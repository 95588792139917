import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { UUID } from "api/types";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useState } from "react";
import {
  ManufacturingGroup as ManufacturingGroupDto,
  ManufacturingGroupWithId,
  ManufacturingUnit as ManufacturingUnitDto,
  ManufacturingUnitGroupPriority,
} from "api/manufacturing/units/models";
import { Panel } from "../../panel/Panel";
import { ColumnType } from "../../ColumnView";
import cuid from "cuid";
import { ColumnWrapper } from "../ColumnWrapper";
import { Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { EmptyTodoSection } from "./EmptyTodoSection";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useQuery } from "hooks";
import { ColumnSearch } from "../../../subcomponents/ColumnSearch";
import { ErrorType as PaginatedErrorType } from "hooks/createPaginatedQuery";
import { ErrorType } from "hooks/createApiQuery";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { ManufacturingTicket } from "../../shared/manufacturingTicket/ManufacturingTicket";
import { manufacturingFileFactory } from "api/manufacturingNew/calls";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { manufacturingActions } from "api/manufacturing/actions";
import { queryString } from "utilities";

const DEFAULT_NUMBER_OF_UNITS_DISPLAYED = 5 as const;

export interface GroupFilters {
  attributesKinds: string;
  groupByModel: string;
  search: string;
}

interface SingleOrdersSectionProps {
  columnType: ColumnType | null;
  error: PaginatedErrorType | null;
  isLoading: boolean;
  isFetching: boolean;
  setGroupDetails: React.Dispatch<React.SetStateAction<ManufacturingGroupDto | null>>;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
  setUnitDetails: React.Dispatch<React.SetStateAction<ManufacturingUnitDto | null>>;
  units: ManufacturingUnitDto[];
}

interface GroupSectionProps {
  columnType: ColumnType | null;
  error: ErrorType | null;
  groups: ManufacturingGroupWithId[] | null;
  isLoading: boolean;
  isFetching: boolean;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
  setGroupDetails: React.Dispatch<React.SetStateAction<ManufacturingGroupDto | null>>;
  setUnitDetails: React.Dispatch<React.SetStateAction<ManufacturingUnitDto | null>>;
}

interface TodoSectionProps {
  columnType: ColumnType | null;
  setColumnType: React.Dispatch<React.SetStateAction<ColumnType | null>>;
  todoGroupsWithId: ManufacturingGroupWithId[];
  setTodoGroupsWithId: React.Dispatch<React.SetStateAction<ManufacturingGroupWithId[]>>;
}

export const TodoSection = ({
  columnType,
  setColumnType,
  setTodoGroupsWithId,
  todoGroupsWithId,
}: TodoSectionProps) => {
  const [unitDetails, setUnitDetails] = useState<ManufacturingUnitDto | null>(null);
  const [groupDetails, setGroupDetails] = useState<ManufacturingGroupDto | null>(null);
  const { stageId } = useParams<{ stageId: UUID }>();
  const { query } = useQuery();
  const { data: manufacturingStage } = useManufacturingStage(stageId);
  const { data: defaultFilters } = manufacturingActions.useStageBoardDefaultAttributesKind(
    queryString.stringify({
      schemaStage: stageId,
    }),
  );
  const attributesKinds = manufacturingStagesUtils.getAttributesKinds(defaultFilters!);
  const unitsSearch = manufacturingStagesUtils.getTodoUnitColumnSearch(
    query,
    stageId,
    attributesKinds,
  );
  const groupsSearch = manufacturingStagesUtils.getTodoGroupColumnSearch(
    query,
    manufacturingStage!.boardFormat,
    attributesKinds,
  );
  const {
    data: units,
    error: unitsError,
    isLoading: unitsAreLoading,
    isFetching: unitsAreFetching,
    isPreviousData: unitsHasPreviousData,
  } = manufacturingUnitsActions.useGetTodoManufacturingUnits(unitsSearch, {
    keepPreviousData: true,
  });
  const {
    data: groups,
    error: groupsError,
    isLoading: groupsAreLoading,
    isFetching: groupsAreFetching,
    isPreviousData: groupsHasPreviousData,
  } = manufacturingUnitsActions.useGetManufacturingGroups(
    {
      id: stageId,
      search: groupsSearch,
    },
    {
      keepPreviousData: true,
      onSuccess: payload => {
        setTodoGroupsWithId(
          payload.map(group => ({
            id: cuid(),
            ...group,
          })),
        );
      },
    },
  );

  if ((unitsAreLoading && !unitsHasPreviousData) || (groupsAreLoading && !groupsHasPreviousData))
    return (
      <div className="position-relative col-4 px-0">
        <ColumnWrapper>
          <div className="d-flex align-items-center justify-content-center h-100">
            <Spinner size={48} />
          </div>
        </ColumnWrapper>
      </div>
    );

  return (
    <div className="d-flex flex-column flex-1 col-4 px-0">
      <ColumnWrapper>
        {Boolean(units && !units.length) && Boolean(groups && !groups.length) ? (
          <>
            <div className="d-flex align-items-center justify-content-between py-2 gap-3">
              <div className="d-flex align-items-center gap-2">
                <Typography color="neutralBlack88" fontSize="14" fontWeight="600" noWrap>
                  Do zrobienia
                </Typography>
                <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                  {units.length || "brak"}
                </Typography>
              </div>
            </div>
            <EmptyTodoSection
              groupId={stageId}
              groupSearchParams={groupsSearch}
              unitSearchParams={unitsSearch}
            />
          </>
        ) : (
          <>
            <SingleOrdersSection
              columnType={columnType}
              error={unitsError}
              isFetching={unitsAreFetching}
              isLoading={unitsAreLoading}
              setGroupDetails={setGroupDetails}
              setColumnType={setColumnType}
              setUnitDetails={setUnitDetails}
              units={units}
            />
            <GroupSection
              columnType={columnType}
              error={groupsError}
              groups={todoGroupsWithId}
              isFetching={groupsAreFetching}
              isLoading={groupsAreLoading}
              setGroupDetails={setGroupDetails}
              setColumnType={setColumnType}
              setUnitDetails={setUnitDetails}
            />
          </>
        )}
      </ColumnWrapper>
      {columnType === "todo" && (
        <div className="d-flex flex-column mt-2">
          <Panel
            columnType={ColumnType.TODO}
            group={groupDetails}
            unit={unitDetails}
            setUnitDetails={setUnitDetails}
            setGroupDetails={setGroupDetails}
          />
        </div>
      )}
    </div>
  );
};

const SingleOrdersSection = ({
  columnType,
  error,
  isFetching,
  isLoading,
  setColumnType,
  setGroupDetails,
  setUnitDetails,
  units,
}: SingleOrdersSectionProps) => {
  const [showMoreUnits, setShowMoreUnits] = useState(false);
  const { query, updateQuery } = useQuery();

  return (
    <Droppable droppableId="todoSingleSectionDroppable">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className="d-flex align-items-center justify-content-between py-2 gap-3">
            <div className="d-flex align-items-center gap-2">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
                Priorytety
              </Typography>
              <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                {units.length || "brak"}
              </Typography>
              {isFetching && <Spinner size={16} />}
            </div>
            <ColumnSearch
              isLoading={isFetching}
              queryKey="todoUnitsSearch"
              onChange={search => updateQuery({ ...query, todoUnitsSearch: search ?? "" })}
              value={query["todoUnitsSearch"]}
            />
          </div>
          {error && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <CommonError status={error._httpStatus_} />
            </div>
          )}
          {!error && !isLoading && (
            <div>
              <div className="d-flex flex-column gap-1">
                {(showMoreUnits ? units : units.slice(0, 5)).map((unit, index) => (
                  <Draggable draggableId={`unitTodo;${unit.id}`} key={unit.id} index={index}>
                    {(provided, snapshot) => (
                      <div
                        key={unit.id}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={manufacturingStagesUtils.getDraggableStyles(
                          provided.draggableProps.style,
                          snapshot,
                        )}
                      >
                        <ManufacturingTicket
                          attributes={ticket => (
                            <>
                              {manufacturingStagesConstants.attributeCategoryIconsDict["PRODUCT"]}
                              <Typography fontSize="16" fontWeight="700">
                                {ticket.productName}
                              </Typography>
                              {ticket.attributes.map(attribute => (
                                <Typography
                                  key={attribute.attribute.id}
                                  fontSize="14"
                                  fontWeight="600"
                                >
                                  {attribute.value.name}
                                </Typography>
                              ))}
                            </>
                          )}
                          downloadLabelFn={ticket =>
                            manufacturingFileFactory.manufacturingItemPdf(
                              ticket.manufacturingItems,
                              ticket.signature!,
                            )
                          }
                          isColumnActive={columnType === ColumnType.TODO}
                          isDragged={snapshot.isDragging}
                          onClick={ticket => {
                            updateQuery({
                              unitPanelId:
                                query.unitPanelId && query.unitPanelId === ticket.id
                                  ? ""
                                  : ticket.id,
                            });
                            setGroupDetails(null);
                            setUnitDetails(unit);
                            setColumnType(ColumnType.TODO);
                          }}
                          ticket={{
                            attributes: unit.attributeValues,
                            employee: unit.employee,
                            id: unit.id,
                            isDeclined: unit.isDeclined,
                            implementedBy: unit.implementedBy,
                            manufacturer: unit.manufacturer?.name,
                            manufacturingItems: [unit.manufacturingItem],
                            signature: unit.signature,
                            priority: unit.priority,
                            productName: unit.name,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
              {Boolean(units.length > DEFAULT_NUMBER_OF_UNITS_DISPLAYED) && (
                <div className="pt-2">
                  {showMoreUnits ? (
                    <Button
                      className="text-uppercase"
                      onClick={() => setShowMoreUnits(false)}
                      size="small"
                      variant="gray"
                    >
                      Pokaż mniej
                    </Button>
                  ) : (
                    <Button
                      className="text-uppercase"
                      onClick={() => setShowMoreUnits(true)}
                      size="small"
                      variant="gray"
                    >
                      Pokaż pozostałe {units.length - DEFAULT_NUMBER_OF_UNITS_DISPLAYED}
                    </Button>
                  )}
                </div>
              )}
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};

const GroupSection = ({
  columnType,
  error,
  groups,
  isFetching,
  isLoading,
  setColumnType,
  setGroupDetails,
  setUnitDetails,
}: GroupSectionProps) => {
  const { query, updateQuery } = useQuery();
  const { stageId } = useParams<{ stageId: UUID }>();
  const { data: manufacturingItemCount } = manufacturingUnitsActions.useManufacturingItemCount({
    stageId,
    search: queryString.stringify({
      finishedAtFrom: query.finishedAtFrom,
      finishedAtTo: query.finishedAtTo,
    }),
  });

  return (
    <Droppable droppableId="todoGroupSectionDroppable">
      {(provided, snapshot) => (
        <div {...provided.droppableProps} ref={provided.innerRef}>
          <div className="d-flex align-items-center justify-content-between py-2 gap-3">
            <div className="d-flex align-items-center gap-2">
              <Typography color="neutralBlack88" fontSize="14" fontWeight="600">
                Do zrobienia
              </Typography>
              <Typography color="deepPurple400" fontSize="14" fontWeight="700">
                {manufacturingItemCount?.counts.notStarted ?? 0}
              </Typography>
              {isFetching && <Spinner size={16} />}
            </div>
            <ColumnSearch
              isLoading={isFetching}
              queryKey="todoGroupsSearch"
              onChange={search => updateQuery({ ...query, todoGroupsSearch: search ?? "" })}
              value={query["todoGroupsSearch"]}
            />
          </div>
          {error && (
            <div className="d-flex align-items-center justify-content-center h-100">
              <CommonError status={error._httpStatus_} />
            </div>
          )}
          {!error && !isLoading && (
            <div className="d-flex flex-column gap-1">
              {groups?.map((group, index) => (
                <Draggable draggableId={`groupTodo;${group.id}`} key={group.id} index={index}>
                  {(provided, snapshot) => (
                    <div
                      key={group.id}
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      style={manufacturingStagesUtils.getDraggableStyles(
                        provided.draggableProps.style,
                        snapshot,
                      )}
                    >
                      <ManufacturingTicket
                        attributes={ticket => (
                          <>
                            {ticket.modelName &&
                              manufacturingStagesConstants.attributeCategoryIconsDict["PRODUCT"]}
                            {ticket.modelName && (
                              <Typography fontSize="16" fontWeight="700">
                                {ticket.modelName}
                              </Typography>
                            )}
                            {ticket.attributes.map(attribute => (
                              <div
                                className="d-flex align-items-center gap-1"
                                key={`todo-section-group-attribute-${attribute.attribute.id}`}
                              >
                                {
                                  manufacturingStagesConstants.attributeCategoryIconsDict[
                                    attribute.category
                                  ]
                                }
                                <Typography fontSize="14" fontWeight="600" noWrap>
                                  {attribute.value.name}
                                </Typography>
                              </div>
                            ))}
                          </>
                        )}
                        downloadLabelFn={ticket =>
                          manufacturingFileFactory.manufacturingItemPdf(
                            ticket.manufacturingItems,
                            ticket.modelName!,
                          )
                        }
                        isColumnActive={columnType === ColumnType.TODO}
                        isDragged={snapshot.isDragging}
                        key={group.id}
                        onClick={ticket => {
                          updateQuery({
                            unitPanelId:
                              query.unitPanelId && query.unitPanelId === ticket.id ? "" : ticket.id,
                          });
                          setGroupDetails(group);
                          setUnitDetails(null);
                          setColumnType(ColumnType.TODO);
                        }}
                        ticket={{
                          attributes: group.attributesValues,
                          elementsCount: {
                            A: group.elements.filter(
                              element => element.priority === ManufacturingUnitGroupPriority.A,
                            ).length,
                            B: group.elements.filter(
                              element => element.priority === ManufacturingUnitGroupPriority.B,
                            ).length,
                            C: group.elements.filter(
                              element => element.priority === ManufacturingUnitGroupPriority.C,
                            ).length,
                            D: 0,
                            E: 0,
                          },
                          id: group.id,
                          isDeclined: group.elements.some(element => element.isDeclined),
                          manufacturingItems: group.elements.map(unit => unit.manufacturingItemId),
                          modelName: group.modelName,
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
            </div>
          )}
          {provided.placeholder}
        </div>
      )}
    </Droppable>
  );
};
