import { Switch, RouteComponentProps, Redirect } from "react-router-dom";
import { Route } from "components/utils";
import { NotFound } from "pages/notFound";
import { RouteList as RouteListView } from "pages/manufacturingNew/routeList/RouteList";
import { RouteProgress } from "pages/logistics/routeProgress/RouteProgress";

export const RouteListRouting = ({ match }: RouteComponentProps) => {
  return (
    <Switch>
      <Route
        exact={true}
        path={`${match.path}`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list`}
        render={() => <Redirect to={`${match.path}/list/all`} />}
      />
      <Route
        exact={true}
        path={`${match.path}/list/:tab`}
        component={RouteListView}
        title="Milo - Trasy"
      />
      <Route
        component={RouteProgress}
        exact={true}
        path={`${match.path}/progress/:route`}
        title="Milo - logistyka - trasa"
      />
      <Route exact={true} path="*" component={NotFound} title="Milo" />
    </Switch>
  );
};
