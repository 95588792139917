import { ManufacturingStageSchema, Settings } from "api/manufacturingNew/models";
import { UUID } from "api/types";
import { UserWithInitials } from "api/users/models";
import { ImplementedBy } from "../units/models";

export enum SchemaStageImplementedBy {
  CONTRACTOR = "CONTRACTOR",
  IN_HOUSE = "IN_HOUSE",
}

export enum AttributeCategory {
  FABRIC = "FABRIC",
  PRODUCT = "PRODUCT",
  SIZE = "SIZE",
}

export enum BoardFormat {
  SINGLE = "SINGLE",
  GROUP = "GROUP",
}

export interface ManufacturingStage {
  backgroundColor: string;
  boardFormat: BoardFormat;
  createdAt: string;
  createdBy: UserWithInitials;
  id: string;
  implementedBy: SchemaStageImplementedBy;
  isAbleToCommission: boolean;
  isQaStage: boolean;
  isThisTheLastStage: boolean;
  name: string;
  schemas: ManufacturingStageSchema[];
  settings: Settings;
  stageColumnSet: {
    id: UUID;
    name: string;
    code: string;
  };
  textColor: string;
}

export interface PostManufacturingStage {
  backgroundColor: string;
  name: string;
  stageColumnSet: string;
  textColor: string;
}

export interface PostManufacturingSchema {
  name: string;
}

export interface ManufacturerStage {
  id: number;
  manufacturer: number;
  schemaStage: {
    backgroundColor: string;
    code: string;
    id: UUID;
    implementedBy: ImplementedBy;
    name: string;
    textColor: string;
  };
}

export interface AddManufacturerStagePayload {
  manufacturer: number;
  schemaStage: UUID;
}
