import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx, getIsoDateFormat, pluralize } from "utilities";
import styles from "./SendShipmentToExternal.module.css";
import { TimePicker } from "components/utils/timePicker";
import { DatePicker } from "components/utils/datePicker";
import { shippingActions } from "api/shipping/actions";
import { UUID } from "api/types";
import { useMemo, useState } from "react";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Partials } from "api/other/models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";

export const MissingSection = ({
  data,
  setOmitNotReadyShipments,
  omitNotReadyShipments,
}: {
  data: ReturnType<typeof shippingActions.useSendToExternal>["data"];
  setOmitNotReadyShipments: React.Dispatch<React.SetStateAction<boolean>>;
  omitNotReadyShipments: boolean;
}) => {
  const persistedData = useMemo(() => {
    return data;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (
    !persistedData?.missingPlannedPickupAt.length &&
    !persistedData?.missingShippingService.length &&
    !persistedData?.missingPackages.length
  ) {
    return null;
  }
  return (
    <div
      className={cx(styles.emptySection, "p-3 mt-3", {
        [styles.emptySectionUpdated]: Boolean(
          !data?.missingPlannedPickupAt.length && !data?.missingShippingService.length,
        ),
      })}
    >
      {Boolean(data?.missingPackages.length) && (
        <Typography fontSize="18" fontWeight="700" color="warning500" className="mb-1">
          Przesyłki z brakującymi paczkami:
        </Typography>
      )}

      {Boolean(persistedData?.missingPackages.length) && (
        <Typography className="mb-3" fontSize="14" fontWeight="700">
          {persistedData?.missingPackages
            .map(missingPackage => missingPackage.signature)
            .join(", ")}
        </Typography>
      )}

      {Boolean(data?.missingPlannedPickupAt.length || data?.missingShippingService.length) && (
        <Typography fontSize="18" fontWeight="700" color="warning500" className="mb-1">
          Przesyłki z brakami danych
        </Typography>
      )}

      {Boolean(persistedData?.missingShippingService.length) && (
        <ShippingServiceForm shipments={persistedData!.missingShippingService.map(e => e.id)} />
      )}

      {Boolean(persistedData?.missingPlannedPickupAt.length) && (
        <PlannedPickupArrivalForm
          shipments={persistedData!.missingPlannedPickupAt.map(e => e.id)}
        />
      )}

      <Checkbox
        disabled={
          !data?.missingPlannedPickupAt.length &&
          !data?.missingShippingService.length &&
          !data?.missingPackages.length
        }
        checked={omitNotReadyShipments}
        onChange={() => setOmitNotReadyShipments(prev => !prev)}
        label="Pomiń przesyłki z brakami danych"
        size="sm"
      />
    </div>
  );
};

const PlannedPickupArrivalForm = ({ shipments }: { shipments: UUID[] }) => {
  const patchShippingShipments = shippingActions.usePatchShippingShipments();
  const [plannedPickupArrivalDate, setPlannedPickupArrivalDate] = useState<string | undefined>(
    undefined,
  );
  const [plannedPickupArrivalTime, setPlannedPickupArrivalTime] = useState<string | undefined>(
    undefined,
  );

  return (
    <div className="d-flex flex-column gap-2 mb-3">
      <div className="d-flex gap-1 align-items-center">
        <Typography fontSize="16" fontWeight="700">
          Nie wybrano daty odbioru przez kuriera:
        </Typography>
        <Typography fontSize="16" fontWeight="600">
          {shipments.length}{" "}
          {pluralize.pl(shipments.length, {
            singular: "przesyłka",
            plural: "przesyłki",
            other: "przesyłek",
          })}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-2">
        <div className="d-flex align-items-center gap-2">
          <DatePicker
            look="common"
            tabIndex={-1}
            overwrites={{
              popup: { className: styles.datePickerPopup },
              input: { className: styles.datePickerInput },
              container: { className: cx(styles.datePickerInput, "pl-0") },
            }}
            value={plannedPickupArrivalDate || null}
            onChange={date => {
              if (date) setPlannedPickupArrivalDate(getIsoDateFormat(date));
            }}
          />

          <TimePicker
            look="common"
            onBlur={time => setPlannedPickupArrivalTime(time)}
            overwrites={{
              container: { className: styles.formHeight },
              input: { className: styles.formHeight },
            }}
            value={plannedPickupArrivalTime || null}
          />
        </div>
        <Button
          size="small"
          variant={patchShippingShipments.isSuccess ? "success" : "gray"}
          className="text-uppercase"
          disabled={
            !plannedPickupArrivalDate ||
            !plannedPickupArrivalTime ||
            patchShippingShipments.isSuccess
          }
          onClick={() => {
            patchShippingShipments.mutate({
              shipments,
              plannedPickupArrivalDate,
              plannedPickupArrivalTime,
            });
          }}
        >
          Aktualizuj
        </Button>
      </div>
    </div>
  );
};

const ShippingServiceForm = ({ shipments }: { shipments: UUID[] }) => {
  const patchShippingShipments = shippingActions.usePatchShippingShipments();
  const shippingServices = useSelector(state => state.partials.shippingShippingServices);
  const [shippingService, setShippingService] = useState<Required<
    Partials["shippingShippingServices"][number]
  > | null>(null);

  return (
    <div className="d-flex flex-column gap-2 mb-3">
      <div className="d-flex gap-1 align-items-center">
        <Typography fontSize="16" fontWeight="700">
          Nie wybrano kuriera:
        </Typography>
        <Typography fontSize="16" fontWeight="600">
          {shipments.length}{" "}
          {pluralize.pl(shipments.length, {
            singular: "przesyłka",
            plural: "przesyłki",
            other: "przesyłek",
          })}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-2">
        <Select
          label="Kurier"
          items={shippingServices.map(shippingService => ({
            value: shippingService.id,
            text: shippingService.name,
            type: MenuItemType.TEXT,
          }))}
          onChange={value => {
            const shippingService = shippingServices.find(({ id }) => id === value);
            assertIsDefined(shippingService);
            setShippingService(shippingService);
          }}
          selected={shippingService?.id || null}
        />
        <Button
          size="small"
          variant={patchShippingShipments.isSuccess ? "success" : "gray"}
          className="text-uppercase"
          disabled={!shippingService || patchShippingShipments.isSuccess}
          onClick={() => {
            patchShippingShipments.mutate({ shipments, shippingService: shippingService });
          }}
        >
          Aktualizuj
        </Button>
      </div>
    </div>
  );
};
