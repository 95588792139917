import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { useQuery } from "hooks";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { PanelHeader } from "./components/PanelHeader";
import { useManufacturingStage } from "api/manufacturingNew/hooks";
import { Days } from "../components/Days";
import { GeneralInfoSection } from "../components/GeneralInfoSection";
import { Employees } from "../components/Employees";
import { PreviousStagesSection } from "../components/previousStagesSection/PreviousStagesSection";
import { SchemasSection } from "../components/schemasSection/SchemasSection";
import { StageColumnSets } from "../components/StageColumnSets";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { useDrawer } from "hooks/useDrawer";
import { StageBoardSettings } from "../components/StageBoardSettings";
import { StageSettingsSection } from "../components/stageSettingsSection/StageSettingsSection";

export const RightPanel = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { close } = useDrawer("manufacturingStagesList");
  const { data: manufacturingStage, error, isLoading } = useManufacturingStage(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturingStage) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={manufacturingStage.id}>
        <PanelHeader manufacturingStage={manufacturingStage} close={close} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <GeneralInfoSection manufacturingStage={manufacturingStage} />
            <StageSettingsSection manufacturingStage={manufacturingStage} />
            <StageColumnSets manufacturingStage={manufacturingStage} />
            <Employees manufacturingStage={manufacturingStage} />
            <StageBoardSettings manufacturingStage={manufacturingStage} />
            <Days manufacturingStage={manufacturingStage} />
            <PreviousStagesSection manufacturingStage={manufacturingStage} />
            <SchemasSection manufacturingStage={manufacturingStage} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
