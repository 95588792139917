import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import uploadFileIcon from "assets/images/uploadFileLight.svg";
import { ImportInvoices } from "routes/ImportInvoices";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { myImportTabDict } from "pages/tradingDocuments/shared/utils/getTabs";

export const myInvoicesToConfirm: ModuleLink = {
  url: "import-invoices",
  label: "Moje faktury do zatwierdzenia",
  icon: uploadFileIcon,
  subSection: <TabSubSection baseUrl="/finances/import-invoices/list" tabs={myImportTabDict} />,
  routing: ImportInvoices,
};
