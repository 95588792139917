import { UserWithInitalsExtendedDetails } from "api/users/models";

export enum MessageType {
  SMS = "SMS",
  EMAIL = "EMAIL",
}

export enum EmailType {
  INCOMING = "INCOMING",
  OUTGOING = "OUTGOING",
}

export enum EmailStatus {
  CLICKED_LINK = "CLICKED_LINK",
  DECLINED = "DECLINED",
  OPENED = "OPENED",
  SENT = "SENT",
}

export interface SmsMessage {
  id: number;
  countryCode: string;
  created: string;
  name: string;
  body: string;
  kind: string;
  type: MessageType;
  salesAccount: {
    id: number;
    name: string;
    channel: string;
    color: string;
    currency: string;
  };
  isDefault: boolean;
  user: UserWithInitalsExtendedDetails;
}

export interface SmsMessageDetails {
  id: number;
  countryCode: string;
  created: string;
  name: string;
  body: string;
  kind: string;
  type: MessageType;
  salesAccount: {
    id: number;
    name: string;
    channel: string;
    color: string;
    currency: string;
  };
  isDefault: boolean;
  user: UserWithInitalsExtendedDetails;
}

export interface EmailMessage {
  body: string;
  created: string;
  createdBy: {
    avatar: string | null;
    firstName: string;
    lastName: string;
    id: number;
  };
  emailFrom: string;
  emailTo: string;
  emailType: EmailType;
  eventType: string;
  externalId: string;
  failureReason: string;
  id: number;
  isConfirmationEmail: boolean;
  lastModified: string;
  modifiedAt: string | null;
  order: number;
  route: number;
  status: EmailStatus;
  subject: string;
}
