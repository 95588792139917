import { DraftInvoiceOrdersFilters } from "../../AddOrdersToDraft";
import styles from "../../AddOrdersToDraft.module.css";
import { Select } from "components/miloDesignSystem/molecules/select";
import { orderConstants } from "constants/orders";
import { Status } from "api/orders/models";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { CHANNELS, Channels, channels } from "CONSTANTS";
import { cx, dateFns, getIsoDateFormat, getStandardDateFormat } from "utilities";
import { Radio } from "components/miloDesignSystem/atoms/radio";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Popover } from "components/miloDesignSystem/atoms/popover";
import { ClickOutsideHandler } from "components/utils";
import { DatePicker } from "components/miloDesignSystem/molecules/datepicker/DatePicker";
import { useState } from "react";
import { ItemsOrService } from "../../AddOrdersToDraftContent";

interface Props {
  filters: DraftInvoiceOrdersFilters;
  itemsOrServices: ItemsOrService;
  setFilter: <T extends keyof DraftInvoiceOrdersFilters, U extends DraftInvoiceOrdersFilters[T]>(
    name: T,
    value: U,
  ) => void;
  setItemsOrServices: React.Dispatch<React.SetStateAction<ItemsOrService>>;
}

export const FiltersSection = ({
  filters,
  itemsOrServices,
  setFilter,
  setItemsOrServices,
}: Props) => {
  const [isCustomRangeSelected, setIsCustomRangeSelected] = useState(false);
  const months = getMonthsFilterContent();

  const resetPage = () => setFilter("page", 1);

  const isMonthTagSelected = (minDate: string, maxDate: string) => {
    return Boolean(
      minDate &&
        filters.createdMinDate === minDate &&
        maxDate &&
        filters.createdMaxDate === maxDate,
    );
  };

  return (
    <div className={styles.filters}>
      <div className={styles.monthsFilter}>
        <Typography color="neutralBlack48" fontSize="10" fontWeight="700">
          Zakres:
        </Typography>
        {months.map(month => (
          <div
            className={styles.selectableMonthTag}
            key={month.label}
            onClick={() => {
              setFilter("createdMinDate", month.minDate);
              setFilter("createdMaxDate", month.maxDate);
              resetPage();
              setIsCustomRangeSelected(false);
            }}
          >
            <Tag
              label={month.label}
              type={isMonthTagSelected(month.minDate, month.maxDate) ? "filled" : "outlined"}
              variant={
                isMonthTagSelected(month.minDate, month.maxDate) ? "deepPurple50" : "quaternary"
              }
            />
          </div>
        ))}
        <Popover
          variant="DARK"
          hideOnClickOutside={false}
          content={({ hide }) => (
            <ClickOutsideHandler
              onClickOutside={hide}
              outsideClickIgnoreClass={ignoreClickOutsideClassName}
            >
              <div className={styles.customDates}>
                <Typography className="mb-1" fontSize="12" fontWeight="600">
                  Wybierz zakres dat:
                </Typography>
                <div className={styles.datePickersContainer}>
                  <DatePicker
                    calendarClassName={ignoreClickOutsideClassName}
                    isNullable
                    label="Od"
                    onChange={date => {
                      if (!date) {
                        setFilter("createdMinDate", "");
                        resetPage();
                        if (!filters.createdMaxDate) setIsCustomRangeSelected(false);
                      } else {
                        setFilter("createdMinDate", getIsoDateFormat(date));
                        resetPage();
                        setIsCustomRangeSelected(true);
                      }
                    }}
                    placeholder="Wybierz"
                    value={filters.createdMinDate}
                  />
                  <DatePicker
                    calendarClassName={ignoreClickOutsideClassName}
                    isNullable
                    label="Do"
                    onChange={date => {
                      if (!date) {
                        setFilter("createdMaxDate", "");
                        resetPage();
                        if (!filters.createdMinDate) setIsCustomRangeSelected(false);
                      } else {
                        setFilter("createdMaxDate", getIsoDateFormat(date));
                        resetPage();
                        setIsCustomRangeSelected(true);
                      }
                    }}
                    placeholder="Wybierz"
                    value={filters.createdMaxDate}
                  />
                </div>
              </div>
            </ClickOutsideHandler>
          )}
        >
          <div className={styles.selectableMonthTag}>
            <Tag
              label={
                isCustomRangeSelected
                  ? `${filters.createdMinDate &&
                      getStandardDateFormat(filters.createdMinDate)} - ${filters.createdMaxDate &&
                      getStandardDateFormat(filters.createdMaxDate)}`
                  : "własny zakres"
              }
              type={isCustomRangeSelected ? "filled" : "outlined"}
              variant={isCustomRangeSelected ? "deepPurple50" : "quaternary"}
            />
          </div>
        </Popover>
      </div>
      <Select
        label="Marketplace"
        items={[{ value: "", text: "Wszystkie", type: MenuItemType.TEXT }].concat(
          CHANNELS.map(channel => ({
            value: channel.id as Channels,
            text: channels[channel.id].name,
            type: MenuItemType.TEXT,
          })),
        )}
        onChange={channel => {
          setFilter("channel", channel as string);
          resetPage();
        }}
        selected={filters.channel}
      />
      <Select
        label="Status"
        items={[
          {
            value: "",
            text: "Wszystkie",
            type: MenuItemType.TEXT,
          },
        ].concat(
          Object.entries(orderConstants.orderStatusDict).map(([value, text]) => ({
            value: value as Status,
            text,
            type: MenuItemType.TEXT,
          })),
        )}
        onChange={status => {
          setFilter("status", status as Status);
          resetPage();
        }}
        selected={filters.status}
      />
      <div className={cx(styles.filtersDivider, "line-divider")} />
      <div className={styles.itemsOrServices}>
        <Radio
          checked={itemsOrServices === "onlyItems"}
          label="tylko towar"
          helperText="grupuj z taką samą ceną"
          onClick={() => setItemsOrServices("onlyItems")}
          size="sm"
        />
        <Radio
          checked={itemsOrServices === "onlyServices"}
          label="tylko usługi"
          helperText="grupuj z taką samą ceną"
          onClick={() => setItemsOrServices("onlyServices")}
          size="sm"
        />
        <Radio
          checked={itemsOrServices === null}
          label="towar i usługi"
          onClick={() => setItemsOrServices(null)}
          size="sm"
        />
      </div>
    </div>
  );
};

const getMonthsFilterContent = () => {
  const today = new Date();

  return [
    {
      label: "bieżący miesiąc",
      minDate: getIsoDateFormat(dateFns.startOfMonth(today)),
      maxDate: getIsoDateFormat(dateFns.endOfMonth(today)),
    },
    {
      label: dateFns.format(dateFns.subMonths(today, 1), "LLLL", { locale: dateFns.pl }),
      minDate: getIsoDateFormat(dateFns.startOfMonth(dateFns.subMonths(today, 1))),
      maxDate: getIsoDateFormat(dateFns.endOfMonth(dateFns.subMonths(today, 1))),
    },
    {
      label: dateFns.format(dateFns.subMonths(today, 2), "LLLL", { locale: dateFns.pl }),
      minDate: getIsoDateFormat(dateFns.startOfMonth(dateFns.subMonths(today, 2))),
      maxDate: getIsoDateFormat(dateFns.endOfMonth(dateFns.subMonths(today, 2))),
    },
  ];
};

const ignoreClickOutsideClassName = "add-orders-to-draft-popover-ignore-class-name";
