import manufacturingIcon from "assets/images/Processing.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import { dashboard } from "./routes/dashboard";
import { manufacturingPlans } from "./routes/manufacturingPlans";
import { products } from "./routes/products";
import { indexes } from "./routes/indexes";
import { manufacturers } from "./routes/manufacturers";
import { manufacturingSchemas } from "./routes/manufacturingSchemas";
import { manufacturingStages } from "./routes/manufacturingStages";
import { manufacturingEmployees } from "./routes/manufacturingEmployees";
import favicon from "assets/images/favicons/fv_production.svg";
import { routeList } from "./routes/routeList";
import { materials } from "./routes/materials";
import { productionOrders } from "./routes/productionOrders";
import { serviceProvider } from "./routes/serviceProviderNavigation";
import { manufacturerOrders } from "./routes/manufacturerOrders";
import { serviceProviderOrders } from "./routes/serviceProviderOrders";

export const manufacturing: ModuleNavigation = {
  favicon,
  color: "#EC8B28",
  navigationConfig: {
    label: "Produkcja",
    icon: { src: favicon, background: "#6A2C11" },
  },
  menuDropdownIcon: manufacturingIcon,
  name: "MANUFACTURING",
  url: "/manufacturing",
  dashboard,
  navigationSections: [
    manufacturingPlans,
    manufacturingSchemas,
    productionOrders,
    manufacturerOrders,
    serviceProviderOrders,
    routeList,
    products,
    indexes,
    materials,
    manufacturingStages,
    manufacturers,
    serviceProvider,
    manufacturingEmployees,
  ],
};
