import { Assign } from "utility-types";
import { TableProps } from "../types";
import { useQuery } from "hooks";
import { UseTableMultiSelectReturnType } from "./useTableMultiSelect/useTableMultiSelect";
import { UUID } from "api/types";
import { AvailableViewKeys } from "ducks/ui";
import { useDrawer } from "hooks/useDrawer";
import { getTablePropsBasedOnDrawerHelpers } from "components/utils/drawerHelpers/DrawerHelpers";
import { cx } from "utilities";
import { PaginationType } from "hooks/createPrimitiveHook";

interface Props<T extends { id: UUID | number }> {
  overrittenPanelKey?: string;
  rows: TableProps<T>["rows"];
  withPagination?: { pagination: PaginationType };
  withMultiSelect?: UseTableMultiSelectReturnType<T>;
  withDrawer?: AvailableViewKeys;
}

export const useTableFeatureConnector = <T extends { id: UUID | number }>({
  overrittenPanelKey,
  rows,
  withPagination,
  withMultiSelect,
  withDrawer,
}: Props<T>): Assign<Pick<TableProps<T>, "rows">, Omit<Partial<TableProps<T>>, "rows">> => {
  const paginationProps = useWithPagination(withPagination);
  const onRowClickProps = useWithOnClick({ overrittenPanelKey, withDrawer, withMultiSelect });
  const drawerHelpers = useDrawer(withDrawer || "accountancyAccountsList", overrittenPanelKey);

  return {
    rows,
    ...paginationProps,
    ...onRowClickProps,
    overrides: () => {
      return {
        row: row => {
          const multiSelectOverrides = withMultiSelect?.overrides?.().row?.(row).className;
          const drawerOverrides = getTablePropsBasedOnDrawerHelpers(drawerHelpers)
            ?.overrides!().row?.(row)!.className;

          return {
            className: cx(drawerOverrides, multiSelectOverrides),
          };
        },
      };
    },
  };
};

const useWithOnClick = <T extends { id: UUID | number }>({
  overrittenPanelKey,
  withDrawer,
  withMultiSelect,
}: {
  overrittenPanelKey?: string;
  withMultiSelect?: UseTableMultiSelectReturnType<T>;
  withDrawer?: AvailableViewKeys;
}) => {
  const drawerHelpers = useDrawer(withDrawer || "accountancyAccountsList", overrittenPanelKey);

  if (!withDrawer && !withMultiSelect) return {};

  const onRowClick = (rowId: string, event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (withMultiSelect) {
      const action = withMultiSelect?.onRowClick(rowId, event);

      if (action === "selection") {
        drawerHelpers.close();
        return;
      }

      if (action === "click" && withDrawer) {
        drawerHelpers.togglePanel(rowId);
        return;
      }
    }

    if (withDrawer) {
      drawerHelpers.togglePanel(rowId);
    }
  };

  return { onRowClick };
};

const useWithPagination = <T extends { id: UUID | number }>(
  withPagination: Props<T>["withPagination"],
) => {
  const { query, setQuery } = useQuery({ exclude: ["panelId"] });

  if (!withPagination) return {};

  return {
    pagination: (withPagination.pagination.count || 0) > 0 ? withPagination.pagination : undefined,
    onPaginationChange: paginationState => {
      setQuery({ ...query, page: paginationState.pageIndex });
    },
  } as Pick<TableProps<T>, "pagination" | "onPaginationChange">;
};
