import { TradingDocument } from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { RightPanelSection } from "components/utils/drawer";

interface Props {
  tradingDocument: TradingDocument;
}

export const BuyerInfo = ({ tradingDocument }: Props) => {
  return (
    <RightPanelSection title="Nabywca">
      <div className="pb-3">
        {tradingDocument.buyerCompanyName && (
          <Typography className="pb-1" fontSize="18" fontWeight="700">
            {tradingDocument.buyerCompanyName}
          </Typography>
        )}
        {tradingDocument.buyerStreet && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.buyerStreet}
          </Typography>
        )}
        {(tradingDocument.buyerPostCode || tradingDocument.buyerCity) && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.buyerPostCode}&nbsp;{tradingDocument.buyerCity}
          </Typography>
        )}
        {tradingDocument.buyerBankAccountNumber && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            {tradingDocument.buyerBankAccountNumber}
          </Typography>
        )}
        {tradingDocument.buyerTaxId && (
          <Typography className="pb-1" fontSize="14" fontWeight="500">
            NIP:&nbsp;{tradingDocument.buyerTaxId}
          </Typography>
        )}
      </div>
    </RightPanelSection>
  );
};
