import customersIcon from "assets/images/customers.svg";
import { ModuleNavigation } from "../../moduleTypes/moduleTypes";
import favicon from "assets/images/favicons/fv_customers.svg";
import { customers } from "./routes/customers";
import { dashboard } from "./routes/dashboard";
import businessCenterIcon from "assets/images/businessCenter.svg";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { financesBalance } from "../finances/routes/financesBalance";

export const crm: ModuleNavigation = {
  favicon,
  color: colorPalette.red200,
  navigationConfig: {
    label: "Kontrahenci",
    icon: {
      background: colorPalette.red600,
      src: businessCenterIcon,
    },
  },
  menuDropdownIcon: customersIcon,
  name: "CRM",
  url: "/crm",
  dashboard,
  navigationSections: [financesBalance, customers],
};
