import { Route } from "api/routes/models";
import { RightPanelSection } from "components/utils/drawer";
import { NotificationsPanel } from "./components/NotificationsPanel";
import { OrderList } from "./orderList/OrderList";

interface Props {
  route: Route;
}

export const DeliverySection = ({ route }: Props) => {
  return (
    <>
      <RightPanelSection title="">
        <NotificationsPanel route={route} />
      </RightPanelSection>
      <OrderList routeId={route.id} />
    </>
  );
};
