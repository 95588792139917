import { PageHeader } from "components/common";
import { getTabs } from "pages/tradingDocuments/shared/utils/getTabs";
import { correctionInvoices } from "components/common/moduleNavigation/moduleConfig/finances/routes/correctionInvoices";
import { MdiUploadFile } from "components/miloDesignSystem/atoms/icons/MdiUploadFile";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDownloadFile } from "components/miloDesignSystem/atoms/icons/MdiDownloadFile";
import { MdiVisibilityOff } from "components/miloDesignSystem/atoms/icons/MdiVisibilityOff";
import { MdiAreaChart } from "components/miloDesignSystem/atoms/icons/MdiAreaChart";
import { useQuery } from "hooks";
import { getSearch } from "pages/tradingDocuments/shared/utils/getSearch";
import { useFinancesAmountSummary } from "api/trading-documents/hooks";
import { BottomBarOptions } from "pages/tradingDocuments/salesInvoicesLIst/components/BottomBarOptions";
import { TradingDocumentTab } from "api/trading-documents/models";

interface Props {
  additionalListParams: {
    [x: string]: string;
  };
  tab: TradingDocumentTab;
}

export const ListHeader = ({ additionalListParams, tab }: Props) => {
  const { query } = useQuery({ exclude: ["panelId", "page"] });
  const type = "INVOICE";
  const invoiceType = "CORRECTION";
  const search = getSearch({ query, type, invoiceType, tab });

  const { data: amountSummary, isLoading } = useFinancesAmountSummary(search);

  return (
    <div>
      <PageHeader
        actionButtons={
          <div className="d-flex align-items-center gap-1">
            <IconButton icon={MdiDownloadFile} variant="transparent" />
            <IconButton icon={MdiUploadFile} variant="transparent" />
            <span className="line-divider" />
            <IconButton icon={MdiVisibilityOff} variant="transparent" />
            <IconButton icon={MdiAreaChart} variant="transparent" />
          </div>
        }
        additionalListParams={additionalListParams}
        bottomBarButtons={<BottomBarOptions amountSummary={amountSummary} isLoading={isLoading} />}
        searchInput={{
          label: "Szukaj wśród korekt",
          tags: [
            {
              name: "issueDateFrom",
              label: "data wystawienia (od)",
              value: "2023-08-01",
            },
            {
              name: "issueDateTo",
              label: "data wystawienia (do)",
              value: "2023-08-04",
            },
          ],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `finances/${correctionInvoices.url}`,
          urlSpan: "list",
        }}
        viewLabel="CORRECTION_INVOICES"
      />
    </div>
  );
};
