import { IndexListItem } from "api/manufacturing/indexes/models";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Attributes } from "components/miloDesignSystem/molecules/attributes";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useIndexesColumns = () => {
  return useCreateTableColumns<IndexListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.name, {
        header: "nazwa",
        size: 400,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "16",
        },
      }),
      columnHelper.text(row => row.ean, {
        header: "EAN",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.text(row => row.signature, {
        header: "kod",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.attributesValues, {
        header: "atrybuty",
        size: 350,
        cell: info => {
          const attributes = info.getValue();
          if (!Boolean(attributes.length)) return <EmptyValue fontSize="14" />;
          return (
            <Attributes
              attributes={attributes.map((attribute, index) => ({
                id: index,
                label: attribute.attribute,
                value: attribute.value,
              }))}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.manufacturingSchema, {
        header: "sygnatura",
        size: 130,
        cell: info => {
          const schema = info.getValue();
          if (!schema) return <EmptyValue fontSize="14" />;
          return (
            <Link
              fontSize="14"
              fontWeight="500"
              to={`/manufacturing/manufacturing-schemas/list/all?panelId=${schema.id}`}
            >
              {schema.name ?? schema.signature}
            </Link>
          );
        },
      }),
      columnHelper.text(row => `${row.weight} kg`, {
        header: "waga",
        size: 75,
        textAlign: "right",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => `${row.height} cm`, {
        header: "wys.",
        size: 75,
        textAlign: "right",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => `${row.width} cm`, {
        header: "szer.",
        size: 75,
        textAlign: "right",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "700",
        },
      }),
      columnHelper.text(row => `${row.depth} cm`, {
        header: "gł.",
        size: 75,
        textAlign: "right",
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "700",
        },
      }),
    ];
  });
};
