import { assertIsDefined } from "utilities/assertIsDefined";
import { tradingDocumentsKeys } from "./keys";
import {
  deleteTradingDocument,
  postInvoiceForOrder,
  postNotificationToRecipient,
  postTradingDocumentPayment,
  tradingDocumentFileFactory,
} from "./calls";
import { useMutation } from "hooks/useMutation";
import { useTradingDocument } from "./hooks";
import { useState } from "react";
import { UUID } from "api/types";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import immer from "immer";
import {
  AddProductToDraftInvoicePayload,
  BulkSalesInvoiceConfirmPreview,
  CreateCorrectionModalState,
  CreatePricing,
  CreateTradingDocumentPayment,
  TradingDocument,
} from "./models";
import { useErrorToastr } from "hooks/useErrorToastr";
import { tradingDocumentsApi } from "./api";
import { getAnyErrorKey, queryString } from "utilities";
import { useStateModal } from "hooks";
import { FilterValues } from "pages/tradingDocuments/salesInvoicesLIst/components/financialReportModal/FinancialReportModal";
import { FormikHelpers } from "formik";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { createApiQuery } from "hooks/createApiQuery";
import { useNavigate } from "hooks/useNavigate";
import { QueryClient } from "react-query";
import {
  CreatePricingForm,
  Modifier,
} from "pages/tradingDocuments/createDraftDocument/pricing/Pricing";
import { financesApi } from "api/finances/api";

const useLightTradingDocuments = createPaginatedApiQuery(
  tradingDocumentsApi.getLightTradingDocuments,
);

const useImportedPurchaseInvoice = createApiQuery(tradingDocumentsApi.getImportedPurchaseInvoice);

const usePatchImportedPurchaseInvoice = () => {
  return useMutation(tradingDocumentsApi.patchImportedPurchaseInvoice, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevPanel = queryUtils.handleMutate(
        tradingDocumentsKeys.importedPurchaseInvoices.details(toUpdate.id),
        toUpdate,
      );

      const prevList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.tradingDocument.list(),
        toUpdate.id,
        toUpdate,
      );

      const purchaseImportList = queryUtils.handlePaginatedListUpdate(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList, purchaseImportList, prevPanel };
    },
    onError: (error, { id }, onMutationReturn) => {
      assertIsDefined(onMutationReturn);
      queryUtils.rollback(
        tradingDocumentsKeys.importedPurchaseInvoices.details(id),
        onMutationReturn.prevPanel,
        error,
      );
      queryUtils.rollbackList(
        tradingDocumentsKeys.tradingDocument.list(),
        onMutationReturn.prevList,
        id,
      );
      queryUtils.rollbackList(
        tradingDocumentsKeys.purchaseInvoicesToReview.list(),
        onMutationReturn.purchaseImportList,
        id,
        error,
      );
    },
  }));
};

const useCreateDraftDocumentPosition = () => {
  return useMutation(financesApi.postDraftDocumentPosition, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useAddPricing = (close: () => void) => {
  const addPricingMutation = useMutation(
    tradingDocumentsApi.postPricing,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Ustalono ceny",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const transformFormData = (value: CreatePricingForm, modifier: Modifier) => {
    const createPricing: CreatePricing = {
      tradingDocumentId: value.tradingDocumentId,
    };
    if (Boolean(value.priceListId)) {
      createPricing.priceListId = value.priceListId as number;
    }
    if (modifier !== "NONE") {
      createPricing.modifiers = {};
      if (modifier === "DISCOUNT") {
        createPricing.modifiers.discount = value.discount;
      } else if (modifier === "MARGIN") {
        createPricing.modifiers.margin = value.margin;
      }
    }
    return createPricing;
  };

  const handleSubmit = (
    value: CreatePricingForm,
    actions: FormikHelpers<CreatePricingForm>,
    modifier: Modifier,
  ) => {
    addPricingMutation.mutate(transformFormData(value, modifier), {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const useAddProductToDraftDocument = (close: () => void) => {
  const addProductToDraftDocumentMutation = useMutation(
    financesApi.postDraftDocumentPosition,
    ({ toastr, queryClient }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano indeks",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: AddProductToDraftInvoicePayload,
    actions: FormikHelpers<AddProductToDraftInvoicePayload>,
  ) => {
    addProductToDraftDocumentMutation.mutate(
      {
        tradingDocumentId: values.tradingDocumentId,
        positions: [
          {
            amountWithTax: values.amountWithTax,
            discount: values.discount,
            name: values.name,
            orderId: values.orderId,
            quantity: values.quantity,
            vatRate: values.vatRate,
            indexId: values.productElements[0].index ?? undefined,
          },
        ],
      },
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};

const useCreateDraftDocument = () => {
  const navigate = useNavigate();

  return useMutation(tradingDocumentsApi.postWorkInProgressDocument, ({ toastr }) => ({
    onSuccess: payload => {
      navigate(`/finances/create-work-in-progress-document/${payload.id}`);
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono roboczy dokument handlowy dla podmiotu własnego",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useModifyDraftDocumentPosition = () => {
  const handleRollback = (context: TradingDocument, queryClient: QueryClient) => {
    queryClient.setQueryData<TradingDocument>(
      tradingDocumentsKeys.tradingDocument.details(context.id),
      details => {
        assertIsDefined(details);
        return context;
      },
    );
  };

  return useMutation(tradingDocumentsApi.patchDraftDocumentPosition, ({ queryClient, toastr }) => ({
    onMutate: args => {
      const prevDetails = queryClient.getQueryData(
        tradingDocumentsKeys.tradingDocument.details(args.tradingDocumentId!),
      );
      const { id, tradingDocumentId, ...newArgs } = args;
      queryClient.setQueryData<TradingDocument>(
        tradingDocumentsKeys.tradingDocument.details(args.tradingDocumentId!),
        details => {
          assertIsDefined(details);
          return immer(details, draft => {
            draft.items = draft.items.map(item => ({
              ...item,
              tradingDocumentItems: item.tradingDocumentItems.map(tradingDocumentItem => {
                if (tradingDocumentItem.id === id) return { ...tradingDocumentItem, ...newArgs };
                return tradingDocumentItem;
              }),
            }));
          });
        },
      );
      return prevDetails;
    },
    onSuccess: () => queryClient.invalidateQueries(),
    onError: (error, _, context) => {
      assertIsDefined(context);
      handleRollback(context as TradingDocument, queryClient);
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useRemoveDraftDocumentPosition = () => {
  return useMutation(
    tradingDocumentsApi.deleteDraftDocumentPosition,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useSetVatRate = (tradingDocumentId: UUID) => {
  const [showInput, setShowInput] = useState(false);
  const { handleMutate } = useTradingDocument({ id: tradingDocumentId }, { enabled: false });

  const setVatRateMutation = useMutation(
    tradingDocumentsApi.patchTradingDocumentItemsVatRate,
    ({ queryClient, toastr }) => ({
      onMutate: args => {
        return handleMutate(draft => {
          draft.isManagedManually = true;
          draft.items = draft.items.map(item => {
            if (item) {
              item.tradingDocumentItems = item.tradingDocumentItems.map(_tradingDocumentItem => {
                if (_tradingDocumentItem.id === args.tradingDocumentItemsIds[0]) {
                  return {
                    ..._tradingDocumentItem,
                    vatRate: args.vatRate,
                  };
                }
                return _tradingDocumentItem;
              });
            }
            return item;
          });
        });
      },
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Zmodyfikowano stawkę VAT",
        });
        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocumentId),
        );
      },
      onError: (error, _, rollback) => {
        assertIsDefined(rollback);
        rollback(error);
      },
      onSettled: () => setShowInput(false),
    }),
  );

  return {
    setVatRateMutation,
    setShowInput,
    showInput,
  };
};

const useDownloadOptimaTradeModuleXml = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (salesInvoices: UUID[]) => {
    if (!Boolean(salesInvoices.length)) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "xml", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.salesInvoiceOptimaTradeModuleXml(
      salesInvoices,
    );
    const response = await fileDownloader({
      url,
      name,
      type: "xml",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadOrderXml = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (tradingDocument: TradingDocument) => {
    if (!tradingDocument) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "xml", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.tradingDocumentXml(tradingDocument);
    const response = await fileDownloader({
      url,
      name,
      type: "xml",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useInvoicePdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (invoice: TradingDocument) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.invoicePdf(invoice);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useAdvanceInvoicePdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (invoice: TradingDocument) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.advanceInvoicePdf(invoice);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useSalesInvoicesBulkPdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (salesInvoicesIds: UUID[], language: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.bulkSalesInvoicesPdf(
      salesInvoicesIds,
      language,
    );
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useCorrectionInvoicesBulkPdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (correctionInvoicesIds: UUID[], language: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.bulkCorrectionInvoicesPdf(
      correctionInvoicesIds,
      language,
    );
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useInvoicesBulkXmlDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (
    tradingDocument: {
      type: TradingDocument["type"];
      invoiceType: TradingDocument["invoiceType"];
    },
    ids: string,
  ) => {
    const tstr = downloadFeedbackToastr.open({ type: "xml", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.tradingDocumentMultipleXml(
      tradingDocument,
      ids,
    );
    const response = await fileDownloader({
      url,
      name,
      type: "xml",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useCorrectionsBulkXmlDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (
    tradingDocument: { type: TradingDocument["type"]; invoiceType: TradingDocument["invoiceType"] },
    ids: string,
  ) => {
    const tstr = downloadFeedbackToastr.open({ type: "xml", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.correctionMultipleXml(tradingDocument, ids);
    const response = await fileDownloader({
      url,
      name,
      type: "xml",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadOptimaTradeModuleZip = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (salesInvoices: UUID[]) => {
    if (!Boolean(salesInvoices.length)) throw new Error("An error occurred");
    const tstr = downloadFeedbackToastr.open({ type: "zip", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.salesInvoiceOptimaTradeModuleXml(
      salesInvoices,
    );
    const response = await fileDownloader({
      url,
      name,
      type: "zip",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useCorrectionPdfDownload = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (correctionInvoice: TradingDocument) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.correctionPdf(correctionInvoice);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useBulkPatchTradingDocuments = () => {
  return useMutation(tradingDocumentsApi.bulkUpdateTradingDocuments, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useRemoveInvoiceConfirmationPatch = () => {
  return useMutation(
    tradingDocumentsApi.patchRemoveInvoiceConfirmation,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

const useBulkSalesAccountConfirmation = (panelId: UUID | undefined) => {
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();

  const bulkSalesInvoiceConfirmMutation = useMutation(
    tradingDocumentsApi.postBulkConfirmSalesInvoice,
    ({ queryClient }) => ({
      onSuccess: payload => {
        if (panelId) {
          queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
        }
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        replyModal.open(payload.message);
      },
    }),
  );

  return { replyModal, bulkSalesInvoiceConfirmMutation };
};

const useFiscalizeReceipts = (panelId: UUID) => {
  return useMutation(
    tradingDocumentsApi.postFiscalizeMultipleReceipts,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się",
          text: "Wysłano paragony",
        });
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(panelId));
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDownloadSalesInvoicesFinancialReport = (close: () => void) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (values: FilterValues, _: FormikHelpers<FilterValues>) => {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const search = queryString.stringify({ ...values });
    const { url, name } = tradingDocumentFileFactory.salesInvoicesFinancialReportCsv(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });
    if (response.status === "success") {
      tstr.lazyClose();
      close();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadSalesInvoiceDuplicatePdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (salesInvoice: TradingDocument) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf", calculateProgress: false });
    const { url, name } = tradingDocumentFileFactory.salesInvoiceDuplicatePdf(salesInvoice);
    const response = await fileDownloader({
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useSubmitPayment = (close: () => void, id: UUID) => {
  const createPaymentMutation = useMutation(
    (formData: CreateTradingDocumentPayment) => postTradingDocumentPayment(formData),
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.details(id));
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Dodano płatność",
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  return (
    values: CreateTradingDocumentPayment,
    actions: FormikHelpers<CreateTradingDocumentPayment>,
  ) => {
    createPaymentMutation.mutate(
      values.kind === "ADVANCE"
        ? {
            ...values,
            amount: String(
              values.items!.reduce((acc, item) => {
                return acc + item.amount;
              }, 0),
            ),
          }
        : values,
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };
};

const useCreateSalesInvoice = () => {
  return useMutation(postInvoiceForOrder, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries(tradingDocumentsKeys.lightTradingDocument.list());
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono fakturę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useSetCurrencyForDraftDocument = () => {
  return useMutation(tradingDocumentsApi.postSetCurrency, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zmieniono walutę",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDeleteTradingDocument = (close: () => void, signature: string) => {
  return withDeleteConfirmation(
    useMutation(deleteTradingDocument, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        close();
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto fakturę ${signature}`,
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    })),
  )();
};

const useSendMultipleEmailNotifications = () => {
  return useMutation(
    tradingDocumentsApi.postMultipleEmailNotifications,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: "Pomyślnie wysłano powiadomienie o fakturze",
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

const useSendEmailNotification = () => {
  return useMutation(postNotificationToRecipient, ({ queryClient, toastr }) => ({
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(
        tradingDocumentsKeys.tradingDocument.details(variables.tradingDocumentId),
      );
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Pomyślnie wysłano powiadomienie o fakturze",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCreateCorrection = (close: () => void) => {
  return useMutation(tradingDocumentsApi.postCorrectionDocumentForInvoice, ({ toastr }) => ({
    onSuccess: () => {
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Utworzono korektę",
      });
    },
    onError: error =>
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      }),
  }));
};

const useResetPrices = (close: () => void) => {
  return useMutation(tradingDocumentsApi.postResetPrices, ({ toastr, queryClient }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: "Zresetowano ceny",
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useCorrectionPreview = (
  openModal: (stateToSet: CreateCorrectionModalState | null) => void,
) => {
  return useMutation(
    (data: { id: UUID; expectedPaymentForm: string }) =>
      tradingDocumentsApi.postPreviewCorrection({ tradingDocument: data.id }),
    ({ toastr }) => ({
      onSuccess: (payload, args) => {
        openModal({
          preview: payload,
          expectedPaymentForm: args.expectedPaymentForm,
          tradingDocumentId: args.id,
        });
      },
      onError: error =>
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        }),
    }),
  );
};

export const tradingDocumentsActions = {
  useSetVatRate,
  useDownloadOptimaTradeModuleXml,
  useDownloadOrderXml,
  useInvoicePdfDownload,
  useCorrectionPdfDownload,
  useDownloadOptimaTradeModuleZip,
  useBulkPatchTradingDocuments,
  useBulkSalesAccountConfirmation,
  useFiscalizeReceipts,
  useSalesInvoicesBulkPdfDownload,
  useInvoicesBulkXmlDownload,
  useCorrectionsBulkXmlDownload,
  useDownloadSalesInvoicesFinancialReport,
  useDownloadSalesInvoiceDuplicatePdf,
  useLightTradingDocuments,
  useSubmitPayment,
  useCorrectionInvoicesBulkPdfDownload,
  useCreateSalesInvoice,
  useDeleteTradingDocument,
  useSendMultipleEmailNotifications,
  useSendEmailNotification,
  useCreateCorrection,
  useCorrectionPreview,
  useImportedPurchaseInvoice,
  usePatchImportedPurchaseInvoice,
  useAdvanceInvoicePdfDownload,
  useRemoveInvoiceConfirmationPatch,
  useCreateDraftDocument,
  useCreateDraftDocumentPosition,
  useRemoveDraftDocumentPosition,
  useModifyDraftDocumentPosition,
  useAddProductToDraftDocument,
  useAddPricing,
  useResetPrices,
  useSetCurrencyForDraftDocument,
};
