import { FieldDetails, FieldKind, PackageInLocation } from "api/wms/layout/models";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { usePackagesInLocationColumns } from "./usePackagesInLocationColumns";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { useFilters } from "hooks/useFilters";
import { LevelSection } from "../levelSection/LevelSection";
import { EmptySection } from "components/common/emptySection/EmptySection";

interface Props {
  field: FieldDetails;
  selectedHall: number;
}

export interface PackagesFilters {
  page: number;
  level: string;
  hall: string;
  field?: string;
  column?: string;
  ramp?: string;
  row?: string;
}

export const PackagesInLocationSection = ({ field, selectedHall }: Props) => {
  const fieldTypeFilter: Partial<PackagesFilters> = (() => {
    if (field.wmsKind === FieldKind.FIELD)
      return {
        field: field.wmsId,
      };
    if (field.wmsKind === FieldKind.RAMP)
      return {
        ramp: field.wmsId,
      };

    if (field.wmsKind === FieldKind.COLUMN)
      return {
        column: field.wmsId,
      };
    return {
      row: field.wmsId,
    };
  })();
  const { searchParams, setFilter } = useFilters<PackagesFilters>({
    page: 1,
    level: "",
    hall: String(selectedHall),
    ...fieldTypeFilter,
  });
  const {
    data: packagesInLocation,
    error,
    isLoading,
    pagination,
  } = wmsLayoutActions.usePackagesInLocation(searchParams, {
    enabled: Boolean(field.wmsId),
  });

  const columns = usePackagesInLocationColumns(packagesInLocation);

  return (
    <>
      <LevelSection field={field} setFilter={setFilter} />
      <RightPanelSection
        title={
          field.wmsKind === FieldKind.RAMP
            ? "Paczki na rampie / samochodzie"
            : "Typy paczek w wybranej lokalizacji"
        }
      >
        <div className="pb-3">
          <Table<PackageInLocation>
            rows={packagesInLocation}
            columns={columns}
            isLoading={isLoading}
            error={error}
            onPaginationChange={paginationState => setFilter("page", paginationState.pageIndex)}
            pagination={(pagination?.count || 0) > 30 ? pagination : undefined}
            uiSchema={mainListUiSchema}
            overrides={() => {
              return {
                hideHeader: !packagesInLocation.length,
                noResultComponent: <EmptySection label="Brak paczek w lokalizacji" />,
              };
            }}
          />
        </div>
      </RightPanelSection>
    </>
  );
};
