import { useQuery } from "hooks";
import styles from "./Panel.module.css";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { cx } from "utilities";
import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { ManufacturingGroup, ManufacturingUnit } from "api/manufacturing/units/models";
import { Todo } from "./sections/todo/Todo";
import { Ready } from "./sections/ready/Ready";
import { ColumnType } from "../ColumnView";
import { InProgress } from "./sections/inProgress/InProgress";

interface Props {
  columnType: ColumnType;
  unit?: ManufacturingUnit | null;
  group?: ManufacturingGroup | null;
  setGroupDetails?: React.Dispatch<React.SetStateAction<ManufacturingGroup | null>>;
  setUnitDetails?: React.Dispatch<React.SetStateAction<ManufacturingUnit | null>>;
}

export const Panel = ({ columnType, group, unit, setGroupDetails, setUnitDetails }: Props) => {
  const { query } = useQuery();
  const { unitPanelId } = query;

  const {
    data: unitInProgressDetails,
    error,
    isLoading,
  } = manufacturingUnitsActions.useGetManufacturingUnitGroupDetails(unitPanelId, {
    enabled: Boolean(unitPanelId) && columnType === "inProgress",
  });

  if (!unitPanelId) return null;

  if (columnType === "todo" && (unit || group))
    return (
      <Todo columnType={columnType} group={group} setGroupDetails={setGroupDetails} unit={unit} />
    );

  if (columnType === "ready" && (unit || group))
    return <Ready columnType={columnType} unit={unit} />;

  if (error && columnType === "inProgress")
    return (
      <div className={cx(styles.panel, "pb-2")}>
        <CommonError status={error._httpStatus_} />
      </div>
    );

  if (isLoading && columnType === "inProgress")
    return (
      <div className={cx(styles.panel, "pb-2")}>
        <div className="d-flex align-items-center justify-content-center">
          <Spinner size={24} />
        </div>
      </div>
    );

  if (unitInProgressDetails) return <InProgress unitInProgressDetails={unitInProgressDetails} />;

  return null;
};
