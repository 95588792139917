import { TradingDocumentTab } from "api/trading-documents/models";
import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "../shared/utils/getSearch";
import { useTradingDocuments } from "api/trading-documents/hooks";
import { useState } from "react";
import { HighlightedRow } from "api/other/models";
import { CommonError, RightPanelHandler } from "components/utils";
import { Loader } from "components/utils/loader";
import { UUID } from "api/types";
import { handleMultipleRowsHighlight } from "utilities/handleMultipleRowsHighlight";
import { InvoicePagination } from "components/common/invoicePagination/InvoicePagination";
import { ActionToolbar } from "../shared/components/actionToolbar/ActionToolbar";
import { ListDrawerWrapper } from "components/utils/drawer";
import { NoResults } from "components/utils/noResults";
import { ModuleList } from "components/utils/moduleList";
import { ModuleListHeader } from "components/utils/moduleList/components/ModuleListHeader";
import { salesInvoiceListConfig } from "components/utils/moduleList/utils/moduleListConfig";
import { ModuleListBody } from "components/utils/moduleList/components/ModuleListBody";
import { ListItem } from "../salesInvoicesLIst/components/ListItem";
import { Header } from "./components/Header";
import { RightPanel } from "./rightPanel/RightPanel";
import { withFilters } from "components/utils/withFilters";
import { filterList } from "./filterList";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";

export const ProformasList = withFilters(
  filterList,
  ({ match }: RouteComponentProps<{ tab: TradingDocumentTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const type = "INVOICE";
    const invoiceType = "PROFORMA";
    const additionalListParams = {
      type,
      invoiceType,
    };
    const search = getSearch({ query, type, invoiceType, tab });
    const {
      data: tradingDocuments,
      error,
      isFetching,
      isLoading,
      pagination,
    } = useTradingDocuments(search);
    const [highlightedRows, setHighlightedRows] = useState<HighlightedRow[]>([]);
    const [showLoader] = useState(false);
    const noResults = !tradingDocuments.length && !isLoading && !error;

    if (error) {
      return (
        <div>
          <Header additionalListParams={additionalListParams} />
          <div className="mt-4">
            <CommonError status={error._httpStatus_} />
          </div>
        </div>
      );
    }

    if (isLoading) {
      return (
        <div>
          <Loader module="finances" />
          <Header additionalListParams={additionalListParams} />
        </div>
      );
    }

    const selectMutlipleItems = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>,
      id: UUID,
      index: number,
      togglePanel: () => void,
    ) => {
      handleMultipleRowsHighlight(
        event,
        highlightedRows,
        id,
        index,
        setHighlightedRows,
        togglePanel,
        tradingDocuments,
      );
    };

    return (
      <PageWrapper>
        {(showLoader || isFetching) && <Loader module="finances" />}
        <Header additionalListParams={additionalListParams} />
        <RightPanelHandler name="salesInvoices">
          {({ close, isActive, isHighlighted, togglePanel }) => (
            <ListDrawerWrapper>
              {noResults ? (
                <NoResults on={noResults} subHeader="faktury proforma" />
              ) : (
                <ModuleList>
                  <ModuleListHeader
                    gridStyle={salesInvoiceListConfig.grid}
                    labels={salesInvoiceListConfig.labels}
                  />
                  <ModuleListBody>
                    {tradingDocuments.map((tradingDocument, index) => (
                      <ListItem
                        highlightedRows={highlightedRows}
                        index={index}
                        isActive={isActive}
                        isHighlighted={isHighlighted}
                        key={tradingDocument.id}
                        togglePanel={togglePanel}
                        salesInvoice={tradingDocument}
                        selectMutlipleItems={selectMutlipleItems}
                      />
                    ))}
                  </ModuleListBody>
                </ModuleList>
              )}
              <RightPanel close={close} />
              <InvoicePagination pagination={pagination} />
              {highlightedRows.length > 0 && (
                <ActionToolbar
                  close={() => setHighlightedRows([])}
                  tradingDocument={{
                    type,
                    invoiceType,
                  }}
                  quantity={highlightedRows.length}
                  highlightedRows={highlightedRows}
                />
              )}
            </ListDrawerWrapper>
          )}
        </RightPanelHandler>
      </PageWrapper>
    );
  },
);
