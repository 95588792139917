import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import trolleyIcon from "assets/images/trolley.svg";
import { Pickings } from "routes/wms/Pickings";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { tabDict } from "pages/wms/pickingsList/utils/getTabs";

export const pickings: ModuleLink = {
  url: "pickings",
  label: "Pickingi",
  icon: trolleyIcon,
  subSection: <TabSubSection tabs={tabDict} baseUrl="/wms/pickings/list" />,
  routing: Pickings,
};
