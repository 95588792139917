import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { externalManufacturingApi, externalManufacturingFileFactory } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useMutation } from "hooks/useMutation";
import { parsePatchData } from "utilities/parsePatchData";
import {
  AskForSingleProductionItemsStatusFiltered,
  OrderLineItemDetails,
  RouteLineItem,
} from "./models";
import { PartialOf } from "typeUtilities";
import { UUID } from "api/types";
import { externalManufacturingKeys } from "./keys";
import { getAnyErrorKey } from "utilities";
import { useSelector } from "hooks";
import { FLAVOR } from "CONSTANTS";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { useErrorToastr } from "hooks/useErrorToastr";

const useExternalManufacturingOrders = createPaginatedApiQuery(externalManufacturingApi.getOrders);
const useExternalManufacturingOrder = createApiQuery(
  externalManufacturingApi.getManufacturingOrder,
);

const useManufacturerItems = createPaginatedApiQuery(externalManufacturingApi.getManufacturerItems);
const useOrderLineItems = createPaginatedApiQuery(externalManufacturingApi.getOrderLineItems);
const useManufacturerItem = createApiQuery(externalManufacturingApi.getManufacturerItem);
const useOrderLineItem = createApiQuery(externalManufacturingApi.getOrderLineItem);

const useRouteLineItems = createPaginatedApiQuery(externalManufacturingApi.getRouteLineItems);
const useEmailsSchedule = createApiQuery(externalManufacturingApi.getEmailsScheduled);

const useManufacturerLineItems = createPaginatedApiQuery(
  externalManufacturingApi.getManufacturerLineItems,
);

const useManufacturerLineItem = createApiQuery(externalManufacturingApi.getManufacturerLineItem);

const usePostConfirmDeliveryDate = () => {
  const me = useSelector(state => state.auth.user);
  const getDetailsKey = (id: UUID) => {
    if (FLAVOR === "externalManufacturing") {
      return externalManufacturingKeys.externalManufacturer.manufacturingLineItems.details(
        String(id),
      );
    }
    return externalManufacturingKeys.main.orderLineItems.details(String(id));
  };
  const listKey =
    FLAVOR === "externalManufacturing"
      ? externalManufacturingKeys.externalManufacturer.manufacturingLineItems.list()
      : externalManufacturingKeys.main.orderLineItems.list();

  return useMutation(externalManufacturingApi.postConfirmDeliveryDate, ({ queryUtils }) => ({
    onMutate: id => {
      const prevPanel = queryUtils.handleMutate(getDetailsKey(id), {
        hasConfirmedDeliveryDate: true,
        deliveryDateConfirmedAt: new Date(),
        deliveryDateConfirmedBy: me,
      });
      const prevList = queryUtils.handlePaginatedListUpdate(listKey, id, {
        hasConfirmedDeliveryDate: true,
        deliveryDateConfirmedAt: new Date(),
        deliveryDateConfirmedBy: me,
      });
      return { prevList, prevPanel };
    },
    onError: (error, id, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollback(getDetailsKey(id), onMutateReturn.prevPanel, error);
      queryUtils.rollbackList(listKey, onMutateReturn.prevList, id);
    },
  }));
};

const usePatchOrderLineItem = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<OrderLineItemDetails> }) => {
      return externalManufacturingApi.patchOrderLineItem(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(
          externalManufacturingKeys.main.orderLineItems.details(String(id)),
          toUpdate,
        );
        const prevList = queryUtils.handlePaginatedListUpdate(
          externalManufacturingKeys.main.orderLineItems.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          externalManufacturingKeys.main.orderLineItems.details(String(id)),
          onMutateReturn.prevPanel,
          error,
        );
        queryUtils.rollbackList(
          externalManufacturingKeys.main.orderLineItems.list(),
          onMutateReturn.prevList,
          id,
        );
      },
    }),
  );
};

const usePatchBulkUpdateLineItems = () => {
  return useMutation(externalManufacturingApi.bulkUpdateLineItems, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        text: getAnyErrorKey(error),
        title: "Wymagane działanie",
        type: "warning",
      });
    },
  }));
};

const usePatchBulkUpdateMainLineItems = () => {
  return useMutation(
    externalManufacturingApi.bulkUpdateExternalManufacturingMainLineItems,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          text: getAnyErrorKey(error),
          title: "Wymagane działanie",
          type: "warning",
        });
      },
    }),
  );
};

const usePatchMainRouteLineItem = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: { pickupBySellerPlannedAt: string | null } }) => {
      return externalManufacturingApi.bulkUpdateExternalManufacturingMainLineItems({
        ids: [id],
        pickupBySellerPlannedAt: toUpdate.pickupBySellerPlannedAt,
      });
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          externalManufacturingKeys.main.routeLineItems.list(),
          id,
          toUpdate,
        );
        return { prevList };
      },
      onError: (_, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(
          externalManufacturingKeys.main.routeLineItems.list(),
          onMutateReturn.prevList,
          id,
        );
      },
    }),
  );
};

const usePatchManufacturerLineItem = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<OrderLineItemDetails> }) => {
      return externalManufacturingApi.patchManufacturerLineItem(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(
          externalManufacturingKeys.externalManufacturer.manufacturingLineItems.details(String(id)),
          toUpdate,
        );
        const prevList = queryUtils.handlePaginatedListUpdate(
          externalManufacturingKeys.externalManufacturer.manufacturingLineItems.list(),
          id,
          toUpdate,
        );
        return { prevList, prevPanel };
      },
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          externalManufacturingKeys.externalManufacturer.manufacturingLineItems.details(String(id)),
          onMutateReturn.prevPanel,
          error,
        );
        queryUtils.rollbackList(
          externalManufacturingKeys.externalManufacturer.manufacturingLineItems.list(),
          onMutateReturn.prevList,
          id,
        );
      },
    }),
  );
};

const useAskForSingleProductionItemsStatusFiltered = () => {
  return useMutation(
    ({ search, data }: { search: string; data: AskForSingleProductionItemsStatusFiltered }) => {
      return externalManufacturingApi.postAskForSingleProductionItemsStatusFiltered(search, data);
    },
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useAskForSingleProductionItemsStatus = () => {
  return useMutation(
    externalManufacturingApi.postAskForSingleProductionItemsStatus,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

export const useSendEmailNotification = () => {
  return useMutation(
    externalManufacturingApi.postNotificationToRecipient,
    ({ queryClient, toastr }) => ({
      onSuccess: (_, variables) => {
        queryClient.invalidateQueries(
          externalManufacturingKeys.main.manufacturingOrders.details(variables.orderId),
        );
        queryClient.invalidateQueries(externalManufacturingKeys.main.manufacturingOrders.list());
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useDownloadExternalManufacturingLineItemsLabelsPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, signature: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = externalManufacturingFileFactory.externalManufacturingLineItemPdf({
      id,
      signature,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const useDownloadExternalManufacturingOrderPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: UUID, signature: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = externalManufacturingFileFactory.externalManufacturingOrderPdf({
      id,
      signature,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

const usePatchRouteLineItem = () => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID | number; toUpdate: PartialOf<RouteLineItem> }) => {
      return externalManufacturingApi.patchRouteLineItem(parsePatchData(toUpdate), id);
    },
    ({ queryUtils }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevList = queryUtils.handlePaginatedListUpdate(
          externalManufacturingKeys.main.routeLineItems.list(),
          id,
          toUpdate,
        );
        return { prevList };
      },
      onError: (_, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollbackList(
          externalManufacturingKeys.main.routeLineItems.list(),
          onMutateReturn.prevList,
          id,
        );
      },
    }),
  );
};

export const externalManufacturingActions = {
  useExternalManufacturingOrders,
  useExternalManufacturingOrder,
  useManufacturerItems,
  useManufacturerItem,
  useManufacturerLineItems,
  useManufacturerLineItem,
  useOrderLineItem,
  useOrderLineItems,
  usePatchBulkUpdateLineItems,
  usePatchManufacturerLineItem,
  useSendEmailNotification,
  usePostConfirmDeliveryDate,
  usePatchOrderLineItem,
  usePatchBulkUpdateMainLineItems,
  useAskForSingleProductionItemsStatus,
  useDownloadExternalManufacturingLineItemsLabelsPdf,
  useRouteLineItems,
  usePatchRouteLineItem,
  useAskForSingleProductionItemsStatusFiltered,
  usePatchMainRouteLineItem,
  useEmailsSchedule,
  useDownloadExternalManufacturingOrderPdf,
};
