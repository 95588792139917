import { wmsBasketsActions } from "api/wms/baskets/actions";
import { PackageInLocation } from "api/wms/layout/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Link } from "components/miloDesignSystem/atoms/link";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { queryString } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const usePackagesInLocationColumns = (packagesInLocation: PackageInLocation[]) => {
  const handleDownloadPackageLabel = wmsBasketsActions.useDownloadPackageLabel();
  const { data: packagesInBasket, error, isLoading } = wmsBasketsActions.useGetPackageInBasket(
    queryString.stringify({
      uniquePackages: packagesInLocation.map(_package => _package.code).join(","),
    }),
    {
      enabled: Boolean(packagesInLocation.length),
    },
  );

  return useCreateTableColumns<PackageInLocation>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.package.name, {
        header: "nazwa",
        size: 267,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
          noWrap: true,
        },
      }),
      columnHelper.accessor(row => row, {
        header: "kosz",
        size: 50,
        cell: info => {
          const _package = info.getValue();
          const searchedPackage = packagesInBasket?.find(
            _searchedPackage => _searchedPackage.uniquePackageCode === _package.code,
          );

          if (error || isLoading)
            return <LoaderCell height={mainListBigUiSchema.cell.height} width={50} />;

          if (!searchedPackage) return <EmptyValue fontSize="14" />;

          return (
            <Link
              fontSize="14"
              fontWeight="600"
              target="_blank"
              to={`/wms/baskets?panelId=${searchedPackage.basket.id}`}
            >
              {searchedPackage.basket.code}
            </Link>
          );
        },
      }),
      columnHelper.text(row => row.package.internalId, {
        header: "nr paczki",
        size: 115,
        typographyProps: {
          fontSize: "14",
          fontWeight: "600",
          noWrap: true,
        },
      }),
      columnHelper.text(row => `${row.package.weight} kg`, {
        header: "waga",
        size: 65,
      }),
      columnHelper.accessor(row => row, {
        id: "packageLabel",
        header: () => (
          <Typography
            className="text-center w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            etykieta
          </Typography>
        ),
        size: 40,
        cell: info => {
          const _package = info.getValue();
          return (
            <div className="d-flex align-items-center justify-content-center ml-1">
              <Tooltip title="Pobierz etykietę paczki">
                <IconButton
                  icon={MdiQrCode}
                  onClick={() =>
                    handleDownloadPackageLabel(_package.code, _package.package.internalId)
                  }
                  variant="transparent"
                />
              </Tooltip>
            </div>
          );
        },
      }),
    ];
  });
};
