import { BulkSalesInvoiceConfirmPreview } from "api/trading-documents/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiEmergencyHome } from "components/miloDesignSystem/atoms/icons/MdiEmergencyHome";
import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./ActionToolbar.module.css";
import { pluralize } from "utilities";
import { MdiTaskAlt } from "components/miloDesignSystem/atoms/icons/MdiTaskAlt";

interface Props {
  bulkInvoiceConfirmation: BulkSalesInvoiceConfirmPreview | null;
  close: () => void;
}

export const ReplyModal = ({ bulkInvoiceConfirmation, close }: Props) => {
  if (!bulkInvoiceConfirmation) return null;

  const isInvalidRecordEmpty = () => {
    return Object.keys(bulkInvoiceConfirmation.invalid).length === 0;
  };

  const notConfirmedInvoicesNumber = Object.values(bulkInvoiceConfirmation.invalid).reduce(
    (acc, values) => {
      acc += values.objects.length;
      return acc;
    },
    0,
  );

  const notConfirmedReasons: JSX.Element[] = Object.values(bulkInvoiceConfirmation.invalid).map(
    reason => {
      const signatures = reason.objects.flatMap(invoice => invoice.signature);
      return (
        <div>
          <Typography className={styles.fitContent} fontSize="14" fontWeight="700">
            {reason.message}:
          </Typography>
          <Typography fontSize="14" fontWeight="600">
            {signatures.join(", ")}
          </Typography>
        </div>
      );
    },
  );

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Status zatwierdzania faktur
        </Typography>
      }
      width={580}
    >
      <div className="d-flex flex-column p-3">
        <div className="mb-3 py-2 d-flex flex-column gap-3">
          {Boolean(bulkInvoiceConfirmation.valid.objects.length) && (
            <div className="d-flex align-items-center gap-2">
              <MdiTaskAlt color="success400" size="20" />
              <Typography fontSize="14" fontWeight="600" noWrap>
                Zatwierdzono{" "}
                {bulkInvoiceConfirmation.valid.objects.length > 1 &&
                  bulkInvoiceConfirmation.valid.objects.length}{" "}
                {pluralize.pl(bulkInvoiceConfirmation.valid.objects.length, {
                  singular: "fakturę",
                  plural: "faktury",
                  other: "faktur",
                })}
              </Typography>
            </div>
          )}
          <div className="d-flex align-items-center gap-2">
            {notConfirmedInvoicesNumber !== 0 && <MdiEmergencyHome color="danger400" size="20" />}
            <Typography fontSize="14" fontWeight="600" noWrap>
              {Boolean(bulkInvoiceConfirmation.invalid) &&
                !isInvalidRecordEmpty() &&
                `Niezatwierdzono ${
                  notConfirmedInvoicesNumber > 1 ? notConfirmedInvoicesNumber : ""
                } ${pluralize.pl(notConfirmedInvoicesNumber, {
                  singular: "faktury",
                  plural: "faktur",
                  other: "faktur",
                })}:`}
            </Typography>
          </div>
          {Boolean(bulkInvoiceConfirmation.invalid) && !isInvalidRecordEmpty() && (
            <div className="ml-4">{notConfirmedReasons.map(reason => reason)}</div>
          )}
        </div>
        <div className="pt-3 gap-3 d-flex align-items-center borderTop">
          <Button
            className="text-uppercase"
            onClick={event => {
              event.stopPropagation();
              close();
            }}
            size="small"
            theme="light"
            variant="gray"
          >
            zamknij
          </Button>
        </div>
      </div>
    </Modal>
  );
};
