import { useTradingDocument } from "api/trading-documents/hooks";
import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { CorrectionSection } from "pages/tradingDocuments/shared/components/rightPanel/correctionSection/CorrectionSection";
import { CorrespondingDocumentSection } from "pages/tradingDocuments/shared/components/rightPanel/correspondingDocumentsSection/CorrespondingDocumentSection";
import { GeneralInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/generalInfoSection/GeneralInfoSection";
import { NotificationSection } from "pages/tradingDocuments/shared/components/rightPanel/notificationSection/NotificationSection";
import { PaymentSection } from "pages/tradingDocuments/shared/components/rightPanel/paymentSection/PaymentSection";
import { TitleSection } from "pages/tradingDocuments/shared/components/rightPanel/titleSection/TitleSection";
import { AmountSection } from "./components/AmountSection";
import { ItemsSummarySection } from "./components/ItemsSummarySection";
import { MasterDocumentSection } from "./components/MasterDocumentSection";
import { PanelHeader } from "./components/PanelHeader";
import { useRef } from "react";
import { RemindersSection } from "../../shared/components/rightPanel/remindersSection/RemindersSection";
import { CommentsGenericSection } from "components/common/commentsGeneric/CommentsGenericSection";
import { BuyerInfo } from "pages/tradingDocuments/shared/components/rightPanel/buyerInfoSection/BuyerInfo";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";
import { RecipientInfoSection } from "pages/tradingDocuments/shared/components/rightPanel/recipientInfoSection/RecipientInfoSection";

interface Props {
  close: () => void;
}

export const RightPanel = ({ close }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: correctionInvoice, error, isLoading } = useTradingDocument(
    { id: panelId },
    { enabled: Boolean(panelId) },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!correctionInvoice) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={correctionInvoice.id}>
        <PanelHeader close={close} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef} hasCommentsModule>
            <TitleSection tradingDocument={correctionInvoice} />
            <GeneralInfoSection tradingDocument={correctionInvoice} />
            <AmountSection correctionInvoice={correctionInvoice} />
            <ItemsSummarySection tradingDocument={correctionInvoice} />
            <MasterDocumentSection correctionInvoice={correctionInvoice} />
            <PaymentSection tradingDocument={correctionInvoice} />
            <NotificationSection tradingDocument={correctionInvoice} />
            {Boolean(correctionInvoice.corrections.length) && (
              <CorrectionSection tradingDocument={correctionInvoice} />
            )}
            {Boolean(correctionInvoice.correspondingDocuments.length) && (
              <CorrespondingDocumentSection tradingDocument={correctionInvoice} />
            )}
            <RemindersSection tradingDocument={correctionInvoice} />
            <BuyerInfo tradingDocument={correctionInvoice} />
            <RecipientInfoSection tradingDocument={correctionInvoice} />
          </RightPanelBody>
          <CommentsGenericSection
            outsideRef={clearCommentsRef}
            paramName="tradingDocument"
            endpointUrl="/finances/comments/items"
            commentedObject={correctionInvoice}
          />
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
