import { CURRENCY_TYPE } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { cx } from "utilities";

interface Props {
  amount: string;
  currency?: CURRENCY_TYPE;
  className?: string;
  decimal?: TypographyProps;
  integer?: TypographyProps;
}

export const AmountDisplay = ({ amount, currency, className, decimal, integer }: Props) => {
  const formattedAmount = Number(amount).toLocaleString("de-DE", {
    style: "decimal",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
  const [integerPart, decimalPart] = formattedAmount.split(",");
  return (
    <div className={cx("d-flex align-items-baseline", className)}>
      <Typography
        fontSize={integer?.fontSize || "14"}
        fontWeight={integer?.fontWeight || "700"}
        {...integer}
      >
        {integerPart}
      </Typography>
      <Typography
        color={decimal?.color || "neutralBlack48"}
        fontSize={decimal?.fontSize || "12"}
        fontWeight={decimal?.fontWeight || "700"}
        {...decimal}
      >
        ,{decimalPart}&nbsp; {currency}
      </Typography>
    </div>
  );
};
