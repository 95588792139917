import styles from "../RightPanel.module.css";
import cx from "classnames";
import { RightPanelSection } from "components/utils/drawer";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Checkbox, Select } from "components/utils";
import { noop } from "utilities";
import { EditDiscountSection } from "./EditDiscountSection";
import { useToggle } from "hooks";
import { AddPurchaseInvoicePosition } from "./components/AddPurchaseInvoicePosition";
import { getMaximumDisplayPosition } from "./utils/getMaximumDisplayPosition";
import { EditPurchaseInvoicePosition } from "./EditPurchaseInvoicePosition";
import { useState } from "react";
import { DeletePurchaseInvoicePosition } from "./DeletePurchaseInvoicePosition";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { EditAmountWithTaxSection } from "./EditAmountWithTaxSection";
import { useRemoveTradingDocumentItem } from "./hooks/useRemoveTradingDocumentItem";
import { TotalAmountSection } from "./TotalAmountSection";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EditVatRateSection } from "./editVatRateSection/EditVatRateSection";
import { PurchaseInvoiceImportPaymentForm } from "./components/PurchaseInvoiceImportPaymentForm";
import { PaymentForm } from "./components/PaymentForm";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiAdd } from "components/miloDesignSystem/atoms/icons/MdiAdd";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiDelete } from "components/miloDesignSystem/atoms/icons/MdiDelete";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  enableAddingPosition?: boolean;
  isPurchaseImport?: boolean;
  tradingDocument: TradingDocument;
}

export const ItemsSummarySection = ({
  enableAddingPosition = false,
  isPurchaseImport = false,
  tradingDocument,
}: Props) => {
  const addPurchaseInvoicePosition = useToggle();
  const [isDisplayPositionEdited, setIsDisplayPositionEdited] = useState(false);
  const priceInWords = tradingDocument.amountSummary.totalWithTaxInWords;
  const removeTradingDocumentItemMutation = useRemoveTradingDocumentItem(tradingDocument.id);
  const paymentStatusMutation = usePatchTradingDocumentMutation();
  const isNotSalesAndNotProformaAndNotAdvance =
    tradingDocument.invoiceType !== "SALES" &&
    tradingDocument.invoiceType !== "PROFORMA" &&
    tradingDocument.invoiceType !== "ADVANCE";
  const isSalesOrProformaOrAdvance =
    tradingDocument.invoiceType === "SALES" ||
    tradingDocument.invoiceType === "PROFORMA" ||
    tradingDocument.invoiceType === "ADVANCE";

  return (
    <RightPanelSection>
      <div className="pb-3">
        <div
          className={cx(styles.tableHeader, {
            [styles.tableHeaderOrderWithSignature]: isSalesOrProformaOrAdvance,
            [styles.tableHeaderOrder]:
              isNotSalesAndNotProformaAndNotAdvance && !isDisplayPositionEdited,
            [styles.tableHeaderOrderWithEditableDisplayPosition]: isDisplayPositionEdited,
          })}
        >
          <div>#</div>
          <div>nazwa towaru</div>
          {isSalesOrProformaOrAdvance && <div>zamówienie</div>}
          {isSalesOrProformaOrAdvance && <div>rabat</div>}
          {isSalesOrProformaOrAdvance && (
            <div className="d-flex align-items-center justify-content-end">w. rabatu</div>
          )}
          <div className="d-flex align-items-center justify-content-end">liczba</div>
          <div
            className={cx({
              "d-flex align-items-center justify-content-end": isNotSalesAndNotProformaAndNotAdvance,
            })}
          >
            jedn.
          </div>
          {isNotSalesAndNotProformaAndNotAdvance && (
            <div className="d-flex align-items-center justify-content-end">c. netto</div>
          )}
          {isSalesOrProformaOrAdvance && (
            <div className="d-flex align-items-center justify-content-center">c. brutto</div>
          )}
          {isNotSalesAndNotProformaAndNotAdvance && (
            <div className="d-flex align-items-center justify-content-center">c. brutto</div>
          )}
          {isSalesOrProformaOrAdvance && (
            <div className="d-flex align-items-center justify-content-center">c. brutto po r.</div>
          )}
          <div
            className={cx("d-flex align-items-center", {
              "justify-content-end": isNotSalesAndNotProformaAndNotAdvance,
              "justify-content-center": isSalesOrProformaOrAdvance,
            })}
          >
            VAT
          </div>
          {isNotSalesAndNotProformaAndNotAdvance && (
            <div className="d-flex align-items-center justify-content-end">war. netto</div>
          )}
          {isSalesOrProformaOrAdvance && (
            <div className="d-flex align-items-center justify-content-end">w. brutto</div>
          )}
        </div>
        <div>
          {tradingDocument.items.map(item =>
            item.tradingDocumentItems.map((tradingDocumentItem, index) => (
              <div
                className={cx(styles.tableRow, {
                  [styles.tableRowOrderWithEditableDisplayPosition]: isDisplayPositionEdited,
                  [styles.tableRowOrderForSales]: isSalesOrProformaOrAdvance,
                  [styles.tableRowOrderWithSignature]: isSalesOrProformaOrAdvance,
                  [styles.tableRowOrder]:
                    isNotSalesAndNotProformaAndNotAdvance && !isDisplayPositionEdited,
                })}
                key={index}
              >
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="#"
                    inputType="number"
                    itemId={item.id}
                    setIsDisplayPositionEdited={setIsDisplayPositionEdited}
                    target={<div className="fw-800">{tradingDocumentItem.displayPosition}*.</div>}
                    targetKey="displayPosition"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.displayPosition}
                  />
                ) : (
                  <div>{tradingDocumentItem.displayPosition}.</div>
                )}
                <div className="d-flex align-items-center gap-1">
                  {enableAddingPosition &&
                  tradingDocumentItem.addedMethod &&
                  tradingDocumentItem.addedMethod === "MANUALLY" ? (
                    <>
                      <EditPurchaseInvoicePosition
                        inputPlaceholder="nazwa"
                        inputType="string"
                        itemId={item.id}
                        target={
                          <div className={styles.productName}>{tradingDocumentItem.name}</div>
                        }
                        targetKey="name"
                        tradingDocumentId={tradingDocument.id}
                        tradingDocumentItemId={tradingDocumentItem.id}
                        value={tradingDocumentItem.name}
                      />
                      <DeletePurchaseInvoicePosition
                        itemId={item.id}
                        tradingDocumentId={tradingDocument.id}
                        tradingDocumentItemId={tradingDocumentItem.id}
                        tradingDocumentItemName={tradingDocumentItem.name}
                      />
                    </>
                  ) : !enableAddingPosition &&
                    isSalesOrProformaOrAdvance &&
                    tradingDocument.status !== "CONFIRMED" ? (
                    <div className="d-flex align-items-center justify-content-between gap-1">
                      <div className={styles.productName}>{tradingDocumentItem.name}</div>
                      <div className="d-flex justify-content-end">
                        <IconButton
                          icon={MdiDelete}
                          onClick={() =>
                            removeTradingDocumentItemMutation.mutate({
                              id: tradingDocumentItem.id,
                              itemId: item.id,
                              name: tradingDocumentItem.name,
                              tradingDocumentId: tradingDocument.id,
                            })
                          }
                          variant="transparent"
                        />
                      </div>
                    </div>
                  ) : (
                    <div className={styles.productName}>{tradingDocumentItem.name}</div>
                  )}
                </div>
                {isSalesOrProformaOrAdvance && (
                  <div>
                    {item.order && item.order.signature.length > 0 ? item.order?.signature : "--"}
                  </div>
                )}
                {isSalesOrProformaOrAdvance && (
                  <EditDiscountSection
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItem={tradingDocumentItem}
                  />
                )}
                {isSalesOrProformaOrAdvance && (
                  <div className="fw-700 d-flex justify-content-end align-items-center">
                    {tradingDocumentItem.discountAmount !== undefined ? (
                      <FinanceAmountFormat value={tradingDocumentItem.discountAmount} />
                    ) : (
                      "--"
                    )}
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                isNotSalesAndNotProformaAndNotAdvance &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="liczba"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        {tradingDocumentItem.quantity}
                      </div>
                    }
                    targetKey="quantity"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.quantity}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    {tradingDocumentItem.quantity}
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="jednostka"
                    inputType="string"
                    itemId={item.id}
                    target={<div>{tradingDocumentItem.unit}</div>}
                    targetKey="unit"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.unit}
                  />
                ) : (
                  <div className="d-flex justify-content-end">{tradingDocumentItem.unit}</div>
                )}
                {enableAddingPosition &&
                isNotSalesAndNotProformaAndNotAdvance &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="cena netto"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        <FinanceAmountFormat value={tradingDocumentItem.amountWithoutTax} />
                      </div>
                    }
                    targetKey="amountWithoutTax"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.amountWithoutTax}
                  />
                ) : (
                  isNotSalesAndNotProformaAndNotAdvance && (
                    <div className="d-flex align-items-center justify-content-end">
                      <FinanceAmountFormat value={tradingDocumentItem.amountWithoutTax} />
                    </div>
                  )
                )}
                {isSalesOrProformaOrAdvance ? (
                  <EditAmountWithTaxSection
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItem={tradingDocumentItem}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat value={tradingDocumentItem.amountWithTax} />
                  </div>
                )}
                {isSalesOrProformaOrAdvance && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat value={tradingDocumentItem.amountWithTaxAfterDiscount} />
                  </div>
                )}
                {enableAddingPosition &&
                tradingDocumentItem.addedMethod &&
                tradingDocumentItem.addedMethod === "MANUALLY" ? (
                  <EditPurchaseInvoicePosition
                    inputPlaceholder="vat"
                    inputType="number"
                    itemId={item.id}
                    target={
                      <div className="d-flex align-items-center justify-content-end">
                        {tradingDocumentItem.vatRate}%
                      </div>
                    }
                    targetKey="vatRate"
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItemId={tradingDocumentItem.id}
                    value={tradingDocumentItem.vatRate}
                  />
                ) : isSalesOrProformaOrAdvance ? (
                  <EditVatRateSection
                    tradingDocumentId={tradingDocument.id}
                    tradingDocumentItem={tradingDocumentItem}
                  />
                ) : (
                  <div className="d-flex align-items-center justify-content-end">
                    {tradingDocumentItem.vatRate}%
                  </div>
                )}
                {isNotSalesAndNotProformaAndNotAdvance && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat
                      value={(
                        tradingDocumentItem.quantity * tradingDocumentItem.amountWithoutTax
                      ).toFixed(2)}
                    />
                  </div>
                )}
                {isSalesOrProformaOrAdvance && (
                  <div className="d-flex align-items-center justify-content-end">
                    <FinanceAmountFormat
                      value={tradingDocumentItem.totalAmountWithTaxAfterDiscount}
                    />
                  </div>
                )}
              </div>
            )),
          )}
        </div>
        {enableAddingPosition && (
          <>
            <div className="py-2 d-flex align-items-center justify-content-end">
              <Button
                className="text-uppercase"
                onClick={addPurchaseInvoicePosition.open}
                size="small"
                startIcon={MdiAdd}
                variant="gray"
              >
                Dodaj pozycję
              </Button>
            </div>
            {addPurchaseInvoicePosition.isOpen && (
              <AddPurchaseInvoicePosition
                close={addPurchaseInvoicePosition.close}
                maxDisplayPosition={getMaximumDisplayPosition(
                  tradingDocument.items[0].tradingDocumentItems,
                )}
                tradingDocumentOrder={tradingDocument.items[0].id}
              />
            )}
          </>
        )}
        <div className="pt-4">
          <div className={cx(styles.tableHeader, styles.tableHeaderOrderSummary)}>
            <div />
            <div className="d-flex align-items-center justify-content-end">kwota do zapłaty</div>
            <div className="d-flex align-items-center justify-content-end" />
            <div>VAT</div>
            <div className="d-flex align-items-center justify-content-end">wartość netto</div>
            <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
            <div className="d-flex align-items-center justify-content-end">Wartość brutto</div>
          </div>
          {tradingDocument.amountSummaryPerVatRate.map((summary, index) => (
            <div className={cx(styles.tableRow, styles.tableRowOrderSummary)} key={index}>
              <div />
              <div className="d-flex align-items-center justify-content-end">
                {index === 0 && (
                  <div className="d-flex align-items-center">
                    <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                )}
              </div>
              {index === 0 ? (
                <div className="d-flex align-items-center justify-content-end text-grey-35">
                  w tym:
                </div>
              ) : (
                <div />
              )}
              <div>{summary.vatRate}%</div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithoutTax && <FinanceAmountFormat value={summary.totalWithoutTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalTax && <FinanceAmountFormat value={summary.totalTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithTax && <FinanceAmountFormat value={summary.totalWithTax} />}
              </div>
            </div>
          ))}
          <div className={cx(styles.tableRow, styles.tableRowOrderSummary)}>
            <div />
            <div />
            <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
            <div />
            <div className="d-flex align-items-center justify-content-end" />
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalTax} />
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
              </strong>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pt-3 row">
          <div className="pt-1 col-5">
            <div className="pb-3">
              <InfoLabel title="forma płatności">
                {isPurchaseImport ? (
                  <PurchaseInvoiceImportPaymentForm
                    expectedPaymentForm={tradingDocument.expectedPaymentForm}
                    id={tradingDocument.id}
                    status={tradingDocument.status}
                  />
                ) : (
                  <PaymentForm
                    expectedPaymentForm={tradingDocument.expectedPaymentForm}
                    id={tradingDocument.id}
                    invoiceType={tradingDocument.invoiceType}
                    type={tradingDocument.type}
                  />
                )}
              </InfoLabel>
              <InfoLabel title="wystawił(/a)">
                <Typography fontSize="14" fontWeight="700" noWrap>
                  {Boolean(tradingDocument.signatureOfAnAuthorizedPerson)
                    ? tradingDocument.signatureOfAnAuthorizedPerson
                    : "---"}
                </Typography>
              </InfoLabel>
              {isSalesOrProformaOrAdvance && (
                <div className="d-flex align-items-center gap-2 pb-2">
                  <Checkbox
                    checked={tradingDocument.isManagedManually}
                    className="fw-600 mt-1"
                    disabled={true}
                    label="Manualnie edytowana faktura"
                    name="isManagedManually"
                    onChange={noop}
                    overwrites={{ label: { className: styles.manuallyEditedInvoice } }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-7 pl-4 overflow-hidden">
            <TotalAmountSection tradingDocument={tradingDocument} />
            <div
              className={cx(
                styles.verbalNotationWrapper,
                "d-flex flex-column justify-content-center align-items-end pt-2",
              )}
            >
              <div className="d-flex align-items-center justify-content-end body-14-500">
                Kwota słownie:
              </div>
              <div className="d-flex justify-content-end text-right w-100">
                {priceInWords && priceInWords.length > 0 ? (
                  <div className="d-flex flex-column align-items-end w-100">
                    <strong className="body-14 fw-700 d-inline-block nowrap text-truncate w-100">
                      {priceInWords}
                    </strong>
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                ) : (
                  <span className="fw-500 italic">Nie udało się odczytać kwoty </span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <InfoLabel title="zapłacono">
          {tradingDocument.invoiceType !== "PURCHASE" && isNotSalesAndNotProformaAndNotAdvance ? (
            <Typography fontSize="16" fontWeight="700">
              {tradingDocumentConstants.invoicePaymentStatusDict[tradingDocument.paymentStatus]}
            </Typography>
          ) : (
            <Select
              disabled={paymentStatusMutation.isLoading}
              items={tradingDocumentConstants.invoicePaymentStatusOptions}
              onChange={paymentStatus => {
                if (paymentStatus) {
                  paymentStatusMutation.mutate({
                    id: tradingDocument.id,
                    paymentStatus: paymentStatus.id,
                  });
                }
              }}
              selectedItem={tradingDocument.paymentStatus ?? null}
              size="small"
            />
          )}
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
