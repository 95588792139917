import { useRedux, useSelector } from "hooks";
import { createApiQuery } from "hooks/createApiQuery";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { useMutation } from "hooks/useMutation";
import { createMutationHook } from "utilities/createMutationHook";
import { deepMerge } from "utilities/deepMerge";
import {
  getCategoriesVisibleInWmsTable,
  getCustomersToRemind,
  getDedicatedPhoneNumbers,
  getExchangeRatesSettings,
  getMiloSettings,
  getPdfSettings,
  getRemindersSchedule,
  getRemindersSettings,
  getTradingDocumentsSettings,
  patchCommunication,
  patchPdfSettings,
  patchSales,
  patchTradingDocument,
  postTestEmailMessage,
} from "./calls";
import { pdfSettingsKeys, settingsKeys } from "./keys";
import { Communication, PdfSettings } from "./models";

export const useMiloSettings = createApiQuery(getMiloSettings);
export const useExchangeRatesSettings = createPaginatedApiQuery(getExchangeRatesSettings);
export const useTradingDocumentsSettings = createApiQuery(getTradingDocumentsSettings);
export const useRemindersSettings = createPaginatedApiQuery(getRemindersSettings);
export const useCustomersToRemind = createPaginatedApiQuery(getCustomersToRemind);
export const useRemindersSchedule = createPaginatedApiQuery(getRemindersSchedule);
export const useDedicatedPhoneNumbers = createPaginatedApiQuery(getDedicatedPhoneNumbers);
export const useCategoriesVisibleInWmsTable = createPaginatedApiQuery(
  getCategoriesVisibleInWmsTable,
);
export const usePdfSettings = createApiQuery(getPdfSettings);

export const useSalesSettingsPatch = () => {
  const { handleMutate } = useMiloSettings("sales");
  const [dispatch, { settings }] = useRedux();

  return useMutation(patchSales, {
    onMutate: args => {
      return handleMutate(draft => {
        Object.assign(draft, deepMerge(draft, args));
      });
    },
    onError: (error, _, rollback) => {
      //@ts-ignore
      rollback(error);
    },
    onSuccess: (_, args) => {
      dispatch(settings.updateSettings({ sales: args }));
    },
  });
};

export const usePdfSettingsMutation = createMutationHook(patchPdfSettings, (_, utils) => ({
  onMutate: ([data]) => {
    return utils.handleMutate(pdfSettingsKeys.pdfs.details(), (draft: PdfSettings) => {
      Object.assign(draft, deepMerge(draft, data));
    });
  },
  onError: (error, _, prevValue) => {
    utils.rollback(pdfSettingsKeys.pdfs.details(), prevValue);
    const err =
      typeof Object.values(error)[0] === "string"
        ? error
        : ((Object.values(error)[0] as unknown) as Record<string, string>);
    utils.genericErrorToastr(err);
  },
}));

export const useCommunicationSettingsMutation = createMutationHook(
  patchCommunication,
  (_, utils) => ({
    onMutate: ([data]) => {
      return utils.handleMutate(settingsKeys["communication"], (draft: Communication) => {
        Object.assign(draft, deepMerge(draft, data));
      });
    },
    onError: (error, _, prevValue) => {
      utils.rollback(settingsKeys["communication"], prevValue);
      // In reality the error that comes from the backend is not what is typed,
      // so we need to override it.
      const err =
        typeof Object.values(error)[0] === "string"
          ? error
          : ((Object.values(error)[0] as unknown) as Record<string, string>);
      utils.genericErrorToastr(err);
    },
  }),
);

export const useTradingDocumentSettingsPatch = () => {
  const tradingDocumentsSettingsId = useSelector(
    store => store.partials.finances.tradingDocumentSettings[0].id,
  );
  const { handleMutate } = useTradingDocumentsSettings({ id: tradingDocumentsSettingsId });
  const [dispatch, { settings }] = useRedux();

  return useMutation(patchTradingDocument, {
    onMutate: args => {
      return handleMutate(draft => {
        Object.assign(draft, deepMerge(draft, args));
      });
    },
    onError: (error, _, rollback) => {
      //@ts-ignore
      rollback(error);
    },
    onSuccess: (_, args) => {
      dispatch(settings.updateSettings({ tradingDocument: args }));
    },
  });
};

export const useTestEmailMutation = createMutationHook(postTestEmailMessage, (_, utils) => ({
  onError: error => {
    utils.genericErrorToastr(error);
  },
}));
