import {
  NormalizedTradingDocumentExchangeRateSummary,
  TradingDocument,
  TradingDocumentSummaryType,
} from "api/trading-documents/models";
import styles from "../../CreateDraftDocument.module.css";
import { Table } from "components/miloDesignSystem/molecules/table";
import cuid from "cuid";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useExchangeRateSectionColumns } from "./useExchangeRateSectionColumns";
import { cx } from "utilities";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";

interface Props {
  draftInvoice: TradingDocument;
}

export const ExchangeRateSection = ({ draftInvoice }: Props) => {
  const normalizedRows = getNormalizedExchangeRateSummaryData(
    draftInvoice.amountSummaryCurrencyConvert,
    draftInvoice.amountSummaryPerVatRateCurrencyConvert,
  );
  const columns = useExchangeRateSectionColumns();

  return (
    <div className={styles.exchangeRateSection}>
      <Table<NormalizedTradingDocumentExchangeRateSummary>
        columns={columns}
        rows={normalizedRows}
        isLoading={false}
        error={null}
        uiSchema={comfortableListUiSchema}
      />
      <div
        className={cx(
          styles.exchangeRateSectionSummary,
          "d-flex align-items-center justify-content-end gap-3",
        )}
      >
        <Typography color="neutralBlack88" fontSize="16" fontWeight="600">
          razem do zapłaty:
        </Typography>
        <div
          className={cx(
            styles.exchangeRateSectionSummaryAmount,
            "d-flex align-items-center justify-content-end",
          )}
        >
          <AmountDisplay
            amount={
              draftInvoice.amountSummaryCurrencyConvert.totalWithTax
                ? String(draftInvoice.amountSummaryCurrencyConvert.totalWithTax)
                : "0.00"
            }
            className={styles.amountDisplay}
            currency="PLN"
            decimal={{ fontSize: "12", fontWeight: "700" }}
            integer={{ fontSize: "20", fontWeight: "600" }}
          />
        </div>
      </div>
    </div>
  );
};

const getNormalizedExchangeRateSummaryData = (
  amountSummaryCurrencyConvert: TradingDocument["amountSummaryCurrencyConvert"],
  amountSummaryPerVatRateCurrencyConvert: TradingDocument["amountSummaryPerVatRateCurrencyConvert"],
): NormalizedTradingDocumentExchangeRateSummary[] => {
  return [
    ...amountSummaryPerVatRateCurrencyConvert.map(summary => ({
      ...summary,
      id: cuid(),
      type: TradingDocumentSummaryType.VAT_RATE,
    })),
    {
      id: cuid(),
      exchangeRate: amountSummaryCurrencyConvert.exchangeRate,
      exchangeRateDate: amountSummaryCurrencyConvert.exchangeRateDate,
      totalTax: amountSummaryCurrencyConvert.totalTax,
      totalWithoutTax: amountSummaryCurrencyConvert.totalWithoutTax,
      totalWithTax: amountSummaryCurrencyConvert.totalWithTax,
      type: TradingDocumentSummaryType.TOTAL,
    },
  ];
};
