import { ManufacturerSingleOrderListItem } from "api/manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag/Tag";
import { Typography } from "components/miloDesignSystem/atoms/typography/Typography";
import { manufacturingConstants } from "constants/manufacturingConstants";
import { getStandardDateFormat } from "utilities";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useManufacturerSingleOrdersColumns = () => {
  return useCreateTableColumns<ManufacturerSingleOrderListItem>(({ columnHelper }) => {
    return [
      columnHelper.text(row => row.manufacturingItem?.signature, {
        header: "sygnatura",
        size: 100,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.text(row => row.material.name, {
        header: "materiał",
        size: 130,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.text(row => row.material.category?.name, {
        header: "kategoria",
        size: 80,
        typographyProps: {
          color: "neutralBlack88",
          fontWeight: "400",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "materialCount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            ilość/liczba
          </Typography>
        ),
        size: 70,
        cell: info => {
          const singleOrder = info.getValue();
          return (
            <Typography
              className="w-100 text-right"
              color="neutralBlack88"
              fontSize="12"
              fontWeight="700"
              noWrap
            >
              {singleOrder.quantity} {singleOrder.material.unit.shortName}
            </Typography>
          );
        },
      }),
      columnHelper.accessor(row => row.status, {
        header: "status realizacji",
        size: 120,
        cell: info => {
          const status = info.getValue();
          return <Tag {...manufacturingConstants.manufacturingOrderStatusToTag[status]} />;
        },
      }),
      columnHelper.accessor(row => row.scheduledDeadline, {
        header: "dostawa",
        size: 70,
        cell: info => {
          const deliveryDeadline = info.getValue();
          if (!deliveryDeadline) return <EmptyValue />;
          return (
            <Tag
              label={getStandardDateFormat(deliveryDeadline)}
              type="outlined"
              variant="quaternary"
            />
          );
        },
      }),
      columnHelper.accessor(row => row.receivedAt, {
        header: "odebrano",
        size: 80,
        cell: info => {
          const receivedAt = info.getValue();
          if (!receivedAt) return <EmptyValue />;
          return (
            <Tag label={getStandardDateFormat(receivedAt)} type="outlined" variant="quaternary" />
          );
        },
      }),
    ];
  });
};
