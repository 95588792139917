import { Modal } from "components/miloDesignSystem/atoms/modal";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import styles from "./DeliveredSalesReportModal.module.css";
import { useState } from "react";
import { getIsoDateFormat, queryString } from "utilities";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { orderFileFactory } from "api/orders/calls";
import { fileDownloader } from "fileDownloader";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";
import { useErrorToastr } from "hooks/useErrorToastr";

interface Props {
  close: () => void;
}

export const DeliveredSalesReportModal = ({ close }: Props) => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const businessEntities = useSelector(store => store.partials.businessEntities);
  const [createdFrom, setCreatedFrom] = useState("");
  const [createdTo, setCreatedTo] = useState("");
  const [deliveredFrom, setDeliveredFrom] = useState("");
  const [deliveredTo, setDeliveredTo] = useState("");
  const [hasComplaint, setHasComplaint] = useState(false);
  const handleErrorMessage = useErrorToastr();

  const internalBusinessEntitiesOptions = businessEntities
    .filter(entity => entity.kind === "INTERNAL")
    .map(entity => ({
      value: entity.id,
      text: entity.companyName,
      type: MenuItemType.TEXT,
    }));

  const [seller, setSeller] = useState(internalBusinessEntitiesOptions?.[0]?.value);

  async function handleDownloadReportCsv() {
    const tstr = downloadFeedbackToastr.open({ type: "csv", calculateProgress: false });
    const search = queryString.stringify({
      createdFrom,
      createdTo,
      deliveredFrom,
      deliveredTo,
      sellerId: seller,
      type: hasComplaint ? "" : "STANDARD",
    });
    const { url, name } = orderFileFactory.ordersDeliveredSalesReport(search);
    const response = await fileDownloader({
      url,
      name,
      type: "csv",
    });

    if (response.status === "success") {
      tstr.lazyClose();
      close();
    } else if (response.error) {
      tstr.close();
      handleErrorMessage(response);
    }
  }

  return (
    <Modal
      close={close}
      isOpen
      title={
        <Typography fontSize="20" fontWeight="700">
          Pobierz raport dla biura rachunkowego
        </Typography>
      }
      width={480}
    >
      <div className="p-3">
        <div className="pb-1">
          <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
            Data utworzenia
          </Typography>
          <div className="d-flex gap-1">
            <div className="position-relative mr-1">
              <span className={styles.label}>Od:</span>
              <DatePicker
                placeholder="Wybierz"
                className={styles.date}
                value={createdFrom}
                selectsStart
                removeDate={false}
                tabIndex={-1}
                look="common"
                startDate={createdFrom ? new Date(createdFrom) : null}
                endDate={createdFrom ? new Date(createdFrom) : null}
                maxDate={createdTo ? new Date(createdTo) : null}
                onChange={date => {
                  if (!date) return;
                  setCreatedFrom(getIsoDateFormat(date));
                }}
              />
            </div>
            <div className="position-relative">
              <span className={styles.label}>Do:</span>
              <DatePicker
                placeholder="Wybierz"
                tabIndex={-1}
                className={styles.date}
                removeDate={false}
                value={createdTo}
                selectsEnd
                look="common"
                startDate={createdFrom ? new Date(createdFrom) : null}
                endDate={createdTo ? new Date(createdTo) : null}
                minDate={createdFrom ? new Date(createdFrom) : null}
                onChange={date => {
                  if (!date) return;
                  setCreatedTo(getIsoDateFormat(date));
                }}
              />
            </div>
          </div>
        </div>
        <div className="pb-4">
          <Typography className="mt-2 mb-3" fontSize="14" fontWeight="700">
            Data dostarczenia
          </Typography>
          <div className="d-flex gap-1">
            <div className="position-relative mr-1">
              <span className={styles.label}>Od:</span>
              <DatePicker
                placeholder="Wybierz"
                className={styles.date}
                value={deliveredFrom}
                selectsStart
                removeDate={false}
                tabIndex={-1}
                look="common"
                startDate={deliveredFrom ? new Date(deliveredFrom) : null}
                endDate={deliveredFrom ? new Date(deliveredFrom) : null}
                maxDate={deliveredTo ? new Date(deliveredTo) : null}
                onChange={date => {
                  if (!date) return;
                  setDeliveredFrom(getIsoDateFormat(date));
                }}
              />
            </div>
            <div className="position-relative">
              <span className={styles.label}>Do:</span>
              <DatePicker
                placeholder="Wybierz"
                tabIndex={-1}
                className={styles.date}
                removeDate={false}
                value={deliveredTo}
                selectsEnd
                look="common"
                startDate={deliveredFrom ? new Date(deliveredFrom) : null}
                endDate={deliveredTo ? new Date(deliveredTo) : null}
                minDate={deliveredFrom ? new Date(deliveredFrom) : null}
                onChange={date => {
                  if (!date) return;
                  setDeliveredTo(getIsoDateFormat(date));
                }}
              />
            </div>
          </div>
        </div>
        <div className="pb-4">
          <Checkbox
            checked={hasComplaint}
            label="Uwzględnij reklamacje"
            onChange={status => setHasComplaint(status)}
            size="sm"
          />
        </div>
        <div className="pb-3">
          <Select
            items={internalBusinessEntitiesOptions}
            onChange={id => id && setSeller(String(id))}
            label="Wybierz podmiot biznesowy"
            selected={seller}
          />
        </div>
        <div className="d-flex align-items-center gap-3 pt-3 borderTop">
          <Button className="text-uppercase" onClick={close} size="medium" variant="gray">
            Anuluj
          </Button>
          <Button
            className="text-uppercase"
            onClick={handleDownloadReportCsv}
            size="medium"
            variant="deepPurple"
          >
            Pobierz
          </Button>
        </div>
      </div>
    </Modal>
  );
};
