import { ISODateTime } from "api/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { isYesterday } from "date-fns";
import { dateFns, getStandardDateFormat, getStandardTimeFormat } from "utilities";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  fontSize: TypographyProps["fontSize"];
  fontWeight: TypographyProps["fontWeight"];
  date: ISODateTime | null;
}

export const ReturnDate = ({ date, fontSize, fontWeight }: Props) => {
  if (!date) return <EmptyValue fontSize={fontSize} fontWeight={fontWeight} />;

  if (dateFns.isToday(new Date(date)))
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        {getStandardTimeFormat(date)}
      </Typography>
    );

  if (isYesterday(new Date(date)))
    return (
      <Typography fontSize={fontSize} fontWeight={fontWeight}>
        wczoraj, {getStandardTimeFormat(date)}
      </Typography>
    );

  return (
    <Typography fontSize={fontSize} fontWeight={fontWeight}>
      {getStandardDateFormat(date)}
    </Typography>
  );
};
