import { EmployeesTab as Tab } from "api/manufacturingNew/models";

export const getTabs = (): { label: string; name: Tab }[] => {
  return [
    { label: "Wszyscy", name: "all" },
    { label: "Tapicerzy", name: "upholsterer" },
    { label: "Stolarze", name: "carpenter" },
    { label: "Krawcowe", name: "seamstress" },
    { label: "Krojczowie", name: "cutter" },
    { label: "Pakowacze", name: "packer" },
  ];
};
