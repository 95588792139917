import { RightPanelSection } from "components/utils/drawer";
import { postOrderSms_query } from "api/orders/calls";
import { OrderMessagesSchema } from "api/orders/models";
import cx from "classnames";
import { Button } from "components/common";
import { CommonError, Spinner } from "components/utils";
import cuid from "cuid";
import { useQuery, useSelector } from "hooks";
import { useOrderMessages } from "hooks/apiPrimitives";
import { useMutation } from "hooks/useMutation";
import { useMemo, useRef, useState } from "react";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "./MessagesWithCustomer.module.css";
import { Messages } from "./components/Messages";
import send from "assets/images/send.svg";
import arrowDown from "assets/images/keyboardArrowDown.svg";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";

type Message = OrderMessagesSchema["messages"][number];

export const MessagesWithCustomer = () => {
  const panelId = useQuery().query.panelId;
  const containerRef = useRef<HTMLDivElement>(null);
  const { data, isLoading, error, handleMutate } = useOrderMessages(panelId);
  const [body, setBody] = useState("");
  const me = useSelector(state => state.auth.user!);

  const sendSmsMutation = useMutation(postOrderSms_query, {
    onMutate: () => {
      const rollback = handleMutate(draft => {
        draft.messages.push({
          body,
          created: String(new Date()),
          createdBy: {
            ...me,
            initials: `${me.firstName.charAt(0).toUpperCase()}${me.lastName
              .charAt(0)
              .toUpperCase()}`,
          },
          isRead: false,
          readAt: null,
          readBy: null,
          failureReason: "",
          id: cuid(),
          kind: "NORMAL",
          sentToPhoneNumber: "",
          status: "AWAITING",
          timestamp: null,
          wayOfCommunication: "OUTGOING",
        });
      });
      setBody("");
      const anchor = containerRef.current;
      anchor!.scrollIntoView();
      return rollback;
    },
    onError: (error, _, rollback) => {
      //@ts-ignore
      rollback(error);
    },
  });

  const groupedMessages = useMemo(() => {
    if (!data) return [];
    const grouped: Message[][] = [];
    data.messages.forEach(message => {
      if (!grouped.length) {
        grouped.push([message]);
        return;
      }
      const lastArray = grouped[grouped.length - 1];
      const lastMessage = lastArray[lastArray.length - 1];
      const startTime = new Date(lastMessage.created);
      const endTime = new Date(message.created);
      const difference = endTime.getTime() - startTime.getTime();
      const resultInMinutes = Math.round(difference / 60000);
      const shouldBeGrouped = resultInMinutes <= 5 && lastMessage.status === message.status;

      if (!lastMessage.createdBy && !message.createdBy && shouldBeGrouped) {
        lastArray.push(message);
        return;
      }
      if (
        lastMessage.createdBy &&
        message.createdBy &&
        lastMessage.createdBy.id === message.createdBy.id &&
        shouldBeGrouped
      ) {
        lastArray.push(message);
        return;
      }
      grouped.push([message]);
    });
    return grouped;
  }, [data]);

  if (error) {
    return <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return (
      <div className={styles.container}>
        <Spinner color="blue" size="big" text="trwa wczytywanie wiadomości" position="absolute" />
      </div>
    );
  }

  const handleSubmit = () => {
    assertIsDefined(data);
    sendSmsMutation.mutate({ message: body, orderId: data.order.id });
  };
  if (!data) return null;
  return (
    <RightPanelSection titleClassName="px-3" padding="" title="Wiadomości">
      {data.messages.length > 0 && (
        <div className={cx(styles.messageListWrapper, "px-3 py-3 overflow-auto")}>
          {groupedMessages.map(message => {
            return (
              <Messages
                key={message[0].id}
                messagesData={message}
                me={me}
                clientAvatar={data.client.avatar}
              />
            );
          })}
          <div ref={containerRef} />
        </div>
      )}

      <div className="d-flex align-items-center gap-2 px-3 py-2">
        <Avatar size="md" user={me} />
        <input
          className="input w-100"
          placeholder="Napisz wiadomość"
          value={body}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => setBody(e.target.value)}
          onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (e.code === "Enter") {
              e.preventDefault();
              e.currentTarget.blur();
              handleSubmit();
            }
          }}
        />
        <Button
          kind="btnBgDeepPurple"
          size="btnSizeMedium"
          className="btn"
          disabled={sendSmsMutation.isLoading}
          onClick={handleSubmit}
        >
          <div className="btnBase btnBase16 btnBaseMedium uppercase">
            <img src={send} alt="" />
            Wyślij
          </div>
          <div className="btnBase btnBaseMedium borderLeft px-1">
            <img src={arrowDown} alt="" />
          </div>
        </Button>
      </div>
    </RightPanelSection>
  );
};
