import { useQuery } from "hooks";
import { getSearch } from "./utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { getTabs } from "./utils/getTabs";
import { useLongHeldPackagesColumns } from "./useLongHeldPackagesColumns";
import { longHeldPackages } from "components/common/moduleNavigation/moduleConfig/wms/routes/longHeldPackages";
import { uniquePackagesAction } from "api/unique-packages/actions";
import { RouteComponentProps } from "react-router";
import { LongHeldPackage, LongHeldPackageTab } from "api/unique-packages/models";
import { useEffect } from "react";
import { withFilters } from "components/utils/withFilters";
import { longHeldPackagesFilters } from "./tableFilters";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const LongHeldPackages = withFilters(
  longHeldPackagesFilters,
  ({ match }: RouteComponentProps<{ tab: LongHeldPackageTab }>) => {
    const { tab } = match.params;
    const { query } = useQuery({ exclude: ["panelId"] });
    const search = getSearch({ query, tab });
    const {
      data: _packages,
      error,
      isPreviousData,
      isLoading,
      isFetching,
      pagination,
      refetch,
    } = uniquePackagesAction.useLongHeldPackages(search);
    const columns = useLongHeldPackagesColumns(tab);
    const tableProps = useTableFeatureConnector({
      rows: _packages,
      withPagination: { pagination },
    });

    useEffect(() => {
      refetch();
    }, [tab, refetch]);

    return (
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj...",
            tags: [],
          }}
          tabs={{
            list: getTabs(),
            routesRoot: `wms/${longHeldPackages.url}`,
            urlSpan: "list",
          }}
          viewLabel="LONG_HELD_PACKAGES"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<LongHeldPackage>
            columns={columns}
            isLoading={isLoading || isFetching || isPreviousData}
            error={error}
            uiSchema={mainListBigUiSchema}
            {...tableProps}
            overrides={() => ({
              ...tableProps?.overrides?.(),
              defaultPaginationVisibility: true,
            })}
          />
        </div>
      </PageWrapper>
    );
  },
);
