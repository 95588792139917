import { Pagination, UUID } from "api/types";
import { manufacturingKeys } from "./keys";
import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import {
  CommissionListItem,
  IndexStages,
  ManufacturerSingleOrderListItem,
  ManufacturingEmail,
  ManufacturingOrder,
  MaterialOrderListItem,
  PostManufacturingEmailPayload,
  PostManufacturingOrdersReceivePayload,
  PostRecipe,
  Recipe,
  StageBoardDefaultAttributesKind,
  StageBoardDefaultAttributesKindPayload,
  StageComponent,
  StageComponentKind,
} from "./models";
import { parsePatchData } from "utilities/parsePatchData";
import { Assign } from "utility-types";
import {
  PostOrderManufacturingMaterial,
  PostOrderManufacturingMaterialForStageList,
} from "./materials/models";

const getManufacturingOrders = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingOrder>> => ({
  key: manufacturingKeys.manufacturingOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "manufacturing/orders/items" + search,
    }),
});

const getCommissionsOrders = (search: string = ""): ApiFetcher<Pagination<CommissionListItem>> => ({
  key: manufacturingKeys.commission.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "manufacturing/commissions/orders" + search,
    }),
});

const getManufacturerOrderEmails = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturingEmail>> => ({
  key: manufacturingKeys.email.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/orders/emails" + search,
    }),
});

const postManufacturingEmails = (data: PostManufacturingEmailPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/orders/send-emails",
    data,
  });

const getManufacturingOrder = (orderId: UUID): ApiFetcher<ManufacturingOrder> => ({
  key: manufacturingKeys.manufacturingOrder(orderId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/orders/items/" + orderId,
    }),
});

const patchManufacturingOrder = (data: Assign<Partial<ManufacturingOrder>, { id: UUID }>) =>
  queryFetch<ManufacturingOrder>({
    method: "PATCH",
    url: "/manufacturing/orders/items/" + data.id,
    data: parsePatchData(data),
  });

const getManufacturingOrdersMaterials = (
  search: string = "",
): ApiFetcher<Pagination<ManufacturerSingleOrderListItem>> => ({
  key: manufacturingKeys.manufacturerOrderMaterial.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/orders/materials" + search,
    }),
});

const getManufacturingMaterialOrders = (
  search: string = "",
): ApiFetcher<Pagination<MaterialOrderListItem>> => ({
  key: manufacturingKeys.materialOrders(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "manufacturing/orders/materials" + search,
    }),
});

const getIndexStages = (search: string): ApiFetcher<Pagination<IndexStages>> => ({
  key: manufacturingKeys.indexStages(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stages/index-stages${search}`,
    }),
});

const getStageComponentsItems = (search: string): ApiFetcher<Pagination<StageComponent>> => ({
  key: manufacturingKeys.stageComponents(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/stage-components/items${search}`,
    }),
});

const postStageComponent = (data: {
  index: number;
  stage: UUID;
  recipe: UUID;
  kind: StageComponentKind;
}) =>
  queryFetch<Recipe>({
    method: "POST",
    url: "/manufacturing/stage-components/items",
    data,
  });

const deleteStageComponent = (stageComponentId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/stage-components/items/" + stageComponentId,
  });

const patchStageComponent = (data: Assign<Partial<StageComponent>, { id: UUID }>) =>
  queryFetch<Recipe>({
    method: "PATCH",
    url: "/manufacturing/stage-components/items/" + data.id,
    data: parsePatchData(data),
  });

const getRecipes = (search: string): ApiFetcher<Pagination<Recipe>> => ({
  key: manufacturingKeys.recipes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/manufacturing/recipes/items${search}`,
    }),
});

const getStageBoardAttributesKind = (
  search: string,
): ApiFetcher<Pagination<StageBoardDefaultAttributesKind>> => ({
  key: manufacturingKeys.settings.stageBoardAttributesKind(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by" + search,
    }),
});

const postStageBoardAttributesKind = (data: StageBoardDefaultAttributesKindPayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by",
    data,
  });

const deleteStageBoardAttributesKind = (attributesKindId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/settings/stage-board-attributes-kind-to-group-by/" + attributesKindId,
  });

const patchRecipe = (data: Assign<Partial<Recipe>, { id: UUID }>) =>
  queryFetch<Recipe>({
    method: "PATCH",
    url: "/manufacturing/recipes/items/" + data.id,
    data: parsePatchData(data),
  });

const deleteRecipe = (id: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/recipes/items/" + id,
  });

const postRecipe = (data: PostRecipe) =>
  queryFetch<Recipe>({
    method: "POST",
    url: "/manufacturing/recipes/items",
    data,
  });

const postManufacturingOrders = (
  data: PostOrderManufacturingMaterial | PostOrderManufacturingMaterialForStageList,
) =>
  queryFetch<void>({
    method: "POST",
    url: "manufacturing/orders/items",
    data,
  });

const getManufacturingOrdersDeliveries = (search: string): ApiFetcher<Pagination<any>> => ({
  key: manufacturingKeys.orderDeliveries(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "manufacturing/orders/deliveries" + search,
    }),
});

const postManufacturingOrdersReceive = (data: PostManufacturingOrdersReceivePayload) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/orders/receive",
    data,
  });

export const manufacturingApi = {
  deleteStageBoardAttributesKind,
  getRecipes,
  getStageBoardAttributesKind,
  postRecipe,
  patchRecipe,
  deleteRecipe,
  getIndexStages,
  getStageComponentsItems,
  postStageComponent,
  deleteStageComponent,
  patchStageComponent,
  postStageBoardAttributesKind,
  getManufacturingOrdersDeliveries,
  postManufacturingOrders,
  getManufacturingMaterialOrders,
  getManufacturingOrders,
  getManufacturingOrder,
  patchManufacturingOrder,
  getManufacturingOrdersMaterials,
  getCommissionsOrders,
  getManufacturerOrderEmails,
  postManufacturingEmails,
  postManufacturingOrdersReceive,
};
