import styles from "../RightPanel.module.css";
import cx from "classnames";
import { RightPanelSection } from "components/utils/drawer";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { AsyncInput } from "components/utils";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { tradingDocumentConstants } from "constants/tradingDocuments";

interface Props {
  tradingDocument: TradingDocument;
}

export const ItemsSummarySection = ({ tradingDocument }: Props) => {
  const sortedTradingDocumentItems = tradingDocument.items
    .flatMap(item => item.tradingDocumentItems)
    .sort((a, b) => a.displayPosition - b.displayPosition);
  const expectedPaymentFormMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection>
      <div className="pb-3">
        <div className={cx(styles.tableHeader, styles.tableHeaderOrder)}>
          <div>#</div>
          <div>nazwa towaru</div>
          <div className="d-flex align-items-center justify-content-end">liczba</div>
          <div>jednostka</div>
          <div className="d-flex align-items-center justify-content-end">cena brutto</div>
          <div className="d-flex align-items-center justify-content-end">VAT</div>
          <div className="d-flex align-items-center justify-content-end">Wartość brutto</div>
        </div>
        <div>
          {sortedTradingDocumentItems.map((tradingDocumentItem, index) => (
            <div className={cx(styles.tableRow, styles.tableRowOrder)} key={index}>
              <div>{tradingDocumentItem.displayPosition}.</div>
              <div>
                <div className={styles.cellFixHeight}>
                  <div className={styles.productName}>{tradingDocumentItem.name}</div>
                  <div className={cx(styles.productName, "body-10-500")}>
                    {tradingDocumentItem.verboseAttributesValues}
                  </div>
                </div>
                <div>Korekta</div>
                <div>Po korekcie</div>
              </div>
              <div>
                <div
                  className={cx(
                    styles.cellFixHeight,
                    "d-flex align-items-center justify-content-end",
                  )}
                >
                  {tradingDocumentItem.oldQuantity !== null
                    ? tradingDocumentItem.oldQuantity
                    : "--"}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.quantity !== null ? tradingDocumentItem.quantity : "--"}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.newQuantity !== null
                    ? tradingDocumentItem.newQuantity
                    : "--"}
                </div>
              </div>
              <div>
                <div className={cx(styles.cellFixHeight, "d-flex align-items-center")}>
                  {tradingDocumentItem.unit}
                </div>
                <div>{tradingDocumentItem.unit}</div>
                <div>{tradingDocumentItem.unit}</div>
              </div>
              <div>
                <div
                  className={cx(
                    styles.cellFixHeight,
                    "d-flex align-items-center justify-content-end",
                  )}
                >
                  <FinanceAmountFormat value={tradingDocumentItem.oldAmountWithTaxAfterDiscount} />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <FinanceAmountFormat value={tradingDocumentItem.amountWithTaxAfterDiscount} />
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  <FinanceAmountFormat value={tradingDocumentItem.newAmountWithTaxAfterDiscount} />
                </div>
              </div>
              <div>
                <div
                  className={cx(
                    styles.cellFixHeight,
                    "d-flex align-items-center justify-content-end",
                  )}
                >
                  {tradingDocumentItem.oldVatRate !== null ? (
                    <>{tradingDocumentItem.oldVatRate}%</>
                  ) : (
                    "--"
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.vatRate !== null ? (
                    <>{tradingDocumentItem.vatRate}%</>
                  ) : (
                    "--"
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.newVatRate !== null ? (
                    <>{tradingDocumentItem.newVatRate}%</>
                  ) : (
                    "--"
                  )}
                </div>
              </div>
              <div>
                <div
                  className={cx(
                    styles.cellFixHeight,
                    "d-flex align-items-center justify-content-end",
                  )}
                >
                  {tradingDocumentItem.oldTotalAmountWithTaxAfterDiscount !== null ? (
                    <FinanceAmountFormat
                      value={tradingDocumentItem.oldTotalAmountWithTaxAfterDiscount}
                    />
                  ) : (
                    <EmptyValue fontWeight="700" />
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.totalAmountWithTaxAfterDiscount !== null ? (
                    <FinanceAmountFormat
                      value={tradingDocumentItem.totalAmountWithTaxAfterDiscount}
                    />
                  ) : (
                    <EmptyValue fontWeight="700" />
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-end">
                  {tradingDocumentItem.newTotalAmountWithTaxAfterDiscount !== null ? (
                    <FinanceAmountFormat
                      value={tradingDocumentItem.newTotalAmountWithTaxAfterDiscount}
                    />
                  ) : (
                    <EmptyValue fontWeight="700" />
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="pt-4">
          <div className={cx(styles.tableHeader, styles.tableHeaderOrderSummary)}>
            <div />
            <div className="d-flex align-items-center justify-content-end">kwota do zapłaty</div>
            <div className="d-flex align-items-center justify-content-end" />
            <div>VAT</div>
            <div className="d-flex align-items-center justify-content-end">wartość netto</div>
            <div className="d-flex align-items-center justify-content-end">kwota VAT</div>
            <div className="d-flex align-items-center justify-content-end">Wartość brutto</div>
          </div>
          {tradingDocument.amountSummaryPerVatRate.map((summary, index) => (
            <div className={cx(styles.tableRow, styles.tableRowOrderSummary)} key={index}>
              <div />
              <div className="d-flex align-items-center justify-content-end">
                {index === 0 && (
                  <div className="d-flex align-items-center">
                    <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                )}
              </div>
              {index === 0 ? (
                <div className="d-flex align-items-center justify-content-end text-grey-35">
                  w tym:
                </div>
              ) : (
                <div />
              )}
              <div>{summary.vatRate}%</div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithoutTax && <FinanceAmountFormat value={summary.totalWithoutTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalTax && <FinanceAmountFormat value={summary.totalTax} />}
              </div>
              <div className="d-flex align-items-center justify-content-end">
                {summary.totalWithTax && <FinanceAmountFormat value={summary.totalWithTax} />}
              </div>
            </div>
          ))}
          <div className={cx(styles.tableRow, styles.tableRowOrderSummary)}>
            <div />
            <div />
            <div className="d-flex align-items-center justify-content-end text-grey-35">suma:</div>
            <div />
            <div className="d-flex align-items-center justify-content-end" />
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalTax} />
              </strong>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <strong className="body-14-600 fw-800">
                <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
              </strong>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between pt-3">
          <div className="pt-1">
            <div className="pb-3">
              <InfoLabel title="forma płatności">
                <AsyncInput
                  disabled={expectedPaymentFormMutation.isLoading}
                  inProgress={expectedPaymentFormMutation.isLoading}
                  onChange={value =>
                    expectedPaymentFormMutation.mutate({
                      id: tradingDocument.id,
                      expectedPaymentForm: value,
                    })
                  }
                  overwrites={{
                    container: { className: styles.input },
                    input: {
                      className: cx(styles.input, {
                        italic:
                          tradingDocument.expectedPaymentForm !== "CASH" &&
                          tradingDocument.expectedPaymentForm !== "ONLINE",
                      }),
                    },
                  }}
                  placeholder="forma płatności"
                  value={
                    tradingDocument.expectedPaymentForm === "CASH" ||
                    tradingDocument.expectedPaymentForm === "ONLINE"
                      ? tradingDocumentConstants.expectedPaymentFormDict[
                          tradingDocument.expectedPaymentForm
                        ]
                      : tradingDocument.expectedPaymentForm
                  }
                />
              </InfoLabel>
              <InfoLabel title="wystawił(/a)">
                <strong className="body-14">
                  {tradingDocument.signatureOfAnAuthorizedPerson
                    ? tradingDocument.signatureOfAnAuthorizedPerson
                    : "---"}
                </strong>
              </InfoLabel>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-items-center justify-content-end">
                <Typography fontSize="14" fontWeight="500">
                  razem do {tradingDocument.amountSummary.totalWithTax > 0 ? "zapłaty" : "zwrotu"}:
                </Typography>
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <strong className="body-18 fw-800">
                  <div className="d-flex align-items-center">
                    <FinanceAmountFormat
                      value={Math.abs(tradingDocument.amountSummary.totalWithTax)}
                    />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div>
                </strong>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-items-center justify-content-end body-14-500">
                zapłacono:
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <strong className="body-14 fw-800">
                  backend
                  {/* <div className="d-flex align-items-center">
                    <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div> */}
                </strong>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end gap-3">
              <div className="d-flex align-items-center justify-content-end body-14-500">
                pozostaje:
              </div>
              <div className="d-flex align-items-center justify-content-end">
                <strong className="body-14 fw-800">
                  backend
                  {/* <div className="d-flex align-items-center">
                    <FinanceAmountFormat value={tradingDocument.amountSummary.totalWithTax} />
                    <div className="fs-12 text-color-grey">{tradingDocument.currency}</div>
                  </div> */}
                </strong>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pb-2">
        <InfoLabel title="zapłacono">
          <div className="fw-800">
            {tradingDocumentConstants.invoicePaymentStatusDict[tradingDocument.paymentStatus]}
          </div>
        </InfoLabel>
      </div>
    </RightPanelSection>
  );
};
