import {
  NormalizedTradingDocumentSummary,
  TradingDocument,
  TradingDocumentSummaryType,
} from "api/trading-documents/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "../../CreateDraftDocument.module.css";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { CURRENCY_TYPE } from "CONSTANTS";

export const useSummarySectionColumns = (draftInvoice: TradingDocument) => {
  return useCreateTableColumns<NormalizedTradingDocumentSummary>(({ columnHelper }) => {
    return [
      columnHelper.stretchContent,
      columnHelper.accessor(row => row.totalWithTax, {
        id: "totalWithTaxAmount",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kwota do zapłaty
          </Typography>
        ),
        size: 100,
        cell: info => {
          const isFirstRow = info.row.index === 0;
          const totalWithTax = info.getValue();
          if (!isFirstRow) return null;
          return (
            <AmountDisplay
              amount={totalWithTax ? String(totalWithTax) : "0.00"}
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "additionalLabels",
        header: " ",
        size: 60,
        cell: info => {
          const isFirstRow = info.row.index === 0;
          const rowType = info.getValue().type;
          if (isFirstRow && rowType !== TradingDocumentSummaryType.TOTAL)
            return (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                w tym:
              </Typography>
            );
          if (rowType === TradingDocumentSummaryType.TOTAL)
            return (
              <Typography
                className="text-right w-100"
                color="neutralBlack48"
                fontSize="12"
                fontWeight="400"
              >
                suma:
              </Typography>
            );
          return null;
        },
      }),
      columnHelper.text(row => (row.vatRate ? `${row.vatRate}%` : " "), {
        header: "VAT",
        size: 65,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "14",
          fontWeight: "600",
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalWithoutTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wartość netto
          </Typography>
        ),
        size: 120,
        cell: info => {
          const summary = info.getValue();
          if (summary.type === TradingDocumentSummaryType.VAT_RATE)
            return (
              <AmountDisplay
                amount={summary.totalWithoutTax ? String(summary.totalWithoutTax) : "0.00"}
                className={styles.amountDisplay}
                currency={draftInvoice.currency as CURRENCY_TYPE}
              />
            );
          return (
            <AmountDisplay
              amount={summary.totalWithoutTax ? String(summary.totalWithoutTax) : "0.00"}
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
              integer={{ fontSize: "16", fontWeight: "600" }}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            kwota VAT
          </Typography>
        ),
        size: 120,
        cell: info => {
          const summary = info.getValue();
          if (summary.type === TradingDocumentSummaryType.VAT_RATE)
            return (
              <AmountDisplay
                amount={summary.totalTax ? String(summary.totalTax) : "0.00"}
                className={styles.amountDisplay}
                currency={draftInvoice.currency as CURRENCY_TYPE}
              />
            );
          return (
            <AmountDisplay
              amount={summary.totalTax ? String(summary.totalTax) : "0.00"}
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
              integer={{ fontSize: "16", fontWeight: "600" }}
            />
          );
        },
      }),
      columnHelper.accessor(row => row, {
        id: "totalWithTax",
        header: () => (
          <Typography
            className="text-right w-100"
            color="neutralBlack48"
            fontSize="12"
            fontWeight="400"
          >
            wartość brutto
          </Typography>
        ),
        size: 120,
        cell: info => {
          const summary = info.getValue();
          if (summary.type === TradingDocumentSummaryType.VAT_RATE)
            return (
              <AmountDisplay
                amount={summary.totalWithTax ? String(summary.totalWithTax) : "0.00"}
                className={styles.amountDisplay}
                currency={draftInvoice.currency as CURRENCY_TYPE}
              />
            );
          return (
            <AmountDisplay
              amount={summary.totalWithTax ? String(summary.totalWithTax) : "0.00"}
              className={styles.amountDisplay}
              currency={draftInvoice.currency as CURRENCY_TYPE}
              integer={{ fontSize: "16", fontWeight: "700" }}
            />
          );
        },
      }),
    ];
  });
};
