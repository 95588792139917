import proformaIcon from "assets/images/proforma.svg";
import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { Proformas } from "routes/Proformas";
import { TabSubSection } from "components/common/moduleNavigation/components/tabSubSection/TabSubSection";
import { tradingDocumentsTabDict } from "pages/tradingDocuments/shared/utils/getTabs";

export const proformas: ModuleLink = {
  url: "proformas",
  label: "Proforma",
  icon: proformaIcon,
  subSection: <TabSubSection baseUrl="/finances/proformas/list" tabs={tradingDocumentsTabDict} />,
  routing: Proformas,
};
