import { manufacturingActions } from "api/manufacturing/actions";
import { ManufacturingMaterialKind } from "api/manufacturing/materials/models";
import { Recipe } from "api/manufacturing/models";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiClose } from "components/miloDesignSystem/atoms/icons/MdiClose";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useRecipesColumns = () => {
  const deleteMutation = manufacturingActions.useDeleteRecipe();
  return useCreateTableColumns<Recipe>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.material.name, {
          header: "nazwa",
          size: 300,
          typographyProps: {
            fontSize: "14",
          },
        }),
        columnHelper.text(row => row.material.category?.name, {
          header: "kategoria materiału",
          size: 150,
          typographyProps: {
            fontSize: "14",
            fontWeight: "400",
          },
        }),
        columnHelper.accessor(row => row.material.kind, {
          header: "rodzaj",
          size: 78,
          cell: info => {
            const kind = info.getValue();
            if (kind === ManufacturingMaterialKind.RAW) {
              return <Tag label="surowiec" variant="info" />;
            }
            return <Tag label="półprodukt" variant="deepPurple400" />;
          },
        }),
        columnHelper.text(row => row.quantity, {
          header: "liczba/ilość",
          size: 70,
          editable: {
            mutationHook: manufacturingActions.usePatchRecipe,
            transformQueryData: (value, row) => ({
              id: row.original.id,
              quantity: value,
            }),
          },
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "700",
          },
        }),
        columnHelper.text(row => row.material.unit, {
          header: "jedn.",
          size: 50,
          typographyProps: {
            color: "neutralBlack48",
            fontSize: "14",
            fontWeight: "400",
          },
        }),
        columnHelper.text(row => row.material.externalId, {
          header: "nr referencyjny",
          size: 100,
          typographyProps: {
            fontSize: "12",
            fontWeight: "400",
          },
        }),
        columnHelper.accessor(row => row.id, {
          id: "remove",
          header: " ",
          size: 32,
          cell: info => {
            const id = info.getValue();
            return (
              <IconButton
                icon={MdiClose}
                variant="transparent"
                onClick={() => {
                  deleteMutation.mutate(id);
                }}
              />
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
