import { manufacturingEmployeesActions } from "api/manufacturing/employees/actions";
import {
  ManufacturingEmployeeDetails,
  MethodOfSettlement,
} from "api/manufacturing/employees/models";
import { InfoLabel } from "components/common/infoLabel";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { AmountDisplay } from "components/miloDesignSystem/molecules/amountDisplay";
import { Select } from "components/miloDesignSystem/molecules/select";
import { RightPanelSection } from "components/utils/drawer";
import { manufacturingEmployeesConstants } from "constants/manufacturingEmployees";
import { dictToList, getStandardDateFormat } from "utilities";
import { manufacturingEmployeesUtils } from "utilities/manufacturingEmployees";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  employee: ManufacturingEmployeeDetails;
}

export const GeneralInfoSection = ({ employee }: Props) => {
  const methodsOfSettlementOptions = dictToList(
    manufacturingEmployeesConstants.methodOfSettlementDict,
  ).map(({ key, value }) => ({
    text: value,
    type: MenuItemType.TEXT,
    value: key,
  }));

  return (
    <RightPanelSection padding="px-3 pt-0">
      <InfoLabel title="aktualne saldo">
        <div className="d-flex align-items-center gap-2">
          {employee.wallet ? (
            <AmountDisplay
              amount={
                manufacturingEmployeesUtils.transfromCentsToPln(employee.wallet.balance) || "0.00"
              }
              currency={employee.wallet.currency}
            />
          ) : (
            <EmptyValue fontSize="14" fontWeight="700" />
          )}
          <Button
            className="text-uppercase"
            disabled
            size="small"
            startIcon={MdiCheck}
            variant="gray"
          >
            Rozlicz
          </Button>
        </div>
      </InfoLabel>
      <InfoLabel title="liczba nierozliczonych">
        <Typography fontSize="14" fontWeight="700">
          {employee.wallet?.unconfirmedTransactions}
        </Typography>
      </InfoLabel>
      <InfoLabel title="sposób naliczania płatności">
        <Select.Async
          items={methodsOfSettlementOptions}
          mutationHook={manufacturingEmployeesActions.usePatchManufacturingEmployee}
          transformQueryData={methodOfSettlement => ({
            id: Number(employee.id),
            data: {
              methodOfSettlement: methodOfSettlement as MethodOfSettlement,
            },
          })}
          selected={employee.methodOfSettlement}
        />
      </InfoLabel>
      <InfoLabel title="email">
        <Typography fontSize="14" fontWeight="700" noWrap>
          {employee.email || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="telefon">
        <Typography fontSize="14" fontWeight="700">
          {employee.phone || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="data zatrudnienia">
        <Typography fontSize="14" fontWeight="500">
          {employee.dateOfEmployment
            ? getStandardDateFormat(new Date(employee.dateOfEmployment))
            : EMPTY_VALUE}
        </Typography>
      </InfoLabel>
    </RightPanelSection>
  );
};
