import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { TabSection } from "../SettingsTabSection";
import styles from "./PaymentTab.module.css";
import { Developer } from "api/other/models";
import { developerActions } from "api/developer/actions";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { getIsoDateFormat } from "utilities";

interface Props {
  settings: Developer["settings"][number];
}

export const PaymentTab = ({ settings }: Props) => {
  const turnOffMiloDateMutation = developerActions.usePatchDeveloperSettings();

  return (
    <div className={styles.tabBody}>
      <TabSection>
        <div className={styles.unpaidInvoiceSection}>
          <Checkbox.Async
            checked={settings.isInfoAboutTurnOffMiloVisible}
            helperText="W przypadku gdy ma nieopłacone faktury"
            label="Wyświetl informację dla klienta o wyłączeniu usługi"
            mutationHook={developerActions.usePatchDeveloperSettings}
            transformQueryData={isInfoAboutTurnOffMiloVisible => ({
              id: settings.id,
              isInfoAboutTurnOffMiloVisible,
            })}
            size="sm"
          />
          <div className={styles.turnOffMiloDateContent}>
            <Typography color="neutralBlack88" fontSize="10" fontWeight="600">
              Wskaż datę wyłączenia usługi
            </Typography>
            <DatePicker
              disabled={turnOffMiloDateMutation.isLoading}
              look="common"
              overwrites={{
                popup: { className: styles.datePickerPopup },
                input: { className: styles.datePickerInput },
                container: { className: styles.datePickerInput },
              }}
              value={settings.turnOffMiloDate || ""}
              onChange={date => {
                if (!date) {
                  return turnOffMiloDateMutation.mutate({
                    id: settings.id,
                    turnOffMiloDate: null,
                  });
                }
                return turnOffMiloDateMutation.mutate({
                  id: settings.id,
                  turnOffMiloDate: getIsoDateFormat(date),
                });
              }}
            />
          </div>
        </div>
      </TabSection>
    </div>
  );
};
