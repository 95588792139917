import { filterFactory } from "components/utils/withFilters";

export const longHeldPackagesFilters = filterFactory(({ FilterType }) => [
  {
    type: FilterType.Search,
    label: "kontrahent",
    name: "supplierId",
    searchBy: "customers",
  },
  {
    type: FilterType.Number,
    label: "l. przeterminowanych dni",
    placeholder: "0",
    name: "days",
  },
]);
