import { ModuleLink } from "components/common/moduleNavigation/moduleTypes/moduleTypes";
import { MdiMaterials } from "components/miloDesignSystem/atoms/icons/MdiMaterials";
import { MaterialsRouting } from "routes/manufacturingNew/MaterialsRouting";

export const materials: ModuleLink = {
  url: "materials",
  label: "Materiały - zapotrzebowanie i stan",
  icon: MdiMaterials,
  routing: MaterialsRouting,
};
