import { BulkSalesInvoiceConfirmPreview, TradingDocument } from "api/trading-documents/models";
import { useMutation, useMutationOptions } from "hooks/useMutation";
import {
  patchPurchaseInvoiceStatus,
  patchTradingDocumentStatus,
} from "api/trading-documents/calls";
import { tradingDocumentsKeys } from "api/trading-documents/keys";
import { queryString } from "utilities";
import { Pagination } from "api/types";
import immer from "immer";
import { assertIsDefined } from "utilities/assertIsDefined";
import { useQuery, useSelector, useStateModal } from "hooks";
import { ReplyModal } from "../actionToolbar/ReplyModal";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import { proformasApi } from "api/trading-documents/proforma/api";
import { advanceApi } from "api/trading-documents/advance/api";
import { tradingDocumentsActions } from "api/trading-documents/actions";

interface Props {
  tradingDocument: TradingDocument;
}

export const ConfirmTradingDocument = ({ tradingDocument }: Props) => {
  const { query } = useQuery();
  const { panelId, ...filters } = query;
  const replyModal = useStateModal<BulkSalesInvoiceConfirmPreview>();
  const me = useSelector(store => store.auth.user!);
  const removeInvoiceConfirmationMutation = tradingDocumentsActions.useRemoveInvoiceConfirmationPatch();

  const updateStatusOptions = useMutationOptions(
    () => {
      const data = { tradingDocument: tradingDocument.id };
      if (tradingDocument.invoiceType === "ADVANCE") return advanceApi.patchProformaStatus(data);
      if (tradingDocument.invoiceType === "PROFORMA") return proformasApi.patchProformaStatus(data);
      if (tradingDocument.invoiceType === "PURCHASE") return patchPurchaseInvoiceStatus(data);
      return patchTradingDocumentStatus(data);
    },
    ({ queryClient, toastr, queryUtils }) => ({
      onMutate: () => {
        const prevList = queryClient.getQueryData<Pagination<TradingDocument>>(
          tradingDocumentsKeys.tradingDocument.list(
            queryString.stringify({
              ...filters,
              type: tradingDocument.type,
              invoiceType: tradingDocument.invoiceType,
            }),
          ),
        );
        const prevPanel = queryClient.getQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );

        if (prevList)
          queryClient.setQueryData<Pagination<TradingDocument>>(
            tradingDocumentsKeys.tradingDocument.list(
              queryString.stringify({
                ...filters,
                type: tradingDocument.type,
                invoiceType: tradingDocument.invoiceType,
              }),
            ),
            currentList => {
              assertIsDefined(currentList);
              return immer(currentList, draft => {
                const document = draft.results.find(result => result.id === tradingDocument.id);
                if (document) Object.assign(document, { ...document, status: "CONFIRMED" });
              });
            },
          );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              status: "CONFIRMED",
            };
          },
        );

        return { prevList, prevPanel };
      },
      onSuccess: (payload, _, context) => {
        const { prevList } = context as {
          prevList: Pagination<TradingDocument>;
        };
        if (prevList)
          queryClient.setQueryData<Pagination<TradingDocument>>(
            tradingDocumentsKeys.tradingDocument.list(
              queryString.stringify({
                ...filters,
                type: tradingDocument.type,
                invoiceType: tradingDocument.invoiceType,
              }),
            ),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: currentList.results.map(result => {
                  if (result.id === tradingDocument.id) {
                    return { ...result, status: payload.status, signature: payload.signature };
                  }
                  return result;
                }),
              };
            },
          );

        queryClient.setQueryData<TradingDocument>(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          currentDocument => {
            assertIsDefined(currentDocument);
            return {
              ...currentDocument,
              status: payload.status,
              signature: payload.signature,
            };
          },
        );

        queryClient.invalidateQueries(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
        );
        queryClient.invalidateQueries(tradingDocumentsKeys.tradingDocument.list());
        if (tradingDocument.invoiceType === "PURCHASE") {
          toastr.open({
            type: "success",
            title: "Udało się!",
            text: "Zatwierdzono dokument",
          });
        } else {
          if (payload.message.valid.objects.length === 0) {
            replyModal.open(payload.message);
          }
        }
      },
      onError: (error, _, context) => {
        const { prevList, prevPanel } = context as {
          prevList: Pagination<TradingDocument>;
          prevPanel: TradingDocument;
        };
        queryUtils.rollback(
          tradingDocumentsKeys.tradingDocument.details(tradingDocument.id),
          prevPanel,
          error,
        );

        if (prevList)
          queryClient.setQueryData<Pagination<TradingDocument>>(
            tradingDocumentsKeys.tradingDocument.list(
              queryString.stringify({
                ...filters,
                type: tradingDocument.type,
                invoiceType: tradingDocument.invoiceType,
              }),
            ),
            currentList => {
              assertIsDefined(currentList);
              return {
                ...currentList,
                results: prevList.results,
              };
            },
          );
      },
    }),
  );

  const updateStatusMutation = useMutation(updateStatusOptions.mutationFn, updateStatusOptions);

  return (
    <>
      <div>
        <Button
          className="text-uppercase"
          disabled={
            (tradingDocument.status === "CONFIRMED" && me.type !== "developer") ||
            tradingDocument.type === "RECEIPT"
          }
          isLoading={updateStatusMutation.isLoading || removeInvoiceConfirmationMutation.isLoading}
          onClick={() => {
            if (tradingDocument.status !== "CONFIRMED") {
              updateStatusMutation.mutate({});
            }
            if (tradingDocument.status === "CONFIRMED" && me.type === "developer") {
              removeInvoiceConfirmationMutation.mutate({
                tradingDocuments: [tradingDocument.id],
              });
            }
          }}
          size="small"
          startIcon={MdiCheck}
          variant={tradingDocument.status !== "CONFIRMED" ? "outline" : "success"}
        >
          {tradingDocument.status !== "CONFIRMED" ? "Zatwierdź fakturę" : "Zatwierdzono fakturę"}
        </Button>
      </div>
      {replyModal.isOpen && (
        <ReplyModal bulkInvoiceConfirmation={replyModal.state} close={replyModal.close} />
      )}
    </>
  );
};
