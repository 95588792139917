import { UnloadingLineItem, UnloadingLineItemType, UnloadingStatus } from "api/wms/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { IconButton } from "components/miloDesignSystem/atoms/iconButton";
import { MdiQrCode } from "components/miloDesignSystem/atoms/icons/MdiQrCode";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiCheck } from "components/miloDesignSystem/atoms/icons/MdiCheck";
import styles from "../../RightPanel.module.css";
import { cx, dateFns, queryString } from "utilities";
import { ListAvatar } from "pages/wms/shared/components/ListAvatar";
import { MdiCancel } from "components/miloDesignSystem/atoms/icons/MdiCancel";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { useCancellUnloadingItem } from "api/wms/hooks";
import { MdiChair } from "components/miloDesignSystem/atoms/icons/MdiChair";
import { MdiShoppingCart } from "components/miloDesignSystem/atoms/icons/MdiShoppingCart";
import { MdiPackage2 } from "components/miloDesignSystem/atoms/icons/MdiPackage2";
import { UserWithInitials } from "api/users/models";
import { ISODateTime } from "api/types";
import { wmsUnloadingActions } from "api/wms/unloading/actions";
import { wmsBasketsActions } from "api/wms/baskets/actions";
import { mainListBigUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { LoaderCell } from "components/miloDesignSystem/molecules/table/components/tableLoader/TableLoader";

export const useLineItemsColumns = (orders: UnloadingLineItem[]) => {
  const handleDownloadUnloadingLineItemPdf = wmsUnloadingActions.useDownloadUnloadingLineItemPdf();
  const cancelItemMutation = useCancellUnloadingItem();

  const { data: packagesInBasket, error, isLoading } = wmsBasketsActions.useGetPackageInBasket(
    queryString.stringify({
      pageSize: 999,
      uniquePackages: orders?.flatMap(order => order?.uniqueCodes).join(","),
    }),
    {
      enabled: Boolean(orders.length),
    },
  );

  return useCreateTableColumns<UnloadingLineItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.accessor(row => row, {
          header: "zamów./mebel",
          size: 105,
          cell: info => {
            const lineItem: UnloadingLineItem = info.getValue();
            return (
              <Typography
                className={cx({
                  [styles.cancelledSignature]: lineItem.isCancelled,
                })}
                fontSize="12"
                fontWeight="700"
                noWrap
              >
                {lineItem.signature}
              </Typography>
            );
          },
        }),
        columnHelper.text(row => row.sourceExternalId, {
          header: "nr zewn.",
          size: 65,
        }),
        columnHelper.accessor(row => row.type, {
          header: "typ",
          size: 18,
          cell: info => {
            const type: UnloadingLineItemType = info.getValue();
            if (type === UnloadingLineItemType.INDEX) return <MdiChair color="cyan300" size="18" />;
            if (type === UnloadingLineItemType.ORDER)
              return <MdiShoppingCart color="magenta300" size="18" />;
            return <MdiPackage2 color="purple300" size="18" />;
          },
        }),
        columnHelper.accessor(row => row.status, {
          header: "",
          id: "status",
          size: 50,
          cell: info => {
            const status = info.getValue();
            if (status === UnloadingStatus.FINISHED) {
              return <Tag startIcon={MdiCheck} label="OK" variant="success" />;
            }
            if (status === UnloadingStatus.IN_PROGRESS) {
              return <Tag label="trwa" variant="warning" />;
            }
            return null;
          },
        }),
        columnHelper.accessor(row => row.suppliers, {
          header: "kontrahent",
          size: 65,
          cell: info => {
            const suppliers = info.getValue();
            if (Boolean(suppliers.length)) {
              return (
                <div className="d-flex align-items-center gap-1 noWrap">
                  <Typography fontSize="12" fontWeight="700" noWrap>
                    {suppliers[0].name}
                  </Typography>
                  {suppliers.length - 1 > 0 && (
                    <Typography color="deepPurple400" fontSize="12" fontWeight="600">
                      +{suppliers.length - 1}
                    </Typography>
                  )}
                </div>
              );
            }
            return <EmptyValue />;
          },
        }),
        columnHelper.accessor(row => row.numberOfPackageGroups, {
          header: "towar",
          size: 40,
          cell: info => {
            const numberOfPackageGroups: UnloadingLineItem["numberOfPackageGroups"] = info.getValue();
            return (
              <div className="d-flex align-items-center">
                <Typography color="success500" fontSize="14" fontWeight="700">
                  {numberOfPackageGroups.inStock}
                </Typography>
                <Typography color="neutralBlack48" fontSize="14" fontWeight="700">
                  /{numberOfPackageGroups.total}
                </Typography>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row.inStockAt, {
          header: "rozładowano",
          size: 80,
          cell: info => {
            const date: ISODateTime | null = info.getValue();
            if (!date) return <EmptyValue />;
            return (
              <Typography fontSize="12" fontWeight="600">
                {dateFns.formatRelative(new Date(date))}
              </Typography>
            );
          },
        }),
        columnHelper.text(
          row => {
            if (Boolean(row.locations)) return row.locations.join(", ");
            return null;
          },
          {
            header: "lokalizacja",
            size: 65,
          },
        ),
        columnHelper.accessor(row => row, {
          header: "kosze",
          size: 75,
          cell: info => {
            const order = info.getValue();
            const baskets = packagesInBasket
              ?.filter(packageInBasket =>
                order.uniqueCodes.includes(packageInBasket.uniquePackageCode),
              )
              .map(packageInBasket => packageInBasket.basket.code);

            if (error || isLoading)
              return <LoaderCell height={mainListBigUiSchema.cell.height} width={75} />;

            if (!Boolean(baskets.length)) return <EmptyValue fontSize="12" />;

            return (
              <Typography fontSize="12" fontWeight="700" noWrap>
                {Array.from(new Set(baskets)).join(", ")}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row.collaborators, {
          header: "kto?",
          size: 35,
          cell: info => {
            const collaborators: UserWithInitials[] = info.getValue();
            return <ListAvatar collaborators={collaborators} />;
          },
        }),
        columnHelper.accessor(row => row, {
          header: "",
          id: "labels",
          size: 56,
          cell: info => {
            const unloadingLineItem = info.getValue() as UnloadingLineItem;
            return (
              <div className="d-flex align-items-center gap-1">
                <IconButton
                  icon={<MdiQrCode size="16" />}
                  onClick={event => {
                    event.stopPropagation();
                    handleDownloadUnloadingLineItemPdf(
                      unloadingLineItem.id,
                      unloadingLineItem.unloading,
                      unloadingLineItem.signature,
                    );
                  }}
                  variant="transparent"
                />
                {unloadingLineItem.isCancelled ? (
                  <IconButton
                    icon={<MdiRestartAlt size="16" />}
                    onClick={event => event.stopPropagation()}
                    variant="transparent"
                  />
                ) : (
                  <IconButton
                    disabled={cancelItemMutation.isLoading}
                    icon={<MdiCancel size="16" />}
                    onClick={event => {
                      event.stopPropagation();
                      cancelItemMutation.mutate({
                        ids: [unloadingLineItem.id],
                        kind: "UNLOADING_LINE_ITEMS",
                        unloadingId: unloadingLineItem.unloading,
                        isCancelled: true,
                        status: UnloadingStatus.CANCELLED,
                      });
                    }}
                    variant="transparent"
                  />
                )}
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: row => `${row.position}.` },
  );
};
