import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { wmsLayoutApi } from "./api";
import { createApiQuery } from "hooks/createApiQuery";
import { useMutation } from "hooks/useMutation";
import { UUID } from "api/types";
import { AssignField } from "./models";
import { assertIsDefined } from "utilities/assertIsDefined";
import { wmsLayoutKeys } from "./keys";
import { useErrorToastr } from "hooks/useErrorToastr";
import { useDownloadFeedbackToastr } from "components/utils/downloadFeedback/DownloadFeedbackController";
import { fileDownloader } from "fileDownloader";
import { wmsFileFactory } from "../calls";

const useRouteLayoutCollection = createApiQuery(wmsLayoutApi.getRouteLayoutCollection);
const useRouteSearchCollection = createApiQuery(wmsLayoutApi.getRouteSearchCollection);
const useRampStatus = createApiQuery(wmsLayoutApi.getRampStatus);
const useFillingStatus = createApiQuery(wmsLayoutApi.getFillingStatus);
const useLayoutSchema = createApiQuery(wmsLayoutApi.getLayoutSchema);
const useLayoutField = createApiQuery(wmsLayoutApi.getLayoutField);
const usePackagesInLocation = createPaginatedApiQuery(wmsLayoutApi.getPackagesInLocation);

const useFieldPatch = (hallId: number) => {
  return useMutation(
    ({ id, toUpdate }: { id: UUID; toUpdate: Partial<AssignField> }) => {
      return wmsLayoutApi.patchField(toUpdate, id);
    },
    ({ queryUtils, queryClient }) => ({
      onMutate: ({ id, toUpdate }) => {
        const prevPanel = queryUtils.handleMutate(
          wmsLayoutKeys.getLayoutField(hallId, id),
          toUpdate,
        );
        return { prevPanel };
      },
      onSuccess: () => queryClient.invalidateQueries(),
      onError: (error, { id }, onMutateReturn) => {
        assertIsDefined(onMutateReturn);
        queryUtils.rollback(
          wmsLayoutKeys.getLayoutField(hallId, id),
          onMutateReturn.prevPanel,
          error,
        );
      },
    }),
  );
};

const useDownloadWarehouseSchemaRackPdf = () => {
  const downloadFeedbackToastr = useDownloadFeedbackToastr();
  const handleErrorMessage = useErrorToastr();

  return async (id: string, rackName: string) => {
    const tstr = downloadFeedbackToastr.open({ type: "pdf" });
    const { url, name } = wmsFileFactory.rackPdf({
      wmsId: id,
      name: rackName,
    });
    const response = await fileDownloader({
      onProgress: tstr.updateProgress,
      url,
      name,
      type: "pdf",
    });
    if (response.status === "success") {
      tstr.lazyClose();
    } else {
      tstr.close();
      handleErrorMessage(response);
    }
  };
};

export const wmsLayoutActions = {
  useRouteLayoutCollection,
  useRampStatus,
  useFillingStatus,
  useRouteSearchCollection,
  useLayoutSchema,
  useLayoutField,
  usePackagesInLocation,
  useFieldPatch,
  useDownloadWarehouseSchemaRackPdf,
};
