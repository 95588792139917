import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { materialsApi } from "./api";
import { useMutation } from "hooks/useMutation";
import { getAnyErrorKey } from "utilities";
import {
  ManualMaterialStockUpdatePayload,
  Material,
  MaterialStockUpdateDirection,
  PostManufacturingMaterial,
} from "./models";
import { FormikHelpers } from "formik";
import { createApiQuery } from "hooks/createApiQuery";
import { assertIsDefined } from "utilities/assertIsDefined";
import { materialsKeys } from "./keys";
import { useQuery } from "hooks";
import { withDeleteConfirmation } from "hooks/withMutationConfirmation";
import { useDrawer } from "hooks/useDrawer";

const useMaterials = createPaginatedApiQuery(materialsApi.getMaterials);
const useMaterial = createApiQuery(materialsApi.getMaterial);
const useStageMaterialsDemands = createApiQuery(materialsApi.getStageMaterialsDemand);

const usePostManufacturingMaterial = (close: () => void) => {
  const postMutation = useMutation(materialsApi.postMaterial, ({ queryClient, toastr }) => ({
    onSuccess: payload => {
      queryClient.invalidateQueries();
      close();
      toastr.open({
        type: "success",
        title: "Udało się!",
        text: `Dodano materiał ${payload.name}`,
      });
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (
    values: PostManufacturingMaterial,
    actions: FormikHelpers<PostManufacturingMaterial>,
  ) => {
    postMutation.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const usePatchMaterialStockManually = (close: () => void, material: Material) => {
  const patchMutation = useMutation(
    materialsApi.patchMaterialStockManually,
    ({ queryClient, toastr }) => ({
      onSuccess: (_, args) => {
        queryClient.invalidateQueries();
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `${
            args.data.direction === MaterialStockUpdateDirection.IN ? "Przyjęto" : "Wydano"
          } ${args.data.quantity} ${material.unit.shortName} materiału "${material.name}"`,
        });
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: ManualMaterialStockUpdatePayload,
    actions: FormikHelpers<ManualMaterialStockUpdatePayload>,
  ) => {
    patchMutation.mutate(
      { data: values, materialId: material.id },
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};

const usePatchMaterialManufacturer = () =>
  useMutation(materialsApi.patchMaterialManufacturer, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const usePatchMaterial = () => {
  const { query } = useQuery();
  return useMutation(materialsApi.patchMaterial, ({ queryClient, queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevPanel = query.panelId
        ? queryUtils.handleMutate(materialsKeys.materials.details(id), toUpdate)
        : undefined;
      const prevList = queryUtils.handlePaginatedListUpdate(
        materialsKeys.materials.list(),
        id,
        toUpdate,
      );
      return { prevList, prevPanel };
    },
    onSuccess: () => queryClient.invalidateQueries(materialsKeys.materials.list()),
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      if (onMutateReturn.prevPanel) {
        queryUtils.rollback(materialsKeys.materials.details(id), onMutateReturn.prevPanel, error);
      }
      queryUtils.rollbackList(materialsKeys.materials.list(), onMutateReturn.prevList, id);
    },
  }));
};

const usePostMaterialManufacturers = (close: () => void) =>
  useMutation(materialsApi.postMaterialManufacturer, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

const useRemoveMaterial = () => {
  const { close } = useDrawer("materialsDemandAndAvailability");

  return withDeleteConfirmation(
    useMutation(materialsApi.deleteMaterial, ({ queryClient, toastr }) => ({
      onSuccess: () => {
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Usunięto materiał`,
        });
        queryClient.invalidateQueries();
        close();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    })),
    "Czy na pewno chcesz usunąć ten materiał?",
  )();
};

export const materialsActions = {
  useMaterials,
  useMaterial,
  usePatchMaterialStockManually,
  usePostManufacturingMaterial,
  usePatchMaterial,
  usePatchMaterialManufacturer,
  usePostMaterialManufacturers,
  useRemoveMaterial,
  useStageMaterialsDemands,
};
