import { ManufacturingItemStatus, OrderDeliveryPriority } from "api/orders/models";
import { TagProps } from "components/miloDesignSystem/atoms/tag";
import priorityNormal from "assets/images/priority_normal.svg";
import priorityHigh from "assets/images/priority_high.svg";
import priorityCritical from "assets/images/priority_critical.svg";
import { dictToList } from "./utilities";

const singleItemQuantitiesManufacturingStatusOptions: Record<
  ManufacturingItemStatus,
  TagProps<string>
> = {
  DONE: { label: "wyprodukowano", type: "outlined", variant: "success" },
  IN_PROGRESS: { label: "w trakcie", type: "outlined", variant: "warning" },
  PENDING: { label: "nie zlecono", type: "outlined", variant: "quaternary" },
};

const orderDeliveryPrioritiesDict: Record<
  OrderDeliveryPriority,
  { label: string; icon: string }
> = {
  CRITICAL: { label: "Krytyczne", icon: priorityCritical },
  HIGH: { label: "Pilne", icon: priorityHigh },
  NORMAL: { label: "Normalne", icon: priorityNormal },
};

const orderDeliveryPrioritiesItems: { id: OrderDeliveryPriority; name: string }[] = dictToList(
  orderDeliveryPrioritiesDict,
).map(({ key, value }) => ({ id: key, name: value.label }));

export const ordersUtils = {
  orderDeliveryPrioritiesDict,
  orderDeliveryPrioritiesItems,
  singleItemQuantitiesManufacturingStatusOptions,
};
