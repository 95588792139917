import { FormInput, FormSelect, RadioGroup } from "components/utils";
import { Formik, FormikHelpers } from "formik";
import { dateFns, getAnyErrorKey } from "utilities";
import cx from "classnames";
import { useToastr } from "components/common";
import { useMutation } from "hooks/useMutation";
import { postEmployee } from "api/manufacturingNew/calls";
import { EmployedPlace, PostEmployee } from "api/manufacturingNew/models";
import { useQueryClient } from "react-query";
import { CustomModal } from "components/utils/customModal";
import { employeeKindConfigDict } from "CONSTANTS";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import styles from "../EmployeesList.module.css";
import { validationSchema } from "../utils/validationSchema";
import { Button } from "components/miloDesignSystem/atoms/button";
import { useRedux } from "hooks";
import { manufacturingEmployeesKeys } from "api/manufacturing/employees/keys";

interface Props {
  close: () => void;
}

const kindObjects = Object.entries(employeeKindConfigDict).map(kind => {
  return {
    id: kind[0],
    name: kind[1].name.toLowerCase(),
  };
});

const initialValues: PostEmployee = {
  firstName: "",
  lastName: "",
  email: "",
  phone: "",
  kind: "",
  employedPlace: EmployedPlace.INTERNAL,
  date: "",
  company: "",
  number: "",
};

const employmentOptions = [
  { name: "wewnętrznie", id: "INTERNAL" },
  { name: "u kontrahenta", id: "EXTERNAL" },
];

export const CreateManufacturingEmployeeModal = ({ close }: Props) => {
  const toastr = useToastr();
  const queryClient = useQueryClient();
  const [dispatch, { partials }] = useRedux();
  const refetchPartials = () => dispatch(partials.fetchPartials());

  const createManufacturingSchemaMutation = useMutation(
    ({ values }: { values: PostEmployee; actions: FormikHelpers<PostEmployee> }) =>
      postEmployee(values),
    {
      onSuccess: payload => {
        close();
        toastr.open({
          type: "success",
          title: "Udało się!",
          text: `Utworzono nowego pracownika: ${payload.firstName} ${payload.lastName}`,
        });
        refetchPartials();
        queryClient.invalidateQueries(manufacturingEmployeesKeys.list());
      },
      onError: (error, { actions }) => {
        actions.setErrors(error.response?.data);
        actions.setSubmitting(false);
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  return (
    <CustomModal
      close={close}
      isOpen={true}
      overrides={{ container: { className: "p-3" } }}
      size={{ all: { width: "480px" } }}
      title="Dodaj pracownika"
    >
      <>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            createManufacturingSchemaMutation.mutate({ values, actions });
          }}
        >
          {({ handleSubmit, setFieldValue, values, isSubmitting, isValid }) => (
            <form className={cx({ "was-validated": !isValid })} onSubmit={handleSubmit}>
              <div className="d-flex flex-column p-3 gap-4">
                <FormInput className="mb-0" label="Imię" placeholder="Imię" name="firstName" />

                <FormInput
                  className="mb-0"
                  label="Nazwisko"
                  placeholder="Nazwisko"
                  name="lastName"
                />

                <FormInput
                  className="mb-0"
                  type="email"
                  label="Email"
                  placeholder="Email"
                  name="email"
                />

                <FormInput
                  className="mb-0"
                  label="Nr telefonu"
                  type="tel"
                  placeholder="Nr telefonu"
                  name="phone"
                />

                <FormInput
                  className="mb-0"
                  label="Nr pracownika"
                  placeholder="Nr pracownika"
                  name="number"
                />

                <FormSelect
                  name="kind"
                  itemToSelection={item => (item ? item.id : null)}
                  width="full"
                  placeholder="Typ pracownika"
                  items={kindObjects}
                />

                <div>
                  <Typography fontSize="10" fontWeight="700">
                    GDZIE ZATRUDNIONY(/-NA)
                  </Typography>
                  <RadioGroup
                    onChange={selected => {
                      if (selected) setFieldValue("employedPlace", selected.id);
                    }}
                    value={values.employedPlace ?? ""}
                    items={employmentOptions}
                    name="employedPlace"
                  />
                </div>
                {values.employedPlace === "EXTERNAL" && (
                  <FormInput
                    className="mb-0"
                    label="Firma"
                    placeholder="Nazwa firmy"
                    name="company"
                  />
                )}
                <div className="w-25">
                  <div className="position-relative mr-1">
                    <Typography fontSize="12" fontWeight="700" className={styles.label}>
                      Data zatrudnienia
                    </Typography>
                    <DatePicker
                      className={styles.date}
                      overwrites={{
                        container: { className: cx(styles.container, "inputOnLightBg") },
                      }}
                      removeDate={false}
                      value={values.date ?? null}
                      onChange={selectedDate => {
                        if (selectedDate)
                          setFieldValue(
                            "date",
                            dateFns.format(new Date(selectedDate), "yyyy-MM-dd"),
                          );
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex align-items-center mt-4 gap-2 p-3 borderTop ">
                <Button size="medium" className="fs-12 uppercase" variant="gray" onClick={close}>
                  Anuluj
                </Button>

                <Button
                  size="medium"
                  className="fs-12 uppercase"
                  disabled={isSubmitting}
                  variant="deepPurple"
                  type="submit"
                >
                  Dodaj pracownika
                </Button>
              </div>
            </form>
          )}
        </Formik>
      </>
    </CustomModal>
  );
};
