import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import { RightPanelSection } from "components/utils/drawer";
import cx from "classnames";
import styles from "../RightPanel.module.css";
import React from "react";
import { AsyncInput } from "components/utils";
import { tradingDocumentConstants } from "constants/tradingDocuments";
import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { Typography } from "components/miloDesignSystem/atoms/typography";

interface Props {
  correctionInvoice: TradingDocument;
}

export const MasterDocumentSection = ({ correctionInvoice }: Props) => {
  const correctionReasonMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection>
      <InfoLabel title="do dokumentu">
        {correctionInvoice.masterDocument && (
          <Typography fontSize="16" fontWeight="800">
            {correctionInvoice.masterDocument.signature || "---"}
          </Typography>
        )}
      </InfoLabel>
      <InfoLabel title="z datą wystawienia">
        <Typography fontSize="16" fontWeight="700">
          {correctionInvoice.masterDocument?.invoiceIssueDate || "---"}
        </Typography>
      </InfoLabel>
      <InfoLabel title="z datą dostawy">
        <Typography fontSize="16" fontWeight="700">
          {correctionInvoice.masterDocument?.invoiceDeliveryDate || "---"}
        </Typography>
      </InfoLabel>
      <InfoLabel title="przyczyna">
        <div className={cx("body-14 fw-800")}>
          {correctionInvoice.reasons.length > 0
            ? correctionInvoice.reasons.map((reason, index) => (
                <React.Fragment key={index}>
                  {correctionInvoice.reasons.length > index + 1 ? (
                    <>
                      {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                      ,&nbsp;
                    </>
                  ) : (
                    <>
                      {tradingDocumentConstants.correctionReasonsMap[reason.field][reason.action]}
                    </>
                  )}
                </React.Fragment>
              ))
            : "--"}
        </div>
      </InfoLabel>
      <InfoLabel title="powód korekty">
        <AsyncInput
          disabled={correctionReasonMutation.isLoading}
          inProgress={correctionReasonMutation.isLoading}
          onChange={correctionReason =>
            correctionReasonMutation.mutate({
              id: correctionInvoice.id,
              correctionReason,
            })
          }
          overwrites={{ container: { className: styles.correctionReason } }}
          placeholder="powód korekty"
          value={correctionInvoice.correctionReason}
        />
      </InfoLabel>
      {/* TODO: Sign document section */}
    </RightPanelSection>
  );
};
