import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useQuery } from "hooks";
import { cx } from "utilities";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useTableMultiSelect } from "components/miloDesignSystem/molecules/table/hooks/useTableMultiSelect/useTableMultiSelect";
import { Tab, getTabs } from "./utils/getTabs";
import { manufacturerOrders } from "components/common/moduleNavigation/moduleConfig/manufacturing/routes/manufacturerOrders";
import { manufacturingActions } from "api/manufacturing/actions";
import { getSearch } from "./utils/getSearch";
import { RouteComponentProps } from "react-router";
import { CommissionListItem } from "api/manufacturing/models";
import { useManufacturerOrderColumns } from "./useServiceProviderOrdersColumns";

export const ServiceProviderOrderList = ({ match }: RouteComponentProps<{ tab: Tab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = getSearch({ query, tab });
  const {
    data,
    error,
    isLoading,
    isPreviousData,
    pagination,
  } = manufacturingActions.useServiceProviderCommissions(search);

  const tableMultiSelect = useTableMultiSelect({ rows: data });
  const tableProps = useTableFeatureConnector({
    rows: data,
    withDrawer: "serviceProviderOrders",
    withPagination: { pagination },
    withMultiSelect: tableMultiSelect,
  });

  const columns = useManufacturerOrderColumns();

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: getTabs(),
          routesRoot: `manufacturing/${manufacturerOrders.url}`,
          urlSpan: "list",
        }}
        viewLabel="SERVICE_PROVIDER_ORDERS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<CommissionListItem>
          columns={columns}
          error={error}
          isLoading={isLoading || isPreviousData}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => {
            return {
              ...tableProps?.overrides?.(),
              defaultPaginationVisibility: true,
              row: row => {
                return {
                  className: cx(
                    {
                      dashedTableRow: row.isCancelled,
                    },
                    tableProps?.overrides?.().row?.(row).className,
                  ),
                };
              },
            };
          }}
        />
      </div>
    </PageWrapper>
  );
};
