import { ApiFetcher } from "hooks/createApiQuery";
import { routesKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import { ManufacturingRoute, ManufacturingRouteListItem, RouteSynchronize } from "./models";
import { Pagination } from "api/types";

const getRouteSynchronizeDetails = (id: number): ApiFetcher<RouteSynchronize[]> => ({
  key: routesKeys.routeSynchronize(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/synchronize/" + id,
    }),
  globalOptions: {
    enabled: Boolean(id),
  },
});

const postRouteSynchronize = (id: number) =>
  queryFetch<void>({
    method: "POST",
    url: "/routes/synchronize/" + id,
  });

const postStatusInExternalService = (data: { route: number }) =>
  queryFetch<void>({
    method: "POST",
    url: "/routes/set-status-in-external-service",
    data,
  });

const getManufacturingRoutes = (
  search: string,
): ApiFetcher<Pagination<ManufacturingRouteListItem>> => ({
  key: routesKeys.manufacturingRoutes(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/routes/manufacturing${search}`,
    }),
});

const getManufacturingRoute = (id: number): ApiFetcher<ManufacturingRoute> => ({
  key: routesKeys.manufacturingRoute(String(id)),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/routes/manufacturing/" + id,
    }),
});

const getManufacturingRoutePositions = (
  id: number,
): ApiFetcher<{
  next: number | null;
  previous: number | null;
}> => ({
  key: routesKeys.manufacturingRoutePositions(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/routes/manufacturing/get_route_neighbor_ids?route_id=${id}`,
    }),
});

export const routeApi = {
  getRouteSynchronizeDetails,
  postRouteSynchronize,
  postStatusInExternalService,
  getManufacturingRoutes,
  getManufacturingRoutePositions,
  getManufacturingRoute,
};
