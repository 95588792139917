import { Pagination, UUID } from "api/types";
import { ApiFetcher } from "hooks/createApiQuery";
import { materialsKeys } from "./keys";
import { queryFetch } from "apiConnectors/queryFetch";
import {
  ManualMaterialStockUpdatePayload,
  Material,
  MaterialCategory,
  MaterialListItem,
  MaterialUnit,
  PostManufacturingMaterial,
  PostMaterialManufacturer,
  StageMaterialDemands,
} from "./models";
import { Assign } from "utility-types";
import { parsePatchData } from "utilities/parsePatchData";

const getMaterials = (search: string): ApiFetcher<Pagination<MaterialListItem>> => ({
  key: materialsKeys.materials.list(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/materials/items" + search,
    }),
});

const getMaterial = (id: Material["id"]): ApiFetcher<Material> => ({
  key: materialsKeys.materials.details(id),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/materials/items/" + id,
    }),
});

const getStageMaterialsDemand = (search: string = ""): ApiFetcher<StageMaterialDemands[]> => ({
  key: materialsKeys.materials.demands(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/materials/demands" + search,
    }),
});

const patchMaterial = (data: Assign<Partial<Material>, { id: UUID }>) =>
  queryFetch<Material>({
    method: "PATCH",
    url: "/manufacturing/materials/items/" + data.id,
    data: parsePatchData(data),
  });

const deleteMaterial = (materialId: UUID) =>
  queryFetch<void>({
    method: "DELETE",
    url: "/manufacturing/materials/items/" + materialId,
  });

const patchMaterialManufacturer = (
  data: Assign<Partial<Material["providers"][number]>, { id: UUID }>,
) =>
  queryFetch<Material["providers"][number]>({
    method: "PATCH",
    url: "/manufacturing/materials/manufacturers/" + data.id,
    data: parsePatchData(data),
  });

const getMaterialUnits = (search: string): ApiFetcher<Pagination<MaterialUnit>> => ({
  key: materialsKeys.materialUnits(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/materials/units" + search,
    }),
});

const getMaterialCategories = (search: string): ApiFetcher<Pagination<MaterialCategory>> => ({
  key: materialsKeys.materialCategories(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/manufacturing/materials/categories" + search,
    }),
});

const postMaterial = (data: PostManufacturingMaterial) =>
  queryFetch<MaterialListItem>({
    method: "POST",
    url: "/manufacturing/materials/items",
    data,
  });

const postMaterialManufacturer = (data: PostMaterialManufacturer) =>
  queryFetch<void>({
    method: "POST",
    url: "/manufacturing/materials/manufacturers/bulk-create",
    data: parsePatchData(data),
  });

const patchMaterialStockManually = ({
  data,
  materialId,
}: {
  data: ManualMaterialStockUpdatePayload;
  materialId: UUID;
}) =>
  queryFetch<void>({
    method: "PATCH",
    url: `/manufacturing/materials/update-stock/${materialId}`,
    data,
  });

export const materialsApi = {
  deleteMaterial,
  getMaterials,
  getMaterialCategories,
  getMaterialUnits,
  getStageMaterialsDemand,
  patchMaterialStockManually,
  postMaterial,
  getMaterial,
  postMaterialManufacturer,
  patchMaterialManufacturer,
  patchMaterial,
};
