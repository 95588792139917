import { ApiFetcher } from "hooks/createApiQuery";
import { queryFetch } from "apiConnectors/queryFetch";
import { Customer, GusDetails } from "./models";
import { Pagination } from "api/types";
import { PartialOf } from "typeUtilities";
import { parsePatchData } from "utilities/parsePatchData";
import { customerKeys } from "./keys";

const getCustomers = (search: string = ""): ApiFetcher<Pagination<Customer>> => ({
  key: customerKeys.customers(search),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/customers/items" + search,
    }),
});

const getCustomer = (customerId: number): ApiFetcher<Customer> => ({
  key: customerKeys.customerDetails(customerId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: `/customers/items/${customerId}`,
    }),
});

const getCustomerGusDetails = (taxId: string): ApiFetcher<GusDetails> => ({
  key: customerKeys.customerGusDetails(taxId),
  fetcher: () =>
    queryFetch({
      method: "GET",
      url: "/customers/gus-customer-details/" + taxId,
    }),
});

const patchCustomer = ({ id, toUpdate }: { toUpdate: PartialOf<Customer>; id: Customer["id"] }) =>
  queryFetch<Customer>({
    method: "PATCH",
    url: "/customers/items/" + id,
    data: parsePatchData(toUpdate),
  });

export const customerApi = {
  getCustomerGusDetails,
  getCustomers,
  getCustomer,
  patchCustomer,
};
