import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { PanelHeader } from "./components/PanelHeader";
import { manufacturersActions } from "api/manufacturers/actions";
import { LatestOrdersSection } from "./latestOrdersSection/LatestOrdersSection";
import { EmailSection } from "./emailSection/EmailSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";

export const RightPanel = () => {
  const { query } = useQuery();
  const { data: manufacturer, error, isLoading } = manufacturersActions.useManufacturer(
    Number(query.panelId),
    {
      enabled: Boolean(query.panelId),
    },
  );

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!manufacturer) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={manufacturer.id}>
        <PanelHeader />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody>
            <GeneralInfoSection />
            <LatestOrdersSection />
            <EmailSection />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
