import { PageHeader } from "components/common";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { useQuery, useToggle } from "hooks";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { queryString } from "utilities";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useRampsColumns } from "./useRampsColumns";
import { AddRampModal } from "./addRampModal/AddRampModal";
import { RightPanel } from "./rightPanel/RightPanel";
import { wmsRampActions } from "api/wms/ramps/actions";
import { RampListItem } from "api/wms/ramps/models";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";

export const RampsList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const { data: ramps, error, isPreviousData, isLoading, pagination } = wmsRampActions.useGetRamps(
    search,
  );
  const columns = useRampsColumns();
  const addRampModal = useToggle();
  const tableProps = useTableFeatureConnector({
    rows: ramps,
    withDrawer: "wmsRamps",
    withPagination: { pagination },
  });

  return (
    <>
      <PageWrapper>
        <PageHeader
          searchInput={{
            label: "Szukaj wśród ramp",
            tags: [],
          }}
          createButton={{
            alt: "utwórz",
            disabled: false,
            img: darkPlusIcon,
            label: "Utwórz rampę",
            onClick: addRampModal.open,
          }}
          viewLabel="WMS_RAMPS"
        />
        <div className="d-flex flex-1 overflow-hidden justify-content-between">
          <Table<RampListItem>
            columns={columns}
            isLoading={isLoading || isPreviousData}
            error={error}
            uiSchema={mainListUiSchema}
            {...tableProps}
            overrides={() => ({
              ...tableProps?.overrides?.(),
              defaultPaginationVisibility: true,
            })}
          />
          <RightPanel />
        </div>
      </PageWrapper>
      {addRampModal.isOpen && <AddRampModal close={addRampModal.close} />}
    </>
  );
};
