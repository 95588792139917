import { useQuery } from "hooks";
import { RouteComponentProps } from "react-router";
import { getSearch } from "./utils/getSearch";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { PageHeader } from "components/common";
import { Table } from "components/miloDesignSystem/molecules/table";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { useProductListColumns } from "./useProductListColumns";
import { products } from "components/common/moduleNavigation/moduleConfig/wms/routes/products";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { orderActions } from "api/orders/actions";
import { FreeSingleItemQuantitiesListItem } from "api/orders/models";
import { RightPanel } from "./rightPanel/RightPanel";

export enum ProductTab {
  "all" = "all",
  "with-order" = "with-order",
  "without-order" = "without-order",
}

const tabs: Record<ProductTab, string> = {
  all: "Wszystkie",
  "with-order": "W zamówieniach",
  "without-order": "Bez zamówień",
};

export const ProductList = ({ match }: RouteComponentProps<{ tab: ProductTab }>) => {
  const { tab } = match.params;
  const { query } = useQuery({ exclude: ["panelId"] });

  const search = getSearch({ query, tab });
  const {
    data: rows,
    error,
    isPreviousData,
    isLoading,
    pagination,
  } = orderActions.useGetOrdersFreeSingleItemQuantities(search);
  const columns = useProductListColumns();

  const tableProps = useTableFeatureConnector({
    rows: rows,
    withPagination: { pagination },
    withDrawer: "freeSingleItemsQuantitiesDetails",
  });

  return (
    <PageWrapper>
      <PageHeader
        searchInput={{
          label: "Szukaj...",
          tags: [],
        }}
        tabs={{
          list: Object.entries(tabs).map(([name, label]) => ({ label, name })),
          routesRoot: `wms/${products.url}`,
          urlSpan: "list",
        }}
        viewLabel="WMS_PRODUCT_LIST"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<FreeSingleItemQuantitiesListItem>
          columns={columns}
          isLoading={isLoading || isPreviousData}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => ({
            ...tableProps?.overrides?.(),
            defaultPaginationVisibility: true,
          })}
        />
        <RightPanel />
      </div>
    </PageWrapper>
  );
};
