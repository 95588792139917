import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx, getDateFromIsoDateAndIsoTime } from "utilities";
import styles from "./SearchEntityDetails.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { wmsLayoutActions } from "api/wms/layout/actions";
import { useQuery, useThresholdToggle } from "hooks";
import { CommonError } from "components/utils";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { calculateTimeDifference } from "pages/wms/unloadingsList/utils/calculateTimeDifference";

export const SearchEntityDetails = () => {
  const { query } = useQuery();
  const routeId = query["searchCollectionId"];
  const { isOpen: isNavbarOpen } = useThresholdToggle();

  const { data, isLoading, error } = wmsLayoutActions.useRouteSearchCollection(Number(routeId), {
    enabled: Boolean(routeId),
  });

  if (error) {
    return (
      <div className={cx("p-3", styles.wrapper)}>
        <CommonError status={error._httpStatus_} />
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={cx("p-3 d-flex align-items-center justify-content-center", styles.wrapper)}>
        <Spinner size={30} />
      </div>
    );
  }

  if (!data) return null;

  return (
    <div
      className={cx("p-3", styles.wrapper, {
        [styles.wrapperForOpenedNavbar]: isNavbarOpen,
        [styles.wrapperForClosedNavbar]: !isNavbarOpen,
      })}
    >
      <div className="d-flex align-items-center gap-2">
        {data.car && (
          <Tag
            label={data.car.registrationNumber}
            variant="quaternary"
            theme="dark"
            type="outlined"
          />
        )}
        <Typography fontSize="16" fontWeight="700" color="neutralWhite100">
          {data.signature}
        </Typography>
      </div>
      <div className="d-flex align-items-center gap-2 pt-3">
        <Typography fontSize="12" fontWeight="700" color="grey500">
          wyjazd:
        </Typography>
        {data.departureDate && data.departureTime ? (
          <>
            <Typography fontSize="12" fontWeight="700" color="neutralWhite100">
              {data.departureTime ? data.departureTime.slice(0, 5) : "---"}
            </Typography>
            <Tag
              theme="dark"
              label={
                calculateTimeDifference(
                  getDateFromIsoDateAndIsoTime(data.departureDate, data.departureTime),
                ).label
              }
              variant={
                calculateTimeDifference(
                  getDateFromIsoDateAndIsoTime(data.departureDate, data.departureTime),
                ).variant
              }
            />
            min)
          </>
        ) : (
          <EmptyValue color="neutralWhite100" />
        )}
      </div>
    </div>
  );
};
