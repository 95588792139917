import { Typography } from "components/miloDesignSystem/atoms/typography";
import { cx } from "utilities";
import styles from "./Header.module.css";
import { Search } from "components/miloDesignSystem/molecules/search";
import { wmsLayoutApi } from "api/wms/layout/api";
import { useQuery, useSelector } from "hooks";
import { Select } from "components/miloDesignSystem/molecules/select";
import { MenuItemType } from "components/miloDesignSystem/atoms/menu/types";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox/Checkbox";

interface Props {
  showFill: boolean;
  setShowFill: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Header = ({ showFill, setShowFill }: Props) => {
  const halls = useSelector(store => store.partials.halls);
  const { setQuery, query, updateQuery } = useQuery();

  return (
    <div
      className={cx(
        "d-flex align-items-center justify-content-between borderBottomB px-3 pt-2",
        styles.header,
      )}
    >
      <div className="d-flex align-items-center">
        <Typography fontSize="20" fontWeight="700" color="neutralWhite100" className="pr-3">
          Magazyn
        </Typography>
        <Search
          textFieldProps={{ containerClassName: styles.input }}
          isNullable
          fetcherFn={wmsLayoutApi.getSearchCollections}
          onChange={searchCollection => {
            setQuery({ ...query, searchCollectionId: searchCollection?.id || "" });
            setShowFill(false);
          }}
          theme="dark"
        />
      </div>
      <div className="d-flex align-items-center gap-4">
        <Select
          items={halls.map(hall => ({
            text: hall.name,
            type: MenuItemType.TEXT,
            value: hall.id,
          }))}
          onChange={hall => {
            if (hall) {
              updateQuery({ hallId: Number(hall) });
            }
          }}
          selected={query["hallId"] || null}
          theme="dark"
        />
        <Checkbox
          checked={showFill}
          label="Pokaż zapełnienie magazynu"
          onChange={() => setShowFill(prev => !prev)}
          size="sm"
          theme="dark"
        />
      </div>
    </div>
  );
};
