import { useQuery, useToggle } from "hooks";
import { RightPanel } from "./rightPanel/RightPanel";
import { useManufacturingSchemas } from "api/manufacturingNew/hooks";
import { Table } from "components/miloDesignSystem/molecules/table";
import { ManufacturingSchema } from "api/manufacturingNew/models";
import { PageWrapper } from "components/common/pageWrapper/PageWrapper";
import { mainListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import darkPlusIcon from "assets/images/darkPlus.svg";
import { PageHeader } from "components/common";
import { AddManufacturingSchema } from "./addManufacturingSchema/AddManufacturingSchema";
import { queryString } from "utilities";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { useManufacturingSchemasColumns } from "./useManufacturingSchemasListColumns";

export const ManufacturingSchemasList = () => {
  const { query } = useQuery({ exclude: ["panelId"] });
  const search = queryString.stringify(query);
  const { data: manufacturingSchemas, error, isLoading, pagination } = useManufacturingSchemas(
    search,
  );
  const addManufacturingSchemaModal = useToggle();
  const columns = useManufacturingSchemasColumns();
  const tableProps = useTableFeatureConnector({
    rows: manufacturingSchemas,
    withDrawer: "manufacturingSchemasList",
    withPagination: { pagination },
  });

  return (
    <PageWrapper>
      <PageHeader
        createButton={{
          alt: "Plus",
          img: darkPlusIcon,
          label: "Utwórz schemat produkcyjny",
          onClick: addManufacturingSchemaModal.open,
        }}
        searchInput={{
          label: "Szukaj wśród schematów produkcyjnych",
          tags: [],
        }}
        viewLabel="MANUFACTURING_SCHEMAS"
      />
      <div className="d-flex flex-1 overflow-hidden justify-content-between">
        <Table<ManufacturingSchema>
          columns={columns}
          isLoading={isLoading}
          error={error}
          uiSchema={mainListUiSchema}
          {...tableProps}
          overrides={() => ({
            ...tableProps?.overrides?.(),
            defaultPaginationVisibility: true,
          })}
        />
        <RightPanel />
      </div>
      {addManufacturingSchemaModal.isOpen && (
        <AddManufacturingSchema close={addManufacturingSchemaModal.close} />
      )}
    </PageWrapper>
  );
};
