import { Typography } from "components/miloDesignSystem/atoms/typography";
import styles from "./Field.module.css";
import { FieldKind, LayoutSchema } from "api/wms/layout/models";
import { Tooltip } from "components/miloDesignSystem/atoms/tooltip";
import { Ramp } from "./components/Ramp";

export const Field = ({
  field,
  area,
  viewScale,
  color,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color?: string;
}) => {
  if (field.kind === FieldKind.RAMP)
    return <Ramp color={color} area={area} field={field} viewScale={viewScale} />;
  return <BaseField color={color} area={area} field={field} viewScale={viewScale} />;
};

const BaseField = ({
  field,
  area,
  viewScale,
  color,
}: {
  field: LayoutSchema["areas"][number]["fields"][number];
  area: LayoutSchema["areas"][number];
  viewScale: number;
  color?: string;
}) => {
  return (
    <div
      key={`${field.kind}-${field.id}`}
      className={styles.field}
      style={{
        top: field.coords.y - area.coords.y + viewScale,
        left: field.coords.x - area.coords.x + viewScale,
        width: field.width + viewScale,
        height: field.height + viewScale,
        backgroundColor: color,
      }}
    >
      {field.verboseName.length > 3 ? (
        <Tooltip title={field.verboseName} variant="DARK">
          <div className="d-flex align-items-center justify-content-center flex-1 h-100">
            <Typography fontSize="64" fontWeight="400" color="neutralWhite100" noWrap>
              {field.verboseName}
            </Typography>
          </div>
        </Tooltip>
      ) : (
        <div className="d-flex align-items-center justify-content-center flex-1 h-100">
          <Typography fontSize="64" fontWeight="400" color="neutralWhite100" noWrap>
            {field.verboseName}
          </Typography>
        </div>
      )}
    </div>
  );
};
