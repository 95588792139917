import { usePatchTradingDocumentMutation } from "api/trading-documents/hooks";
import { TradingDocument } from "api/trading-documents/models";
import { FinanceAmountFormat } from "components/common/financeAmountFormat";
import { InfoLabel } from "components/common/infoLabel";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { DatePicker } from "components/utils/datePicker";
import { RightPanelSection } from "components/utils/drawer";
import { getIsoDateFormat, handleDateField } from "utilities";
import styles from "../RightPanel.module.css";
import {
  EMPTY_VALUE,
  EmptyValue,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { countryToFlagDict } from "constants/countriesFlags";

interface Props {
  tradingDocument: TradingDocument;
}

export const AmountSection = ({ tradingDocument }: Props) => {
  const pitTaxObligationDateMutation = usePatchTradingDocumentMutation();
  const updateVatObligationDateMutation = usePatchTradingDocumentMutation();

  return (
    <RightPanelSection>
      <InfoLabel title="kraj">
        {Boolean(tradingDocument.countryCode) ? (
          <img alt="Flaga kraju" src={countryToFlagDict[tradingDocument.countryCode]} />
        ) : (
          <EmptyValue fontSize="14" fontWeight="700" />
        )}
      </InfoLabel>
      <InfoLabel title="kwota">
        <div className="d-flex align-items-center">
          {Boolean(Object.keys(tradingDocument.amountSummary).length) ? (
            <FinanceAmountFormat
              decimalClassName="fw-700 fs-16"
              integerClassName="fw-700 fs-16"
              value={tradingDocument.amountSummary.totalWithTax}
            />
          ) : (
            <EmptyValue className="mr-2" fontSize="16" fontWeight="700" />
          )}
          <Typography
            className={styles.adjustedLineHeight}
            color="neutralBlack48"
            fontSize="16"
            fontWeight="700"
          >
            {tradingDocument.currency}
          </Typography>
        </div>
      </InfoLabel>
      <InfoLabel title="kwota VAT">
        {Boolean(Object.keys(tradingDocument.amountSummary).length) ? (
          <FinanceAmountFormat
            decimalClassName="body-14-600"
            integerClassName="body-14-600"
            value={tradingDocument.amountSummary.totalTax}
          />
        ) : (
          <EmptyValue className="mr-1" fontSize="14" />
        )}
        <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
          {tradingDocument.currency}
        </Typography>
      </InfoLabel>
      <InfoLabel title="waluta">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.currency || EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="kurs wymiany">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.lastValidExchangeRate ?? EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="data wymiany">
        <Typography fontSize="14" fontWeight="500">
          {tradingDocument.lastValidExchangeRateDate ?? EMPTY_VALUE}
        </Typography>
      </InfoLabel>
      <InfoLabel title="rejestr VAT">
        <DatePicker
          showMonthYearPicker
          look="common"
          onChange={date => {
            if (!date) {
              return updateVatObligationDateMutation.mutate({
                id: tradingDocument.id,
                vatTaxObligationDate: null,
              });
            }
            return updateVatObligationDateMutation.mutate({
              id: tradingDocument.id,
              vatTaxObligationDate: getIsoDateFormat(date),
            });
          }}
          customDateFormat="MM/yyyy"
          value={tradingDocument.vatTaxObligationDate ?? ""}
        />
      </InfoLabel>
      <InfoLabel title="obowiązek podatkowy">
        {tradingDocument.invoiceType !== "SALES" ? (
          <Typography fontSize="14" fontWeight="500">
            {handleDateField(tradingDocument.pitTaxObligationDate)}
          </Typography>
        ) : (
          <DatePicker
            disabled={pitTaxObligationDateMutation.isLoading}
            look="common"
            onChange={date => {
              if (!date) {
                return pitTaxObligationDateMutation.mutate({
                  id: tradingDocument.id,
                  pitTaxObligationDate: null,
                });
              }
              return pitTaxObligationDateMutation.mutate({
                id: tradingDocument.id,
                pitTaxObligationDate: getIsoDateFormat(date),
              });
            }}
            overwrites={{
              popup: { className: styles.datePickerPopup },
              input: { className: styles.datePickerInput },
              container: { className: styles.datePickerInput },
            }}
            value={tradingDocument.pitTaxObligationDate ?? ""}
          />
        )}
      </InfoLabel>
      <InfoLabel
        title={`${tradingDocument.sellerBankName ? tradingDocument.sellerBankName : "bank"} [${
          tradingDocument.currency ? tradingDocument.currency : EMPTY_VALUE
        }]`}
      >
        {tradingDocument.sellerBankAccountNumber ? (
          <div>****&nbsp;{tradingDocument.sellerBankAccountNumber.slice(-4)}</div>
        ) : (
          <EmptyValue fontSize="14" fontWeight="500" />
        )}
      </InfoLabel>
    </RightPanelSection>
  );
};
