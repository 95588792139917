import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styled from "@emotion/styled";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import styles from "./RouteProgress.module.css";
import { cx, dateFns } from "utilities";
import { useManufacturingStages } from "api/manufacturingNew/hooks";
import { ManufacturingStage } from "api/manufacturing/schemas/models";
import { MdiCheckCircle } from "components/miloDesignSystem/atoms/icons/MdiCheckCircle";
import { MdiSchedule } from "components/miloDesignSystem/atoms/icons/MdiSchedule";
import { MdiSettings } from "components/miloDesignSystem/atoms/icons/MdiSettings";
import { ColumnDef } from "@tanstack/react-table";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { ReactNode } from "react";
import { ItemProgress, ItemProgressStatus, ManufacturingItemProgress } from "api/orders/models";
import { Button } from "components/miloDesignSystem/atoms/button";
import { productionOrdersActions } from "api/production-orders/hooks";

export const useRouteProgressColumns = () => {
  const { data } = useManufacturingStages("");
  return useCreateTableColumns<ManufacturingItemProgress>(({ columnHelper }) => {
    const columns: ColumnDef<ManufacturingItemProgress>[] = data.map((stage, index) =>
      columnHelper.accessor(row => row, {
        header: stage.name,
        size: 141,
        cell: info => {
          const row = info.getValue();
          if (index === 0) {
            const width =
              info.table.getTotalSize() -
              clientColumnWidth -
              orderSignatureColumnWidth -
              signatureColumnWidth -
              sendForProductionStatusColumnWidth -
              productionStatusColumnWidth;

            return (
              <div className="d-flex flex-column flex-1 position-relative">
                <div className={styles.productName} style={{ width: `${width}px` }}>
                  <Typography fontSize="14" fontWeight="700">
                    {row.index.product.name}
                    <Typography
                      fontSize="14"
                      fontWeight="600"
                      color="neutralBlack64"
                      className="ml-1"
                    >
                      {row.index.attributes.map(
                        attribute => `${attribute.attribute}: ${attribute.value}`,
                      )}
                    </Typography>
                  </Typography>
                </div>

                <Stage
                  stage={stage}
                  progress={row?.manufacturingItem ? row?.manufacturingItem?.[stage.id] : null}
                />
              </div>
            );
          }

          return (
            <Stage
              stage={stage}
              progress={row?.manufacturingItem ? row?.manufacturingItem?.[stage.id] : null}
            />
          );
        },
      }),
    );

    return [
      columnHelper.text(row => row.order?.signature, {
        header: "zamówienie",
        typographyProps: { fontWeight: "700", fontSize: "14" },
        size: orderSignatureColumnWidth,
      }),
      columnHelper.text(row => row.signature, {
        header: "nr zlecenia",
        typographyProps: { fontWeight: "700", fontSize: "14" },
        size: signatureColumnWidth,
      }),
      columnHelper.accessor(row => row, {
        header: "status zlecenia",
        size: sendForProductionStatusColumnWidth,
        cell: info => {
          const row = info.getValue();
          if (Boolean(row.manufacturingItem)) {
            return <Tag label="zlecono" variant="info" type="outlined" />;
          }

          return <CreateProductionOrder row={row} />;
        },
      }),
      columnHelper.accessor(row => row, {
        header: "status produkcji",
        size: productionStatusColumnWidth,
        cell: info => {
          const row = info.getValue();
          const progressList = Object.values(row.manufacturingItem || {});
          if (progressList.every(({ status }) => status === ItemProgressStatus.NOT_STARTED))
            return itemStatusToTagDict[ItemProgressStatus.NOT_STARTED];

          if (progressList.every(({ status }) => status === ItemProgressStatus.FINISHED))
            return itemStatusToTagDict[ItemProgressStatus.FINISHED];

          return itemStatusToTagDict[ItemProgressStatus.IN_PROGRESS];
        },
      }),
      columnHelper.text(
        row =>
          row.order?.customer
            ? row.order?.customer?.fullName
            : `${row.order?.delivery.firstName} ${row.order?.delivery.lastName}`,
        {
          header: "klient",
          typographyProps: { fontWeight: "400", fontSize: "14" },
          size: clientColumnWidth,
        },
      ),
      ...columns,
    ];
  });
};

const Stage = ({
  progress,
  stage,
}: {
  progress: ItemProgress | null;
  stage: ManufacturingStage | null;
}) => {
  if (!progress) {
    return (
      <NoStage>
        <Typography fontSize="12" fontWeight="600" color="neutralBlack32">
          nie dotyczy
        </Typography>
      </NoStage>
    );
  }

  if (progress.status === ItemProgressStatus.IN_PROGRESS) {
    return (
      <StyledStage>
        <StageInProgressBackground backgroundColor={stage?.backgroundColor || ""} />
        <div className={styles.loader}></div>

        <WorkerInitials progress={progress} />
        <div className="mr-1">
          <MdiSettings color="deepPurple400" size="14" />
        </div>
      </StyledStage>
    );
  }

  if (progress.status === ItemProgressStatus.NOT_STARTED) {
    return (
      <StageNotStarted>
        <WorkerInitials progress={progress} />

        <div className="mr-1">
          <MdiSchedule color="neutralBlack32" size="14" />
        </div>
      </StageNotStarted>
    );
  }

  return (
    <StageFinished backgroundColor={stage?.backgroundColor || ""}>
      <div className="d-flex align-items-center">
        <WorkerInitials progress={progress} />
        <Typography fontSize="10" fontWeight="400">
          {progress.finishedAt && dateFns.format(new Date(progress.finishedAt), "dd.MM HH:mm")}
        </Typography>
      </div>
      <div
        className={cx("d-flex align-items-center justify-content-center", styles.progressContainer)}
      >
        <MdiCheckCircle color="success300" size="14" />
      </div>
    </StageFinished>
  );
};

const WorkerInitials = ({ progress }: { progress: ItemProgress }) => {
  return (
    <Typography fontSize="12" fontWeight="700" className="mr-1">
      {progress.employee?.firstName[0]}
      {progress.employee?.lastName[0]}
    </Typography>
  );
};

const CreateProductionOrder = ({ row }: { row: ManufacturingItemProgress }) => {
  const createManufacturerOrdersMutation = productionOrdersActions.useCreateProductionOrders();
  return (
    <div className="d-flex gap-1 align-items-center">
      <Tag label="nie zlecono" variant="quaternary" type="outlined" />
      <Button
        size="small"
        variant="gray"
        isLoading={createManufacturerOrdersMutation.isLoading}
        onClick={() => {
          createManufacturerOrdersMutation.mutate({
            items: [
              {
                id: row.item.id,
                indexId: row.index.id,
                isSelfProduced: true,
                manufacturer: null,
                number: 1,
                order: row.order!.id,
              },
            ],
          });
        }}
      >
        Zleć produkcję
      </Button>
    </div>
  );
};

const StyledStage = styled.div`
  position: relative;
  display: flex;
  margin-top: 32px;
  flex: 1;
  width: 141px;
  min-width: 141px;
  max-width: 141px;
  align-items: center;
  justify-content: space-between;
  height: 26px;
  min-height: 26px;
  max-height: 26px;
  padding-left: 8px;
`;

const StageNotStarted = styled(StyledStage)`
  border: 1px solid ${colorPalette.neutralBlack24};
`;

const StageInProgressBackground = styled.div<{
  backgroundColor: string;
}>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: ${props => props.backgroundColor};
  opacity: 0.3;
`;

const StageFinished = styled(StyledStage)<{
  backgroundColor: string;
}>`
  background-color: ${props => props.backgroundColor};
`;

const NoStage = styled(StyledStage)`
  opacity: 0.5;
  border: 1px solid ${colorPalette.neutralBlack7};
`;

const itemStatusToTagDict: Record<ItemProgressStatus, ReactNode> = {
  [ItemProgressStatus.FINISHED]: <Tag label="Wyprodukowano" variant="success" />,
  [ItemProgressStatus.IN_PROGRESS]: <Tag label="W trakcie" variant="warning" />,
  [ItemProgressStatus.NOT_STARTED]: (
    <Tag label="Nie rozpoczęto" variant="quaternary" type="outlined" />
  ),
};

const orderSignatureColumnWidth = 130;
const signatureColumnWidth = 128;
const productionStatusColumnWidth = 110;
const sendForProductionStatusColumnWidth = 165;
const clientColumnWidth = 150;
