import { manufacturingActions } from "api/manufacturing/actions";
import { StageBoardAttributeKind } from "api/manufacturing/models";
import { manufacturingSchemaActions } from "api/manufacturing/schemas/actions";
import { BoardFormat, ManufacturingStage } from "api/manufacturing/schemas/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { MdiArrowRange } from "components/miloDesignSystem/atoms/icons/MdiArrowRange";
import { MdiFabric } from "components/miloDesignSystem/atoms/icons/MdiFabric";
import { MdiKingBed } from "components/miloDesignSystem/atoms/icons/MdiKingBed";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { CommonError } from "components/utils";
import { RightPanelSection } from "components/utils/drawer";
import { cx, queryString } from "utilities";
import { assertIsDefined } from "utilities/assertIsDefined";
import { manufacturingStagesUtils } from "utilities/manufacturingStages";

interface Props {
  manufacturingStage: ManufacturingStage;
}

export const StageBoardSettings = ({ manufacturingStage }: Props) => {
  const search = queryString.stringify({
    schemaStage: manufacturingStage.id,
  });
  const {
    data: stageBoardDefaultAttributesKind,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingActions.useStageBoardDefaultAttributesKind(search);
  const boardFormatMutation = manufacturingSchemaActions.usePatchManufacturingStage();
  const addDefaultFabricKind = manufacturingActions.usePostStageBoardDefaultAttributesKind();
  const addDefaultSizeKind = manufacturingActions.usePostStageBoardDefaultAttributesKind();
  const deleteDefaultFabricKind = manufacturingActions.useDeleteStageBoardAttributesKind();
  const deleteDefaultSizeKind = manufacturingActions.useDeleteStageBoardAttributesKind();

  const isGrouped = manufacturingStage.boardFormat === BoardFormat.GROUP;

  return (
    <RightPanelSection title="Sposób wyświetlania zleceń na widoku etapu produkcyjnego">
      <div className="pt-1 pb-3">
        <Checkbox
          checked={manufacturingStage.boardFormat === BoardFormat.GROUP}
          label="W tym etapie zlecenia są grupowane"
          size="sm"
          onChange={format =>
            boardFormatMutation.mutate({
              id: manufacturingStage.id,
              boardFormat: format ? BoardFormat.GROUP : BoardFormat.SINGLE,
            })
          }
        />
      </div>
      <div className="d-flex flex-column gap-2">
        <Typography color="neutralBlack24" fontSize="12" fontWeight="600" noWrap>
          Atrybuty, po których zlecenia są grupowane to:
        </Typography>
        {isLoading && !isPreviousData && <Spinner size={26} />}
        {!isLoading && error && <CommonError status={error._httpStatus_} />}
        {!isLoading && stageBoardDefaultAttributesKind && (
          <div className="d-flex align-items-center gap-1">
            <div className="cursor-not-allowed">
              <Tag
                label="Model"
                startIcon={<MdiKingBed size="14" />}
                type={manufacturingStage?.boardFormat === BoardFormat.GROUP ? "filled" : "outlined"}
                variant={
                  manufacturingStage?.boardFormat === BoardFormat.GROUP
                    ? "deepPurple50"
                    : "quaternary"
                }
              />
            </div>
            <div
              className={cx("cursor-pointer", {
                "cursor-not-allowed": !isGrouped,
              })}
              onClick={() => {
                if (
                  !addDefaultSizeKind.isLoading &&
                  isGrouped &&
                  !manufacturingStagesUtils.hasSize(stageBoardDefaultAttributesKind)
                )
                  addDefaultSizeKind.mutate({
                    attributeKind: StageBoardAttributeKind.SIZE,
                    schemaStage: manufacturingStage.id,
                  });
                else if (
                  !deleteDefaultSizeKind.isLoading &&
                  isGrouped &&
                  manufacturingStagesUtils.hasSize(stageBoardDefaultAttributesKind)
                ) {
                  const searchedSizeFilter = stageBoardDefaultAttributesKind.find(
                    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.SIZE,
                  );
                  assertIsDefined(searchedSizeFilter);
                  deleteDefaultSizeKind.mutate(searchedSizeFilter.id);
                }
              }}
            >
              <Tag
                disabled={!isGrouped}
                endIcon={
                  addDefaultSizeKind.isLoading || deleteDefaultSizeKind.isLoading ? (
                    <Spinner size={16} />
                  ) : (
                    undefined
                  )
                }
                label="Rozmiar"
                startIcon={<MdiArrowRange size="14" />}
                type={
                  manufacturingStagesUtils.hasSize(stageBoardDefaultAttributesKind)
                    ? "filled"
                    : "outlined"
                }
                variant={
                  manufacturingStagesUtils.hasSize(stageBoardDefaultAttributesKind)
                    ? "deepPurple400"
                    : "quaternary"
                }
              />
            </div>
            <div
              className={cx("cursor-pointer", {
                "cursor-not-allowed": !isGrouped,
              })}
              onClick={() => {
                if (
                  !addDefaultFabricKind.isLoading &&
                  isGrouped &&
                  !manufacturingStagesUtils.hasFabric(stageBoardDefaultAttributesKind)
                )
                  addDefaultFabricKind.mutate({
                    attributeKind: StageBoardAttributeKind.FABRIC,
                    schemaStage: manufacturingStage.id,
                  });
                else if (
                  !deleteDefaultFabricKind.isLoading &&
                  isGrouped &&
                  manufacturingStagesUtils.hasFabric(stageBoardDefaultAttributesKind)
                ) {
                  const searchedFabricFilter = stageBoardDefaultAttributesKind.find(
                    defaultFilter => defaultFilter.attributeKind === StageBoardAttributeKind.FABRIC,
                  );
                  assertIsDefined(searchedFabricFilter);
                  deleteDefaultFabricKind.mutate(searchedFabricFilter.id);
                }
              }}
            >
              <Tag
                disabled={!isGrouped}
                endIcon={
                  addDefaultFabricKind.isLoading || deleteDefaultFabricKind.isLoading ? (
                    <Spinner size={16} />
                  ) : (
                    undefined
                  )
                }
                label="materiały"
                startIcon={<MdiFabric size="14" />}
                type={
                  manufacturingStagesUtils.hasFabric(stageBoardDefaultAttributesKind)
                    ? "filled"
                    : "outlined"
                }
                variant={
                  manufacturingStagesUtils.hasFabric(stageBoardDefaultAttributesKind)
                    ? "deepPurple400"
                    : "quaternary"
                }
              />
            </div>
          </div>
        )}
      </div>
    </RightPanelSection>
  );
};
