import { manufacturingActions } from "api/manufacturing/actions";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiArrowForward } from "components/miloDesignSystem/atoms/icons/MdiArrowForward";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Table } from "components/miloDesignSystem/molecules/table";
import { useTableFeatureConnector } from "components/miloDesignSystem/molecules/table/hooks/useTableFeatureConnector";
import { comfortableListUiSchema } from "components/miloDesignSystem/molecules/table/uiSchemas";
import { RightPanelSection } from "components/utils/drawer";
import { useQuery } from "hooks";
import { queryString } from "utilities";
import { useManufacturerSingleOrdersColumns } from "./useManufacturerSingleOrdersColumns";
import { ManufacturerSingleOrderListItem } from "api/manufacturing/models";
import { useNavigate } from "hooks/useNavigate";

export const ManufacturerSingleOrdersSection = () => {
  const { query } = useQuery();
  const { panelId } = query;
  const { data: order } = manufacturingActions.useManufacturingOrder(panelId);
  const search = queryString.stringify({
    materialOrder: order!.id,
    pageSize: 5,
  });
  const {
    data: singleOrders,
    error,
    isLoading,
    isPreviousData,
  } = manufacturingActions.useManufacturingOrdersMaterials(search);
  const navigate = useNavigate();

  const columns = useManufacturerSingleOrdersColumns();
  const tableProps = useTableFeatureConnector({
    rows: singleOrders,
  });

  return (
    <RightPanelSection
      title={
        <div className="w-100 d-flex align-items-center justify-content-between">
          <Typography color="neutralBlack48" fontSize="14" fontWeight="600">
            Pojedyncze zlecenia{" "}
            {Boolean(order!.materialsOrdersItems.length) &&
              `(${order!.materialsOrdersItems.length})`}
          </Typography>
          <Button
            className="text-uppercase"
            endIcon={MdiArrowForward}
            onClick={() =>
              navigate(`/manufacturing/manufacturer-orders/single-orders/${order!.id}`)
            }
            size="medium"
            variant="gray"
          >
            Zobacz wszystkie
          </Button>
        </div>
      }
    >
      <Table<ManufacturerSingleOrderListItem>
        columns={columns}
        error={error}
        isLoading={isLoading || isPreviousData}
        uiSchema={comfortableListUiSchema}
        {...tableProps}
      />
    </RightPanelSection>
  );
};
