import { manufacturingUnitsActions } from "api/manufacturing/units/actions";
import { manufacturingUnitsKeys } from "api/manufacturing/units/keys";
import { UUID } from "api/types";
import socialIcon from "assets/images/social.svg";
import { Button } from "components/miloDesignSystem/atoms/button";
import { MdiRestartAlt } from "components/miloDesignSystem/atoms/icons/MdiRestartAlt";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { useQueryClient } from "react-query";
import { useParams } from "react-router-dom";

interface Props {
  groupId: string;
  groupSearchParams: string;
  unitSearchParams: string;
}

export const EmptyTodoSection = ({ groupId, groupSearchParams, unitSearchParams }: Props) => {
  const { stageId } = useParams<{ stageId: UUID }>();
  const {
    refetch: refetchUnits,
    isRefetching: unitsAreLoading,
  } = manufacturingUnitsActions.useGetTodoManufacturingUnits(unitSearchParams);
  const {
    refetch: refetchGroups,
    isRefetching: groupsAreLoading,
  } = manufacturingUnitsActions.useGetManufacturingGroups({
    id: groupId,
    search: groupSearchParams,
  });
  const queryClient = useQueryClient();

  return (
    <div className="d-flex flex-column gap-2 align-items-center">
      <div>
        <img alt="brak zleceń" src={socialIcon} />
      </div>
      <div className="d-flex flex-column gap-2 py-4 align-items-center">
        <Typography color="neutralBlack88" fontSize="20" fontWeight="700">
          Nie ma nic więcej do zrobienia
        </Typography>
        <Typography color="neutralBlack48" fontSize="16" fontWeight="500">
          Zrelaksuj się chwilę :)
        </Typography>
        <div className="py-4">
          <Button
            isLoading={unitsAreLoading || groupsAreLoading}
            onClick={() => {
              refetchGroups();
              refetchUnits();
              queryClient.invalidateQueries(
                manufacturingUnitsKeys.manufacturingItem.count(stageId),
              );
            }}
            size="medium"
            startIcon={MdiRestartAlt}
            variant="deepPurple"
          >
            Odśwież listę
          </Button>
        </div>
      </div>
    </div>
  );
};
