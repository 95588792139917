import { TradingDocument } from "api/trading-documents/models";
import { InfoLabel } from "components/common/infoLabel";
import customersIcon from "assets/images/copyrightWhite.svg";
import packageIcon from "assets/images/package.svg";
import styles from "../RightPanel.module.css";
import cx from "classnames";
import ReactTooltip from "react-tooltip";
import { useToggle } from "hooks";
import { EditRecipientNamesModal } from "../../editRecipientNamesModal/EditRecipientNamesModal";
import { openEditRecipientNamesModal } from "./utils/openEditRecipientNamesModal";
import { EmptyValue } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

interface Props {
  editRecipientModalPosition: {
    top: number;
    left: number;
  };
  salesInvoice: TradingDocument;
  setEditRecipientModalPosition: React.Dispatch<
    React.SetStateAction<{ top: number; left: number }>
  >;
}

export const CustomerSection = ({
  editRecipientModalPosition,
  salesInvoice,
  setEditRecipientModalPosition,
}: Props) => {
  const editRecipientNamesModal = useToggle();

  if (salesInvoice.kind === "DROPSHIPPING") {
    return (
      <InfoLabel title="nabywca">
        <div className="d-flex align-items-center gap-2">
          <div
            className={cx(styles.filterChip, styles.filterDropshipping)}
            data-tip
            data-for={`dropshipper-${salesInvoice.id}`}
            onClick={e =>
              openEditRecipientNamesModal(
                e,
                setEditRecipientModalPosition,
                editRecipientNamesModal.toggle,
              )
            }
          >
            <img alt="Ikona nabywcy" src={packageIcon} />
            {Boolean(salesInvoice.buyerCompanyName.length) ? (
              <div>{salesInvoice.buyerCompanyName}</div>
            ) : Boolean(salesInvoice.buyerFirstName.length) ||
              Boolean(salesInvoice.buyerLastName.length) ? (
              <div>
                {salesInvoice.buyerFirstName} {salesInvoice.buyerLastName}
              </div>
            ) : (
              EMPTY_VALUE
            )}
          </div>
          <div className="d-flex align-items-center gap-1">
            <Typography color="grey400" fontSize="14" fontWeight="600">
              NIP:
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {salesInvoice.buyerTaxId || EMPTY_VALUE}
            </Typography>
          </div>
        </div>
        <ReactTooltip
          className={styles.customTooltip}
          id={`dropshipper-${salesInvoice.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Dropshipping
        </ReactTooltip>
        {editRecipientNamesModal.isOpen && (
          <EditRecipientNamesModal
            close={editRecipientNamesModal.close}
            modalPosition={editRecipientModalPosition}
            tradingDocument={salesInvoice}
          />
        )}
      </InfoLabel>
    );
  }
  if (salesInvoice.kind === "WHOLESALE") {
    return (
      <InfoLabel title="nabywca">
        <div className="d-flex align-items-center gap-2">
          <div
            className={cx(styles.filterChip, styles.filterCustomer)}
            data-tip
            data-for={`customer-${salesInvoice.id}`}
            onClick={e =>
              openEditRecipientNamesModal(
                e,
                setEditRecipientModalPosition,
                editRecipientNamesModal.toggle,
              )
            }
          >
            <img alt="Ikona nabywcy" src={customersIcon} />
            {salesInvoice.buyerCompanyName.length > 0 ? (
              <div>{salesInvoice.buyerCompanyName}</div>
            ) : salesInvoice.buyerFirstName.length > 0 || salesInvoice.buyerLastName.length > 0 ? (
              <div>
                {salesInvoice.buyerFirstName} {salesInvoice.buyerLastName}
              </div>
            ) : (
              <EmptyValue />
            )}
          </div>
          <div className="d-flex align-items-center gap-1">
            <Typography color="grey400" fontSize="14" fontWeight="600">
              NIP:
            </Typography>
            <Typography fontSize="14" fontWeight="600">
              {salesInvoice.buyerTaxId || EMPTY_VALUE}
            </Typography>
          </div>
        </div>
        <ReactTooltip
          className={styles.customTooltip}
          id={`customer-${salesInvoice.id}`}
          place="top"
          effect="solid"
          arrowColor="transparent"
          offset={{ top: -10 }}
        >
          Kontrahent
        </ReactTooltip>
        {editRecipientNamesModal.isOpen && (
          <EditRecipientNamesModal
            close={editRecipientNamesModal.close}
            modalPosition={editRecipientModalPosition}
            tradingDocument={salesInvoice}
          />
        )}
      </InfoLabel>
    );
  }

  return null;
};
