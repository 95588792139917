import { ManufacturingUnit } from "api/manufacturing/units/models";
import { ColumnType } from "../../../ColumnView";
import { UnitItem } from "../todo/Todo";

interface Props {
  columnType: ColumnType | null;
  unit?: ManufacturingUnit | null;
}

export const Ready = ({ columnType, unit }: Props) => {
  if (unit) return <UnitItem columnType={columnType} unit={unit} />;
  return null;
};
