import { Typography } from "components/miloDesignSystem/atoms/typography";
import { Recipe as RecipeType } from "api/manufacturing/models";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { MdiDragIndicator } from "components/miloDesignSystem/atoms/icons/MdiDragIndicator";
import { EMPTY_VALUE } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { Draggable, DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import styled from "@emotion/styled";
import { UUID } from "api/types";
import { css } from "@emotion/core";
import { ManufacturingMaterialKind } from "api/manufacturing/materials/models";
import { cx } from "utilities";

export const Recipe = ({
  recipe,
  index,
  id,
  isDragDisabled = false,
  isOutput = false,
  usedAsInput,
  usedAsOutput,
}: {
  recipe: RecipeType;
  index: number;
  id: UUID;
  isDragDisabled?: boolean;
  isOutput?: boolean;
  usedAsOutput?: boolean;
  usedAsInput?: boolean;
}) => {
  if (isDragDisabled) {
    return (
      <RecipeInner
        isOutput={isOutput}
        id={id}
        index={index}
        recipe={recipe}
        isDragDisabled={isDragDisabled}
        usedAsInput={usedAsInput}
        usedAsOutput={usedAsOutput}
      />
    );
  }

  return (
    <Draggable
      draggableId={id}
      index={index}
      isDragDisabled={isDragDisabled || (usedAsInput && usedAsOutput)}
    >
      {(provided, snapshot) => (
        <RecipeInner
          id={id}
          isOutput={isOutput}
          index={index}
          provided={provided}
          recipe={recipe}
          snapshot={snapshot}
          isDragDisabled={isDragDisabled}
          usedAsInput={usedAsInput}
          usedAsOutput={usedAsOutput}
        />
      )}
    </Draggable>
  );
};

const RecipeInner = ({
  id,
  index,
  provided,
  recipe,
  snapshot,
  isDragDisabled,
  isOutput,
  usedAsInput,
  usedAsOutput,
}: {
  provided?: DraggableProvided;
  snapshot?: DraggableStateSnapshot;
  recipe: RecipeType;
  index: number;
  id: UUID;
  isDragDisabled?: boolean;
  isOutput: boolean;
  usedAsOutput?: boolean;
  usedAsInput?: boolean;
}) => {
  return (
    <StyledMaterial
      className={cx({
        dashedTableRow:
          (recipe.material.kind === ManufacturingMaterialKind.RAW && usedAsInput) ||
          (recipe.material.kind === ManufacturingMaterialKind.SEMI_FINISHED &&
            usedAsInput &&
            usedAsOutput),
      })}
      isOutput={isOutput}
      isDragging={snapshot?.isDragging || false}
      ref={provided?.innerRef}
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
    >
      {!isDragDisabled && <MdiDragIndicator size="16" color="neutralBlack48" />}
      <div className="d-flex flex-column flex-1">
        <Typography fontSize="16" fontWeight="700" className="mb-1">
          {recipe.material.name}
        </Typography>
        <div className="d-flex align-items-center justify-content-between flex-1">
          <div className="d-flex gap-1 align-items-center">
            {recipe.material.kind === ManufacturingMaterialKind.RAW ? (
              <Tag label="surowiec" variant="info" />
            ) : (
              <Tag label="półprodukt" variant="deepPurple400" />
            )}
            <Typography fontSize="14" fontWeight="700">
              {recipe.quantity}
            </Typography>
            <Typography fontSize="10" fontWeight="500" color="neutralBlack48">
              {recipe.material.unit}
            </Typography>
          </div>
          <Typography fontSize="12" fontWeight="400">
            nr ref. {recipe.material.externalId || EMPTY_VALUE}
          </Typography>
          <div className="d-flex align-items-center gap-1">
            {usedAsOutput && <Tag label="Efekt końcowy" variant="success" type="outlined" />}
            {usedAsInput && <Tag label="Materiał" variant="warning" type="outlined" />}
          </div>
        </div>
      </div>
    </StyledMaterial>
  );
};

export const StyledMaterial = styled.div<{
  isDragging: boolean;
  isOutput: boolean;
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 8px;
  flex: 1;
  gap: 4px;
  border: 1px solid var(--neutralBlack16);
  border-radius: 4px;
  background-color: var(--neutralWhite100)
    ${props =>
      props.isOutput &&
      css({ border: "1px solid var(--success500)", backgroundColor: "var(--success12)" })};

  ${props =>
    props.isDragging
      ? css({ border: "1px solid var(--deepPurple400)", backgroundColor: "var(--deepPurple50)" })
      : css({ transform: "none !important" })};

  :hover {
    border: 1px solid var(--deepPurple400);
    background-color: var(--neutralWhite100);
  }
`;
