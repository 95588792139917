import {
  GroupedUnitInProgress,
  ManufacturingUnitGroupPriority,
} from "api/manufacturing/units/models";
import { Checkbox } from "components/miloDesignSystem/atoms/checkbox";
import { colorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { manufacturingStagesConstants } from "constants/manufacturingStages";
import { assertIsDefined } from "utilities/assertIsDefined";
import styles from "../../Panel.module.css";
import { useCreateTableColumns } from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import { AttributeCategory } from "api/manufacturing/schemas/models";

export const useGroupedUnitsColumns = (
  groupedUnits: GroupedUnitInProgress[],
  selectedUnits: GroupedUnitInProgress[],
  setSelectedUnits: React.Dispatch<React.SetStateAction<GroupedUnitInProgress[]>>,
) => {
  const isUnitSelected = (id: string | number): boolean =>
    selectedUnits.some(group => group.id === id);

  const setUnit = (id: number | string): void => {
    if (isUnitSelected(id))
      return setSelectedUnits(prevGroups => prevGroups.filter(group => group.id !== id));
    const groupToAdd = groupedUnits.find(group => group.id === id);
    assertIsDefined(groupToAdd);
    setSelectedUnits(prevGroups => [...prevGroups, groupToAdd]);
  };

  const areSomeUnitsSelected = Boolean(
    selectedUnits.length &&
      groupedUnits.some(group =>
        selectedUnits.some(selectedGroup => selectedGroup.id === group.id),
      ),
  );

  const areAllSelected =
    groupedUnits.every(group =>
      selectedUnits.some(selectedGroup => selectedGroup.id === group.id),
    ) && Boolean(selectedUnits.length);

  const setAllUnits = (): void => {
    if (areAllSelected) return setSelectedUnits([]);
    const notSelectedGroups = groupedUnits.filter(
      group => !selectedUnits.some(selectedGroup => selectedGroup.id === group.id),
    );
    setSelectedUnits(prevGroups => [...prevGroups, ...notSelectedGroups]);
  };

  return useCreateTableColumns<GroupedUnitInProgress>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "selectedGroups",
        header: () => (
          <div className="d-flex align-items-end pb-2">
            <Checkbox
              checked={areAllSelected}
              indeterminate={areSomeUnitsSelected && !areAllSelected}
              onChange={setAllUnits}
              size="sx"
            />
          </div>
        ),
        size: 25,
        cell: info => {
          const group = info.getValue();
          return (
            <div className="pb-2">
              <Checkbox
                checked={isUnitSelected(group.id)}
                onChange={() => setUnit(group.id)}
                size="sx"
              />
            </div>
          );
        },
      }),
      columnHelper.text(
        row =>
          row.attributes
            .filter(attribute => attribute.category === AttributeCategory.FABRIC)
            .map(attribute => attribute.value.name)
            .join(", "),
        {
          header: "tkanina",
          size: 161,
          typographyProps: {
            color: "neutralBlack88",
          },
        },
      ),
      columnHelper.accessor(row => row, {
        header: "zlecenie",
        size: 120,
        cell: info => {
          const unit = info.getValue();
          return (
            <Typography
              color={
                unit.priority === ManufacturingUnitGroupPriority.A
                  ? "danger600"
                  : unit.priority === ManufacturingUnitGroupPriority.B
                  ? "orange600"
                  : "neutralBlack88"
              }
              fontSize="14"
              fontWeight={
                unit.priority === ManufacturingUnitGroupPriority.A ||
                unit.priority === ManufacturingUnitGroupPriority.B
                  ? "700"
                  : "600"
              }
            >
              {unit.signature}
            </Typography>
          );
        },
      }),
      columnHelper.text(row => row.order?.signature, {
        header: "zamówienie",
        size: 120,
        typographyProps: {
          color: "neutralBlack88",
        },
      }),
      columnHelper.accessor(row => row.priority, {
        id: "priority",
        header: " ",
        size: 46,
        cell: info => {
          const priority = info.getValue();
          if (
            priority === ManufacturingUnitGroupPriority.A ||
            priority === ManufacturingUnitGroupPriority.B
          )
            return (
              <div
                className={styles.ticketIcon}
                style={{
                  borderColor:
                    colorPalette[
                      manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[priority]
                        .light.color
                    ],
                }}
              >
                {
                  manufacturingStagesConstants.manufacturingUnitGroupPriorityDict[priority].light
                    .icon
                }
              </div>
            );
          return null;
        },
      }),
      columnHelper.stretchContent,
    ];
  });
};
