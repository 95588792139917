import { createColumnHelper, ColumnDef, ColumnHelper, AccessorFn } from "@tanstack/react-table";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import { PropsWithChildren, useMemo } from "react";
import { textColumn } from "./columns/textColumn";
import { dateColumn } from "./columns/dateColumn";
import { numberColumn } from "./columns/numberColumn";
import { TypographyProps } from "components/miloDesignSystem/atoms/typography/types";
import { ColorPalette } from "components/miloDesignSystem/atoms/colorsPalette";
import { accessorColumn } from "./columns/accessorColumn";
import { TABLE_FILL_WIDTH_ID } from "components/miloDesignSystem/molecules/table/constants";
import { amountColumn } from "./columns/amountColumn";
import { linkColumn } from "./columns/linkColumn";

interface Options<T> {
  shouldDisplayIndexColumn?: boolean | AccessorFn<T>;
}

interface EmptyValueProps {
  className?: string;
  color?: ColorPalette;
  fontSize?: TypographyProps["fontSize"];
  fontWeight?: TypographyProps["fontWeight"];
}

export type ColumnHelperArgs<T> = {
  accessor: typeof accessorColumn;
  display: ColumnHelper<T>["display"];
  group: ColumnHelper<T>["group"];
  link: typeof linkColumn;
  text: typeof textColumn;
  date: typeof dateColumn;
  number: typeof numberColumn;
  amount: typeof amountColumn;
  stretchContent: ColumnDef<T, unknown>;
};

export function useCreateTableColumns<T>(
  getColumns: (args: { columnHelper: ColumnHelperArgs<T> }) => ColumnDef<T>[],
  options?: Options<T>,
) {
  return useMemo(() => {
    const baseColumnHelper = createColumnHelper<T>();
    const columns = getColumns({
      columnHelper: {
        ...baseColumnHelper,
        accessor: baseColumnHelper.accessor as typeof accessorColumn,
        link: linkColumn,
        text: textColumn,
        date: dateColumn,
        number: numberColumn,
        amount: amountColumn,
        stretchContent: baseColumnHelper.display({
          header: "",
          id: TABLE_FILL_WIDTH_ID,
          size: 0,
          cell: props => null,
        }),
      },
    });

    if (options?.shouldDisplayIndexColumn) {
      if (typeof options.shouldDisplayIndexColumn === "boolean") {
        return [
          baseColumnHelper.display({
            header: "#",
            size: 20,
            cell: props => (
              <BaseTableTypography>
                {props.row.depth ? "" : `${props.row.index + 1}.`}
              </BaseTableTypography>
            ),
          }),
          ...columns,
        ];
      }

      return [
        baseColumnHelper.accessor(options.shouldDisplayIndexColumn, {
          header: "#",
          size: 20,
          cell: props => {
            const index = props.getValue();
            return <BaseTableTypography>{index}</BaseTableTypography>;
          },
        }),
        ...columns,
      ];
    }

    return columns;
  }, [getColumns, options?.shouldDisplayIndexColumn]);
}

const BaseTableTypography = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Typography fontSize="12" fontWeight="700">
      {children}
    </Typography>
  );
};

export const EmptyValue = ({
  className,
  color = "neutralBlack100",
  fontSize = "12",
  fontWeight = "600",
}: EmptyValueProps) => (
  <Typography className={className} color={color} fontSize={fontSize} fontWeight={fontWeight}>
    {EMPTY_VALUE}
  </Typography>
);

export const EMPTY_VALUE = "---";
