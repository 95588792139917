import styles from "../moduleNavigation/ModuleNavigation.module.css";
import { useQuery } from "hooks";
import { Button as DesignSystemButton } from "components/miloDesignSystem/atoms/button";
import { MdiCleaningService } from "components/miloDesignSystem/atoms/icons/MdiCleaningService";
import { FilterList } from "./filterList/FilterList";
import { useFilterContext } from "components/utils/withFilters";
import { ClickOutsideHandler } from "components/utils";
import { cx } from "utilities";

export const outsideClickIgnoreClassFilterDrawer = "outsideClickIgnoreClassFilterDrawer";

export const FilterDrawerRenderer = () => {
  const { setQuery } = useQuery();
  const filterContext = useFilterContext();

  if (!filterContext?.filters.length || !filterContext?.isFilterDrawerOpen) return null;

  return (
    <ClickOutsideHandler
      onClickOutside={() => {
        filterContext.closeFilterDrawer();
      }}
      outsideClickIgnoreClass={outsideClickIgnoreClassFilterDrawer}
    >
      <div
        className={cx(
          styles.filterDrawer,
          "d-flex flex-1 flex-column",
          outsideClickIgnoreClassFilterDrawer,
        )}
      >
        <div className={styles.filtersBody}>
          <div className={styles.filtersBodyTitle}>
            <div className={styles.filtersBodyTitleLabel}>Filtry</div>
            <DesignSystemButton
              className="text-uppercase"
              onClick={() => setQuery({})}
              size="small"
              startIcon={MdiCleaningService}
              theme="dark"
              variant="gray"
            >
              Wyczyść filtry
            </DesignSystemButton>
          </div>
        </div>
        <FilterList filters={filterContext.filters} />
      </div>
    </ClickOutsideHandler>
  );
};
