import { Link as LinkWrapper } from "react-router-dom";
import styles from "./Link.module.css";
import { LinkProps } from "./types";
import { Typography } from "../typography";
import { cx, noop } from "utilities";
import { PropsWithChildren } from "react";

export const Link = ({
  children,
  className,
  fontSize,
  fontWeight,
  onClick,
  target,
  theme = "light",
  to,
}: PropsWithChildren<LinkProps>) => {
  return (
    <LinkWrapper
      className={cx({
        [styles.link]: theme === "light",
        [styles.linkDark]: theme === "dark",
      })}
      key={to}
      onClick={event => {
        event.stopPropagation();
        onClick ? onClick() : noop();
      }}
      target={target}
      to={to}
    >
      <Typography
        className={className}
        color="inherit"
        fontSize={fontSize}
        fontWeight={fontWeight}
        noWrap
      >
        {children}
      </Typography>
    </LinkWrapper>
  );
};

Link.External = ({
  children,
  className,
  fontSize,
  fontWeight,
  onClick,
  target,
  theme = "light",
  to,
}: PropsWithChildren<LinkProps>) => {
  return (
    <div
      className={cx("cursor-pointer", {
        [styles.link]: theme === "light",
        [styles.linkDark]: theme === "dark",
      })}
      key={to}
      onClick={event => {
        event.stopPropagation();
        window.open(to, target);
        onClick ? onClick() : noop();
      }}
    >
      <Typography
        className={className}
        color="inherit"
        fontSize={fontSize}
        fontWeight={fontWeight}
        noWrap
      >
        {children}
      </Typography>
    </div>
  );
};
