import { useMutation } from "hooks/useMutation";
import { manufacturingApi } from "./api";
import { createPaginatedApiQuery } from "hooks/createPaginatedQuery";
import { getAnyErrorKey } from "utilities";
import { FormikHelpers } from "formik";
import { PostManufacturingEmailPayload, PostRecipe } from "./models";
import { manufacturingKeys } from "./keys";
import { assertIsDefined } from "utilities/assertIsDefined";
import { createApiQuery } from "hooks/createApiQuery";
import { useQuery } from "hooks";
import { PostOrderManufacturingMaterialForStageList } from "./materials/models";

const useRecipes = createPaginatedApiQuery(manufacturingApi.getRecipes);
const useGetManufacturingOrders = createPaginatedApiQuery(manufacturingApi.getManufacturingOrders);
const useManufacturingOrder = createApiQuery(manufacturingApi.getManufacturingOrder);
const useIndexStages = createPaginatedApiQuery(manufacturingApi.getIndexStages);
const useStageComponentItems = createPaginatedApiQuery(manufacturingApi.getStageComponentsItems);
const useGetManufacturingOrdersDeliveries = createPaginatedApiQuery(
  manufacturingApi.getManufacturingOrdersDeliveries,
);
const useStageBoardDefaultAttributesKind = createPaginatedApiQuery(
  manufacturingApi.getStageBoardAttributesKind,
);
const useGetManufacturingMaterialOrders = createPaginatedApiQuery(
  manufacturingApi.getManufacturingMaterialOrders,
);
const useManufacturingOrdersMaterials = createPaginatedApiQuery(
  manufacturingApi.getManufacturingOrdersMaterials,
);
const useServiceProviderCommissions = createPaginatedApiQuery(
  manufacturingApi.getCommissionsOrders,
);
const useManufacturerOrderEmails = createPaginatedApiQuery(
  manufacturingApi.getManufacturerOrderEmails,
);

const usePostRecipes = (close: () => void) => {
  const postMutation = useMutation(manufacturingApi.postRecipe, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));

  const handleSubmit = (values: PostRecipe, actions: FormikHelpers<PostRecipe>) => {
    postMutation.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const usePostManufacturingEmails = () => {
  return useMutation(manufacturingApi.postManufacturingEmails, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostManufacturingEmailsToSelectedAddress = (close: () => void) => {
  const postMutation = useMutation(
    manufacturingApi.postManufacturingEmails,
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostManufacturingEmailPayload,
    actions: FormikHelpers<PostManufacturingEmailPayload>,
  ) => {
    postMutation.mutate(values, {
      onSuccess: () => actions.setSubmitting(false),
      onError: error => {
        actions.setSubmitting(false);
        actions.setErrors(error.response?.data);
      },
    });
  };

  return handleSubmit;
};

const usePostManufacturingOrdersReceive = () => {
  return useMutation(
    manufacturingApi.postManufacturingOrdersReceive,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const usePostManufacturingOrders = (close: () => void) =>
  useMutation(manufacturingApi.postManufacturingOrders, ({ queryClient, toastr }) => ({
    onSuccess: () => {
      queryClient.invalidateQueries();
      close();
    },
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
const usePatchRecipe = () => {
  return useMutation(manufacturingApi.patchRecipe, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingKeys.recipes(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList };
    },
    onError: (error, toUpdate, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollbackList(
        manufacturingKeys.recipes(),
        onMutateReturn.prevList,
        toUpdate.id,
        error,
      );
    },
  }));
};

const useDeleteRecipe = () => {
  return useMutation(manufacturingApi.deleteRecipe, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const useDeleteStageBoardAttributesKind = () => {
  return useMutation(
    manufacturingApi.deleteStageBoardAttributesKind,
    ({ queryClient, toastr }) => ({
      onSuccess: () => queryClient.invalidateQueries(),
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );
};

const useBulkPostManufacturingOrders = (close: () => void) => {
  const postMutation = useMutation(
    ({ data }: { data: PostOrderManufacturingMaterialForStageList }) => {
      return manufacturingApi.postManufacturingOrders({
        ...data,
        orders: data.orders.filter(order => !order.isAlreadyOrdered),
      });
    },
    ({ queryClient, toastr }) => ({
      onSuccess: () => {
        queryClient.invalidateQueries();
        close();
      },
      onError: error => {
        toastr.open({
          type: "warning",
          title: "Wymagane działanie",
          text: getAnyErrorKey(error),
        });
      },
    }),
  );

  const handleSubmit = (
    values: PostOrderManufacturingMaterialForStageList,
    actions: FormikHelpers<PostOrderManufacturingMaterialForStageList>,
  ) => {
    postMutation.mutate(
      { data: values },
      {
        onSuccess: () => actions.setSubmitting(false),
        onError: error => {
          actions.setSubmitting(false);
          actions.setErrors(error.response?.data);
        },
      },
    );
  };

  return handleSubmit;
};

const useDeleteStageComponent = () => {
  return useMutation(manufacturingApi.deleteStageComponent, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePostStageComponent = () => {
  return useMutation(manufacturingApi.postStageComponent, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchStageComponent = () => {
  return useMutation(manufacturingApi.patchStageComponent, ({ queryUtils }) => ({
    onMutate: toUpdate => {
      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingKeys.stageComponents(),
        toUpdate.id,
        toUpdate,
      );

      return { prevList };
    },
    onError: (error, toUpdate, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      queryUtils.rollbackList(
        manufacturingKeys.stageComponents(),
        onMutateReturn.prevList,
        toUpdate.id,
        error,
      );
    },
  }));
};

const usePostStageBoardDefaultAttributesKind = () => {
  return useMutation(manufacturingApi.postStageBoardAttributesKind, ({ queryClient, toastr }) => ({
    onSuccess: () => queryClient.invalidateQueries(),
    onError: error => {
      toastr.open({
        type: "warning",
        title: "Wymagane działanie",
        text: getAnyErrorKey(error),
      });
    },
  }));
};

const usePatchManufacturingOrder = () => {
  const { query } = useQuery();

  return useMutation(manufacturingApi.patchManufacturingOrder, ({ queryUtils }) => ({
    onMutate: ({ id, ...toUpdate }) => {
      const prevPanel = query.panelId
        ? queryUtils.handleMutate(manufacturingKeys.manufacturingOrder(id), toUpdate)
        : undefined;
      const prevList = queryUtils.handlePaginatedListUpdate(
        manufacturingKeys.manufacturingOrders(),
        id,
        toUpdate,
      );
      return { prevList, prevPanel };
    },
    onError: (error, { id }, onMutateReturn) => {
      assertIsDefined(onMutateReturn);
      if (onMutateReturn.prevPanel) {
        queryUtils.rollback(
          manufacturingKeys.manufacturingOrder(id),
          onMutateReturn.prevPanel,
          error,
        );
      }
      queryUtils.rollbackList(manufacturingKeys.manufacturingOrders(), onMutateReturn.prevList, id);
    },
  }));
};

export const manufacturingActions = {
  useBulkPostManufacturingOrders,
  useDeleteStageBoardAttributesKind,
  useRecipes,
  usePostRecipes,
  usePatchRecipe,
  useDeleteRecipe,
  useIndexStages,
  useStageComponentItems,
  usePostStageComponent,
  useDeleteStageComponent,
  usePatchStageComponent,
  usePostStageBoardDefaultAttributesKind,
  useStageBoardDefaultAttributesKind,
  useGetManufacturingOrdersDeliveries,
  useGetManufacturingOrders,
  usePostManufacturingOrders,
  useGetManufacturingMaterialOrders,
  useManufacturingOrder,
  usePatchManufacturingOrder,
  useManufacturingOrdersMaterials,
  useServiceProviderCommissions,
  useManufacturerOrderEmails,
  usePostManufacturingEmails,
  usePostManufacturingEmailsToSelectedAddress,
  usePostManufacturingOrdersReceive,
};
