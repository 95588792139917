import { customerActions } from "api/customers/actions";
import { useParams } from "react-router";
import { SectionWrapper } from "../../components/sectionWrapper/SectionWrapper";
import { getAnyErrorKey } from "utilities";
import { Spinner } from "components/miloDesignSystem/atoms/spinner";
import { CommonError } from "components/utils";
import { assertIsDefined } from "utilities/assertIsDefined";
import { Tabs } from "components/miloDesignSystem/molecules/tabs";
import styled from "@emotion/styled";
import { GeneralInformationTab } from "./tabs/generalInformationTab/GeneralInformationTab";

export const CustomerTabSection = () => {
  const { customerId } = useParams<{ customerId: string }>();
  const { data: customer, isLoading, error } = customerActions.useCustomer(Number(customerId));

  if (isLoading)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center"
        width="100%"
        minHeight={516}
      >
        <Spinner size={30} />
      </SectionWrapper>
    );

  if (error)
    return (
      <SectionWrapper
        className="mb-3 d-flex flex-1 justify-content-center align-items-center "
        width="100%"
        minHeight={516}
      >
        <CommonError status={error._httpStatus_} text={getAnyErrorKey(error)} />
      </SectionWrapper>
    );

  assertIsDefined(customer);

  return (
    <SectionWrapper className="mb-3 pt-3 d-flex flex-column flex-1">
      <Tabs mode="state">
        <div className="d-flex gap-2 px-3">
          <Tabs.TabItem label="Zamówienia" value={2} />
          <Tabs.TabItem label="Płatności" value={3} />
          <Tabs.TabItem label="Użytkownicy" value={4} />
          <Tabs.TabItem label="Konfiguracje" value={5} />
          <Tabs.TabItem label="Dane ogólne" value={1} />
        </div>
        <StyledLine />

        <Tabs.TabContentRenderer value={1}>
          <GeneralInformationTab />
        </Tabs.TabContentRenderer>
      </Tabs>
    </SectionWrapper>
  );
};

const StyledLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: var(--neutralBlack16);
  margin-top: -1px;
`;
