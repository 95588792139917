import { CommonError, DisabledOpacity, MockupLoader } from "components/utils";
import { DrawerRightPanel, RightPanelBody } from "components/utils/drawer";
import { useQuery } from "hooks";
import { useRoute } from "hooks/apiPrimitives";
import { useRef } from "react";
import { DeliverySection } from "./components/deliverySection/DeliverySection";
import { DriversSection } from "./components/DriversSection";
import { GeneralInfoSection } from "./components/GeneralInfoSection";
import { NoteSection } from "./components/NoteSection";
import { PanelHeader } from "./components/PanelHeader";
import { TitleSection } from "./components/TitleSection";
import { TriggerScrollContext } from "components/common/triggerScrollContext/TriggerScrollContext";

interface Props {
  close: () => void;
  setShowLoader: React.Dispatch<React.SetStateAction<boolean>>;
}

export const RightPanel = ({ close, setShowLoader }: Props) => {
  const { query } = useQuery();
  const { panelId } = query;
  const clearCommentsRef = useRef<HTMLDivElement>(null);
  const { data: route, error, isLoading } = useRoute(panelId, {
    enabled: Boolean(panelId),
  });

  if (error) {
    return (
      <DrawerRightPanel>
        <CommonError status={error._httpStatus_} />
      </DrawerRightPanel>
    );
  }

  if (isLoading) {
    return (
      <DrawerRightPanel>
        <MockupLoader type="drawer" />
      </DrawerRightPanel>
    );
  }

  if (!route) return null;

  return (
    <TriggerScrollContext>
      <DrawerRightPanel key={route.id}>
        <PanelHeader close={close} setShowLoader={setShowLoader} />
        <DisabledOpacity disabled={isLoading}>
          <RightPanelBody myRef={clearCommentsRef}>
            <TitleSection route={route} />
            <GeneralInfoSection route={route} />
            <DriversSection route={route} />
            <DeliverySection route={route} />
            <NoteSection route={route} />
          </RightPanelBody>
        </DisabledOpacity>
      </DrawerRightPanel>
    </TriggerScrollContext>
  );
};
