import { ManufacturingEmployeeListItem } from "api/manufacturing/employees/models";
import { Avatar } from "components/miloDesignSystem/atoms/avatar";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { manufacturingEmployeesConstants } from "constants/manufacturingEmployees";
import { useSelector } from "hooks";
import { dateFns } from "utilities";
import { manufacturingEmployeesUtils } from "utilities/manufacturingEmployees";
import {
  EMPTY_VALUE,
  EmptyValue,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";

export const useEmployeesColumns = () => {
  const users = useSelector(store => store.partials.users);

  return useCreateTableColumns<ManufacturingEmployeeListItem>(({ columnHelper }) => {
    return [
      columnHelper.accessor(row => row, {
        id: "employeeAvatar",
        header: " ",
        size: 30,
        cell: info => {
          const employee = info.getValue();
          const searchedUser = users.find(user => user.id === employee.userId);
          return <Avatar user={searchedUser ?? null} size="sm" />;
        },
      }),
      columnHelper.text(row => `${row.firstName} ${row.lastName}`, {
        header: "imię nazwisko",
        size: 250,
        typographyProps: {
          color: "neutralBlack88",
          fontSize: "16",
          fontWeight: "500",
        },
      }),
      columnHelper.accessor(row => row.kind, {
        header: "stanowisko",
        size: 120,
        cell: info => {
          const kind = info.getValue();
          if (!Boolean(manufacturingEmployeesConstants.employeeKindOptions[kind]))
            return <EmptyValue fontSize="16" />;
          return (
            <Tag
              label={manufacturingEmployeesConstants.employeeKindOptions[kind].label}
              variant={manufacturingEmployeesConstants.employeeKindOptions[kind].variant}
            />
          );
        },
      }),
      columnHelper.accessor(row => row.employedPlace, {
        header: "gdzie zatrudniony",
        size: 130,
        cell: info => {
          const employedPlace = info.getValue();
          return (
            <Tag
              label={manufacturingEmployeesConstants.employmentPlaceOptions[employedPlace].label}
              variant={
                manufacturingEmployeesConstants.employmentPlaceOptions[employedPlace].variant
              }
            />
          );
        },
      }),
      columnHelper.amount(
        row =>
          row.wallet
            ? String(manufacturingEmployeesUtils.transfromCentsToPln(row.wallet.balance))
            : 0,
        {
          header: "saldo",
          size: 140,
          amountDisplayProps: {
            currency: () => "PLN",
          },
          textAlign: "right",
        },
      ),
      columnHelper.amount(
        row =>
          row.wallet && row.wallet.latestPayment
            ? String(
                manufacturingEmployeesUtils.transfromCentsToPln(row.wallet.latestPayment.amount),
              )
            : 0,
        {
          header: "kwota ostatniej płatności",
          size: 140,
          amountDisplayProps: {
            currency: row => row.original.wallet?.latestPayment?.currency,
          },
          textAlign: "right",
        },
      ),
      columnHelper.accessor(row => row.wallet, {
        header: "typ płatności",
        size: 85,
        cell: info => {
          const wallet = info.getValue();
          if (!wallet || !wallet.latestPayment) return <EmptyValue fontSize="16" />;
          return (
            <Tag
              label={
                manufacturingEmployeesConstants.transactionOptions[
                  wallet.latestPayment.transactionType
                ].label
              }
              type="outlined"
              variant={
                manufacturingEmployeesConstants.transactionOptions[
                  wallet.latestPayment.transactionType
                ].variant
              }
            />
          );
        },
      }),
      columnHelper.text(
        row =>
          row.wallet && row.wallet.latestPayment?.createdAt
            ? dateFns.formatRelative(new Date(row.wallet.latestPayment.createdAt))
            : EMPTY_VALUE,
        {
          header: "data ostatniej płatności",
          size: 135,
          typographyProps: {
            fontSize: "14",
            fontWeight: "400",
          },
        },
      ),
    ];
  });
};
