import { UUID } from "api/types";

export interface Manufacturer {
  id: number;
  name: string;
  fullName: string;
  email: string;
  secondaryEmail: string;
  businessRegisterNumber: string;
  taxId: string;
  phone: string;
  postCode: string;
  city: string;
  integrationToken: string;
  street: string;
  isConnected: boolean;
  productionOrders: {
    id: number;
    signature: string;
    isFinished: boolean;
    isSentToManufacturer: boolean;
    internalId: string;
    createdFor: string;
    isConfirmed: boolean;
    created: string;
    sent: number;
    total: number;
    link: string;
  }[];
}

export enum ManufacturerKind {
  MANUFACTURER = "MANUFACTURER",
  SUPPLIER = "SUPPLIER",
  SERVICE_PROVIDER = "SERVICE_PROVIDER",
}

export interface ManufacturerListItem {
  city: string;
  email: string;
  phone: string;
  fullName: string;
  id: number;
  kind: ManufacturerKind;
  name: string;
  postCode: string;
  secondaryEmail: string;
  serviceStages: {
    id: UUID;
    name: string;
  }[];
  street: string;
  taxId: string;
  businessRegisterNumber: string;
}
