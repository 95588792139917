import { MaterialAvailabilityStatus, MaterialListItem } from "api/manufacturing/materials/models";
import { Typography } from "components/miloDesignSystem/atoms/typography";
import {
  EMPTY_VALUE,
  useCreateTableColumns,
} from "utilities/tableColumnsUtilities/createTableColumns/createTableColumns";
import styles from "./DemandAndAvailability.module.css";
import { Tag } from "components/miloDesignSystem/atoms/tag";
import { materialsConstants } from "constants/materials";
import { Button } from "components/miloDesignSystem/atoms/button";
import { materialsActions } from "api/manufacturing/materials/actions";
import { OrderMaterialModal } from "./DemandAndAvailabilityList";

export const useMaterialColumns = ({
  orderMaterialModal,
}: {
  orderMaterialModal: OrderMaterialModal;
}) => {
  return useCreateTableColumns<MaterialListItem>(
    ({ columnHelper }) => {
      return [
        columnHelper.text(row => row.name, {
          header: "nazwa",
          size: 200,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
          },
        }),
        columnHelper.text(row => row.category?.name ?? null, {
          header: "kategoria",
          size: 120,
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "16",
            fontWeight: "500",
          },
        }),
        columnHelper.text(row => `${row.stock.demandFromOrders} ${row.unit.shortName}`, {
          header: "z zamówień",
          size: 90,
          textAlign: "right",
          typographyProps: {
            color: "neutralBlack88",
            fontSize: "14",
            fontWeight: "500",
          },
        }),
        columnHelper.accessor(row => row, {
          id: "stockStatus",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              stan
            </Typography>
          ),
          size: 180,
          cell: info => {
            const materialDemand = info.getValue();
            return (
              <div className={styles.stockStatus}>
                <Tag
                  label={
                    materialsConstants.materialAvailabilityStatusOptions[
                      materialDemand.stock.stockStatus
                    ].label
                  }
                  variant={
                    materialsConstants.materialAvailabilityStatusOptions[
                      materialDemand.stock.stockStatus
                    ].variant
                  }
                />
                <div className={styles.inStock}>
                  <Typography
                    color={
                      materialDemand.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW
                        ? "danger600"
                        : "neutralBlack88"
                    }
                    fontSize="14"
                    fontWeight="700"
                    noWrap
                  >
                    {materialDemand.stock.inStock} {materialDemand.unit.shortName}
                  </Typography>
                </div>
              </div>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "alreadyOrdered",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              już zamówione
            </Typography>
          ),
          size: 100,
          cell: info => {
            const materialDemand = info.getValue();
            return (
              <Typography
                className="text-right w-100"
                color={materialDemand.stock.alreadyOrdered ? "info300" : "neutralBlack88"}
                fontSize="16"
                fontWeight="700"
              >
                {materialDemand.stock.alreadyOrdered || EMPTY_VALUE}{" "}
                {materialDemand.stock.alreadyOrdered ? materialDemand.unit.shortName : ""}
              </Typography>
            );
          },
        }),
        columnHelper.accessor(row => row, {
          id: "demandForOneWeek",
          header: () => (
            <Typography
              className="text-right w-100"
              color="neutralBlack48"
              fontSize="12"
              fontWeight="400"
            >
              zapotrzebowanie 7 dni
            </Typography>
          ),
          size: 130,
          cell: info => {
            const materialDemand = info.getValue();
            return (
              <Typography
                className="text-right w-100"
                color={
                  materialDemand.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW
                    ? "neutralBlack88"
                    : "neutralBlack48"
                }
                fontSize={
                  materialDemand.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW
                    ? "16"
                    : "14"
                }
                fontWeight={
                  materialDemand.stock.stockStatus === MaterialAvailabilityStatus.VERY_LOW
                    ? "700"
                    : "500"
                }
              >
                {materialDemand.stock.lastWeekDemand} {materialDemand.unit.shortName}
              </Typography>
            );
          },
        }),
        columnHelper.text(row => row.externalId, {
          header: "nr referencyjny",
          size: 130,
          editable: {
            mutationHook: materialsActions.usePatchMaterial,
            transformQueryData: (value, row) => ({ id: row.original.id, externalId: value }),
          },
          typographyProps: {
            fontSize: "14",
            fontWeight: "600",
          },
        }),
        columnHelper.text(row => row.averageDeliveryDays, {
          header: "dostawa (dni)",
          size: 80,
          typographyProps: {
            fontSize: "14",
            fontWeight: "700",
          },
        }),
        columnHelper.accessor(row => row, {
          id: "orderButton",
          header: " ",
          size: 70,
          cell: info => {
            const row = info.getValue();
            return (
              <div className="d-flex justify-content-end">
                <Button
                  disabled={row.isCustomOrdered}
                  className="text-uppercase"
                  size="small"
                  onClick={event => {
                    event.stopPropagation();
                    orderMaterialModal.open({ materials: [row] });
                  }}
                  variant={
                    row.stock.stockStatus === MaterialAvailabilityStatus.HIGH ||
                    row.stock.stockStatus === MaterialAvailabilityStatus.VERY_HIGH
                      ? "gray"
                      : "deepPurple"
                  }
                >
                  zamów
                </Button>
              </div>
            );
          },
        }),
      ];
    },
    { shouldDisplayIndexColumn: true },
  );
};
